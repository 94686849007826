<template>
  <div>
    <InfiniteLoadingBar
      id="infiniteLoadingBar"
      v-if="heavyCalculationInProgress"
    />
    <div id="canvasDiv" @click="handlePaintBucketClick">
      <v-stage
        ref="konvaCanvas"
        id="canva"
        :config="canvasStore.state.configStage"
        @mousedown="handleTouchStart"
      >
        <v-layer ref="konvaLayer" :config="configLayer">
          <v-rect :config="canvasStore.state.configBackgroundRect" />

          <v-image
            :ref="setMainImageRefs"
            v-for="(mainImage, index) in canvasStore.state.configMainImage"
            :key="'mi' + index"
            @click="handleClick($event, index)"
            @dragstart="handleDragstart($event, index)"
            @dragend="handleDragend($event, index)"
            :config="mainImage"
          >
          </v-image>

          <v-image
            v-for="(extraImage, index) in canvasStore.state.configExtraImages"
            :key="'ei' + index"
            :config="extraImage"
            @dragstart="handleDragstart($event, index)"
            @dragend="handleDragend($event, index)"
          >
          </v-image>

          <v-image :config="canvasStore.state.configWaterMarkImage"> </v-image>

          <v-text
            ref="konvaUpperText"
            :config="canvasStore.state.configText.upperText"
            @text="handleTextChanged"
          />
          <v-text
            ref="konvaUpperTextFirstLetter"
            :config="canvasStore.state.configText.upperText.firstLetter"
          />

          <v-text
            ref="konvaLowerText"
            :config="canvasStore.state.configText.lowerText"
            @text="handleTextChanged"
          />
          <v-text
            ref="konvaLowerTextFirstLetter"
            :config="canvasStore.state.configText.lowerText.firstLetter"
          />

          <!-- <v-circle class="circle" :config="configCircle"></v-circle> -->
        </v-layer>
      </v-stage>
    </div>

    <div v-if="controlsVisible" id="controlDiv">


<div class="row">

     <!-- <HelpButton helpId="download"/> -->

      <div class="downloadSectionDiv">
        <div class="downloadFileNameContainer">
          <!-- <ImageButton
          label="Kedvencekbe"
          iconClass="far fa-fw fa-heart"
          @click="handleFavouriteClick"
        /> -->

          <div class="downloadFileNameLabel">
            fájlnév:
          </div>

          <input
            class="downloadFileNameInput"
            type="text"
            v-model="downloadFileName"
          />

          <div class="downloadFileNameLabel">
            .png
          </div>
        </div>

        <div class="imageButtonContainer">
          <ImageButton
            label="Letöltés"
            iconClass="far fa-fw fa-arrow-alt-circle-down"
            @click="handleDownloadClick"
          />
                <ImageButton
            label="Mentés"
            iconClass="far fa-fw fa-heart"
            @click="handleSaveToFavoritesClick"
         
          />
        </div>
      </div>

      </div>

      <div class="row divTopSeparator">
<!-- <HelpButton helpId="extra"/> -->
      <div class="extraImageControlsDiv">
        <div class="extraImageButtonContainer">
          <ImageButton
            class="addExtraImageButton"
            label="Kép törlése"
            iconClass="far fa-fw fa-trash-alt"
            @click="imageDeleterFloatingWindowVisible = true"
            :disabled="deleteButtonDisabled"
          />
        </div>

        <div class="extraImageButtonContainer">
          <ImageButton
            class="addExtraImageButton"
            label="Kép hozzáadása"
            iconClass="fas fa-fw fa-plus"
            @click="handleAddExtraImageButtonClick"
          />
        </div>
      </div>
</div>
      <div class="row divTopSeparator">
<!-- <HelpButton helpId="undo-redo"/> -->

      <div class="restoreButtonsDiv">
        <div class="restoreButtonContainer">
          <ImageButton
            label="Alaphelyzetbe"
            iconClass="fas fa-fw fa-fast-backward"
            @click="canvasStore.methods.undoAll"
            :disabled="undoButtonDisabled"
          />
        </div>

        <div class="restoreButtonContainer">
          <ImageButton
            label="Vissza"
            iconClass="fas fa-fw fa-step-backward"
            @click="canvasStore.methods.undo"
            :disabled="undoButtonDisabled"
          />
        </div>

        <div class="restoreButtonContainer">
          <ImageButton
            label="Előre"
            iconClass="fas fa-fw fa-step-forward"
            @click="canvasStore.methods.redo"
            :disabled="redoButtonDisabled"
          />
        </div>
      </div>

      </div>
    </div>
  </div>

  <!-- <ImageDeleterFloatingWindow
    v-if="itemMoverWindowVisible"
    @cancelButtonClicked="itemMoverWindowVisible = false"
    @acceptButtonClicked="(newParentId) => apiMoveFavouriteItem(newParentId)"
    v-model:inputModel="renameWindowInputModel"
  >
  </ImageDeleterFloatingWindow> -->

  <ImageDeleterFloatingWindow
    v-if="imageDeleterFloatingWindowVisible"
    @cancelButtonClicked="imageDeleterFloatingWindowVisible = false"
    @acceptButtonClicked="(index) => imageDeleteRequested(index)"
    :imageModel="canvasStore.state.configMainImage"
  >
  </ImageDeleterFloatingWindow>
</template>

<script>
import { onMounted, ref, /*computed,*/ watch, computed } from "vue";
import { useRouter } from "vue-router";

import axios from "axios";


import PersistentStore from "../store/persistent";

import CanvasStore from "../store/canvas";
import Store from "../store/store";

import FloodFill from "../components/FloodFill.js";

import ImageButton from "@/components/ImageButton.vue";

import InfiniteLoadingBar from "@/components/InfiniteLoadingBar.vue";

import ImageDeleterFloatingWindow from "@/components/ImageDeleterFloatingWindow.vue";

  //import HelpButton from "@/components/HelpButton.vue";


// import canvas from '../store/canvas';

export default {
  components: {
    ImageButton,
    InfiniteLoadingBar,
    ImageDeleterFloatingWindow,
    /*HelpButton,*/
  },
  props: {
    controlsVisible: Boolean,
  },
  setup(props) {

        const persistentStore = PersistentStore;


    const canvasStore = CanvasStore;
    const store = Store;
    const router = useRouter();

        const isStudent = ref(false);

    const imageDeleterFloatingWindowVisible = ref(false);

    const fastFloodFill = FloodFill;

    // const defaultImageResolution = 3000;

    const heavyCalculationInProgress = ref(false);
    // ÖTLET!!!! az itt kiszamolt canvasheight atadhato kivetelesen a css-nek is.... (igy tudod pl. hogy mekkora helyet hagyj neki a gridben, meg hogy amikor bekeretezed akkor milyen szinu legyen a keret / vagy a transparent hatter milyen meretu legyen)

    const konvaCanvas = ref(null);
    const konvaUpperText = ref(null);
    const konvaLowerText = ref(null);
    const konvaLayer = ref(null);

    const konvaImage = ref(null);

    const konvaMainImageRefs = ref([]);

    function setMainImageRefs(el) {
      konvaMainImageRefs.value.push(el);
    }

    const lastSize = ref(0);

  const deleteButtonDisabled = computed(
      () => canvasStore.state.configMainImage.length <=1
    );
    const undoButtonDisabled = computed(
      () => canvasStore.stateHistory.undoHistory.length == 0
    );
    const redoButtonDisabled = computed(
      () => canvasStore.stateHistory.redoHistory.length == 0
    );

    const downloadFileName = ref("");

    /*  const configKonva = ref({
      width: 500,
      height: 500,
    });*/

    const configLayer = ref({
      width: 500,
      height: 500,
    });

    const configCircle = ref({
      x: 0,
      y: 0,
      radius: 80,
      fill: "red",
      stroke: "black",
      strokeWidth: 4,
      draggable: true,
    });

    // canvasStore.methods.setMainImageSource("fut.png");
    // canvasStore.methods.setWaterMarkImageSource("vizjel.png");

    // function redrawCanvas()
    // {
    //   konvaLayer.value.getStage().draw()
    //   // console.log()
    // }

    // function getMainImageData(index) {
    //   let pixelRatio = 3000 / canvasStore.state.configMainImage[index].height;

    //   let canvas = konvaMainImageRefs.value[0]
    //     .getStage()
    //     .toCanvas({ pixelRatio: pixelRatio });

    //   // console.log(canvas);

    //   let ctx = canvas.getContext("2d");

    //   // console.log(ctx);
    //   //  var t0 = performance.now()
    //   return ctx.getImageData(0, 0, canvas.width, canvas.height);
    // }

    function imageDeleteRequested(index) {
      imageDeleterFloatingWindowVisible.value = false;
      canvasStore.methods.deleteImage(index);
    }

    function paintBucket(
      x,
      y,
      correctedX,
      correctedY,
      canvas,
      ctx,
      imageData,
      index
    ) {
      if (
        x < 0 ||
        y < 0 ||
        x >= canvasStore.state.configMainImage[0].width ||
        y >= canvasStore.state.configMainImage[0].height
      ) {
        console.log("pixel outside!");
        return;
      }

      const col = hexAToRGBA(canvasStore.state.configPaintBucket._color.color);

      // console.log("szin");
      // console.log(col);

      //    console.log("i: " +   RGBAToHexA(data[i], data[i+1], data[i+2], data[i+3]) )

      // var t0 = performance.now()

      var success = floodFill(imageData, col, correctedX, correctedY);
      //  imageData = fastFloodFill.paintAt(imageData, correctedX, correctedY);
      // var t1 = performance.now()
      // alert("Call to fasdFloodFill took " + (t1 - t0) + " milliseconds.")

      // console.log("succes: " + success);

      if (!success) {
        return;
      }
      //alert(JSON.stringify(e))

      //t0 = performance.now()
      ctx.putImageData(imageData, 0, 0);
      //t1 = performance.now()
      //alert("Call to putImageData took " + (t1 - t0) + " milliseconds.")
      // //       console.log(imageData);
      // t0 = performance.now()
      var img = canvas.toDataURL("image/png");

      // t1 = performance.now()
      // alert("Call to toDataURL took " + (t1 - t0) + " milliseconds.")

      canvasStore.methods.saveImageBeforePaintBucketChange(
        index,
        canvasStore.state.configMainImage[index].image.src,
        img
      );

      canvasStore.state.configMainImage[index].image = new Image();
      canvasStore.state.configMainImage[index].image.src = img;

      //console.log("paint bucket finished, save old image: ")
      //console.log(canvasStore.state.configMainImage[index].image.src)
    }

    // function paintBucket(x, y) {
    //   if (canvasStore.state.configPaintBucket._on) {
    //     if (
    //       x < 0 ||
    //       y < 0 ||
    //       x >= canvasStore.state.configMainImage[0].width ||
    //       y >= canvasStore.state.configMainImage[0].height
    //     ) {
    //       return;
    //     }

    //     // console.log("x: " + x + " y: " + y);

    //     var pixelRatio = 3000 / canvasStore.state.configMainImage[0].height;

    //     // console.log("pixelRatio: " + pixelRatio);

    //     // var correctedX = Math.round(
    //     //   (x - canvasStore.state.configMainImage[0].x) * pixelRatio
    //     // );
    //     // var correctedY = Math.round(
    //     //   (y - canvasStore.state.configMainImage[0].y) * pixelRatio
    //     // );

    //     var correctedX = Math.round(x * pixelRatio);
    //     var correctedY = Math.round(y * pixelRatio);

    //     // console.log("correctedX: " + correctedX + " correctedY: " + correctedY);

    //     var canvas = konvaMainImageRefs.value[0]
    //       .getStage()
    //       .toCanvas({ pixelRatio: pixelRatio });

    //     // console.log(canvas);

    //     var ctx = canvas.getContext("2d");

    //     // console.log(ctx);
    //     //  var t0 = performance.now()
    //     var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    //     // var t1 = performance.now()
    //     //alert("Call to getImageData took " + (t1 - t0) + " milliseconds.")
    //     // const data = imageData.data;

    //     // var clickedIndex = getIndexAtImageCoordinates(
    //     //   correctedX,
    //     //   correctedY,
    //     //   canvas.width
    //     // );
    //     // console.log("image data index: " + clickedIndex);

    //     // console.log("width: " + canvas.width + " height: " + canvas.height);

    //     //  console.log(data[clickedIndex] + ", " + data[clickedIndex+1] + ", " + data[clickedIndex+2]  + ", " +  data[clickedIndex+3])
    //     //       console.log(RGBAToHexA(data[clickedIndex], data[clickedIndex+1], data[clickedIndex+2], data[clickedIndex+3]));

    //     //  console.log(data.length)

    //     // var clicked_red = data[clickedIndex]
    //     // var clicked_green = data[clickedIndex+1]
    //     // var clicked_blue = data[clickedIndex+2]
    //     // var clicked_alpha = data[clickedIndex+3]

    //     // for (var i = clickedIndex; i < data.length; i += 4) {

    //     //   if(data[i] == clicked_red &&
    //     //   data[i+1] == clicked_green &&
    //     //   data[i+2] == clicked_blue &&
    //     //   data[i+3] == clicked_alpha)
    //     //   {

    //     //   data[i] = 0; // red
    //     //   data[i + 1] = 255; // green
    //     //   data[i + 2] = 0; // blue
    //     //   data[i + 3] = 255; // alpha

    //     //   }

    //     //   }

    //     //const col = {r: 0xff, g: 0xff, b: 0x0, a: 0xff}

    //     //const col = {r: 255, g: 0, b: 0, a: 255}

    //     const col = hexAToRGBA(
    //       canvasStore.state.configPaintBucket._color.color + "ff"
    //     );

    //     // console.log("szin");
    //     // console.log(col);

    //     //    console.log("i: " +   RGBAToHexA(data[i], data[i+1], data[i+2], data[i+3]) )

    //     // var t0 = performance.now()

    //     var success = floodFill(imageData, col, correctedX, correctedY);
    //     //  imageData = fastFloodFill.paintAt(imageData, correctedX, correctedY);
    //     // var t1 = performance.now()
    //     // alert("Call to fasdFloodFill took " + (t1 - t0) + " milliseconds.")

    //     // console.log("succes: " + success);

    //     if (!success) {
    //       return;
    //     }
    //     //alert(JSON.stringify(e))

    //     //t0 = performance.now()
    //     ctx.putImageData(imageData, 0, 0);
    //     //t1 = performance.now()
    //     //alert("Call to putImageData took " + (t1 - t0) + " milliseconds.")
    //     // //       console.log(imageData);
    //     // t0 = performance.now()
    //     var img = canvas.toDataURL("image/png");

    //     // t1 = performance.now()
    //     // alert("Call to toDataURL took " + (t1 - t0) + " milliseconds.")

    //     canvasStore.state.configMainImage[0].image = new Image();
    //     canvasStore.state.configMainImage[0].image.src = img;
    //   }
    // }

    function handlePaintBucketTouch(/*e*/) {
      alert("Todo: implement touch functionality for mobile devices");

      // console.log(e);

      //  var x = e.evt.touches[0].clientX - canvasStore.state.configMainImage[0].x
      // var y = e.evt.touches[0].clientY - canvasStore.state.configMainImage[0].y

      // paintBucket(x, y)
    }

    //  function startPaintBucketExecution(e)
    //   {

    //   }

    function createEditableImage(imageUrl) {
      let canvas = document.createElement("canvas");
      canvas.width = canvasStore.defaultImageResolution;
      canvas.height = canvasStore.defaultImageResolution;

      let ctx = canvas.getContext("2d");

      ctx.drawImage(
        imageUrl,
        0,
        0,
        canvasStore.defaultImageResolution,
        canvasStore.defaultImageResolution
      );

      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      return {
        canvas,
        ctx,
        imageData,
      };
    }

    function executePaintBucketClick(e) {
      if (canvasStore.state.configPaintBucket._on) {
        //canvasStore.state.configText.upperText.text = "clik"

        console.log(e);

        console.log(e.layerX + " " + e.layerY);
        // console.log(e.evt.layerX )

        const x = e.layerX;
        const y = e.layerY;

        console.log("original x on canvas: " + x);

        let paintHappened = false;
        let index = canvasStore.state.configMainImage.length - 1;

        while (!paintHappened && index >= 0) {
          //   x *= canvasStore.state.configMainImage[index]._zoom/100
          // y *= canvasStore.state.configMainImage[index]._zoom/100

          let xTransformed = x;
          let yTransformed = y;

          xTransformed -= canvasStore.state.configMainImage[index].x;
          yTransformed -= canvasStore.state.configMainImage[index].y;

          xTransformed += canvasStore.state.configMainImage[index].offsetX;
          yTransformed += canvasStore.state.configMainImage[index].offsetY;

          // xTransformed -= canvasStore.state.configMainImage[index]._dragX; //  *canvasStore.state.configMainImage[0]._zoom/100
          // yTransformed -= canvasStore.state.configMainImage[index]._dragY; //*canvasStore.state.configMainImage[0]._zoom/100

          // x *= canvasStore.state.configMainImage[0].width / canvasStore.state.configStage.width
          // y *= canvasStore.state.configMainImage[0].height / canvasStore.state.configStage.height

          // rotation
          let rotationInRAD =
            (canvasStore.state.configMainImage[index].rotation / 180) * Math.PI;
          console.log(`RotRad: ${rotationInRAD}`);
          console.log(
            `RotOG:  ${canvasStore.state.configMainImage[index].rotation}`
          );
          let halfedImageWidth =
            canvasStore.state.configMainImage[index].width / 2;
          let halfedImageHeight =
            canvasStore.state.configMainImage[index].height / 2;
          console.log(`W ${halfedImageWidth}, H: ${halfedImageHeight}`);
          let xn =
            (xTransformed - halfedImageWidth) * Math.cos(rotationInRAD) -
            (-yTransformed + halfedImageHeight) * Math.sin(rotationInRAD) +
            halfedImageWidth;

          let yn =
            (xTransformed - halfedImageWidth) * Math.sin(rotationInRAD) +
            (-yTransformed + halfedImageHeight) * Math.cos(rotationInRAD) -
            halfedImageWidth;
          yn *= -1;
          console.log("rotated x: " + xn);
          console.log("rotated y: " + yn);
          yTransformed = yn;
          xTransformed = xn;

          // if the image is flipped
          if (canvasStore.state.configMainImage[index].scaleX == -1) {
            // alert("hre")
            xTransformed =
              -xTransformed + canvasStore.state.configMainImage[index].width;
          }
          console.log("scaled x: " + xTransformed);
          console.log("scaled y: " + yTransformed);

          // x-= canvasStore.state.configBackgroundRect.strokeWidth /2
          // y-= canvasStore.state.configBackgroundRect.strokeWidth /2

          console.log("moved x: " + xTransformed);
          console.log("moved y: " + yTransformed);

          // var x = (e.evt.layerX  - canvasStore.state.configMainImage[0]._dragX) // * canvasStore.state.configMainImage[0]._zoom/100;
          // var y = (e.evt.layerY  - canvasStore.state.configMainImage[0]._dragY) // * canvasStore.state.configMainImage[0]._zoom/100;

          console.log("original x on image: " + xTransformed);
          console.log(
            "mainimage x: " +
              canvasStore.state.configMainImage[index].x +
              " w: " +
              canvasStore.state.configMainImage[index].width
          );

          //get image data
          let pixelRatio =
            canvasStore.defaultImageResolution /
            canvasStore.state.configMainImage[index].height;

          let editableImage = createEditableImage(
            canvasStore.state.configMainImage[index].image
          );

          var correctedX = Math.round(xTransformed * pixelRatio);
          var correctedY = Math.round(yTransformed * pixelRatio);

          console.log("correctedX: " + correctedX);

          let colorAtPixel = getColorAtPixel(
            editableImage.imageData,
            correctedX,
            correctedY
          );
          console.log("colorAtPixel");

          console.log(colorAtPixel);

          let hexColorAtPixel = RGBAToHexA(
            colorAtPixel.r,
            colorAtPixel.g,
            colorAtPixel.b,
            colorAtPixel.a
          );
          console.log(hexColorAtPixel);
          let actualOutlineColor =
            canvasStore.state.configGeneral._outlineColor.color;
          if (actualOutlineColor.length == 7) {
            actualOutlineColor += "ff";
          }

          console.log("actualOutlineColor");
          console.log(actualOutlineColor);

          if (
            colorAtPixel.r == 0 &&
            colorAtPixel.g == 0 &&
            colorAtPixel.b == 0 &&
            colorAtPixel.a == 0
          ) {
            console.log("This is a transparent pixel, check the layers below!");
            index--;
          } else if (hexColorAtPixel == actualOutlineColor) {
            console.log("This is an outline color!!!");
            return;
          } else {
            paintBucket(
              xTransformed,
              yTransformed,
              correctedX,
              correctedY,
              editableImage.canvas,
              editableImage.ctx,
              editableImage.imageData,
              index
            );

            paintHappened = true;
          }
        }
      }
    }

    function startHeavyCalculation(callback) {
      heavyCalculationInProgress.value = true;
      setTimeout(function() {
        callback();
      }, 25);
    }

    function endHeavyCalculation() {
      setTimeout(function() {
        heavyCalculationInProgress.value = false;
      }, 100);
    }

    function handlePaintBucketClick(e) {
      startHeavyCalculation(() => {
        executePaintBucketClick(e);
      });

      endHeavyCalculation();
    }

    function hexAToRGBA(h) {
      // let r = 0, g = 0, b = 0, a = 1;

      // if (h.length == 5) {
      //   r = "0x" + h[1] + h[1];
      //   g = "0x" + h[2] + h[2];
      //   b = "0x" + h[3] + h[3];
      //   a = "0x" + h[4] + h[4];

      // } else if (h.length == 9) {
      //   r = "0x" + h[1] + h[2];
      //   g = "0x" + h[3] + h[4];
      //   b = "0x" + h[5] + h[6];
      //   a = "0x" + h[7] + h[8];
      // }
      //a = +(a / 255).toFixed(3);

      if (h.length == 6) {
        // add 100% alpha if not exists
        h += "ff";
      }

      var r = parseInt(h.slice(1, 3), 16),
        g = parseInt(h.slice(3, 5), 16),
        b = parseInt(h.slice(5, 7), 16),
        a = h.length > 7 ? parseInt(h.slice(7, 9), 16) : 255;

      return { r: r, g: g, b: b, a: a };
    }

    function RGBAToHexA(r, g, b, a) {
      r = r.toString(16);
      g = g.toString(16);
      b = b.toString(16);
      a = a.toString(16);

      if (r.length == 1) r = "0" + r;
      if (g.length == 1) g = "0" + g;
      if (b.length == 1) b = "0" + b;
      if (a.length == 1) a = "0" + a;

      return "#" + r + g + b + a;
    }

    // function getIndexAtImageCoordinates(x, y, width) {
    //   return (y * width + x) * 4;
    // }

    function getColorAtPixel(imageData, x, y) {
      const { width, data } = imageData;

      return {
        r: data[4 * (width * y + x) + 0],
        g: data[4 * (width * y + x) + 1],
        b: data[4 * (width * y + x) + 2],
        a: data[4 * (width * y + x) + 3],
      };
    }

    function setColorAtPixel(imageData, color, x, y) {
      const { width, data } = imageData;

      data[4 * (width * y + x) + 0] = color.r & 0xff;
      data[4 * (width * y + x) + 1] = color.g & 0xff;
      data[4 * (width * y + x) + 2] = color.b & 0xff;
      data[4 * (width * y + x) + 3] = color.a & 0xff;
    }

    function colorMatch(a, b) {
      return a.r === b.r && a.g === b.g && a.b === b.b && a.a === b.a;
    }

    function floodFill(imageData, newColor, x, y) {
      const { width, height /*, data*/ } = imageData;

      const stack = [];
      const baseColor = getColorAtPixel(imageData, x, y);
      let operator = { x, y };

      // console.log(newColor);
      // console.log(baseColor);
      // Check if base color and new color are the same
      if (colorMatch(baseColor, newColor)) {
        // console.log("same");
        return false;
      }
      // else {
      //   console.log("not same");
      // }

      // Add the clicked location to stack
      stack.push({ x: operator.x, y: operator.y });

      while (stack.length) {
        operator = stack.pop();
        let contiguousDown = true; // Vertical is assumed to be true
        let contiguousUp = true; // Vertical is assumed to be true
        let contiguousLeft = false;
        let contiguousRight = false;

        // Move to top most contiguousDown pixel
        while (contiguousUp && operator.y >= 0) {
          operator.y--;
          contiguousUp = colorMatch(
            getColorAtPixel(imageData, operator.x, operator.y),
            baseColor
          );
        }

        // Move downward
        while (contiguousDown && operator.y < height) {
          setColorAtPixel(imageData, newColor, operator.x, operator.y);

          // Check left
          if (
            operator.x - 1 >= 0 &&
            colorMatch(
              getColorAtPixel(imageData, operator.x - 1, operator.y),
              baseColor
            )
          ) {
            if (!contiguousLeft) {
              contiguousLeft = true;
              stack.push({ x: operator.x - 1, y: operator.y });
            }
          } else {
            contiguousLeft = false;
          }

          // Check right
          if (
            operator.x + 1 < width &&
            colorMatch(
              getColorAtPixel(imageData, operator.x + 1, operator.y),
              baseColor
            )
          ) {
            if (!contiguousRight) {
              stack.push({ x: operator.x + 1, y: operator.y });
              contiguousRight = true;
            }
          } else {
            contiguousRight = false;
          }

          operator.y++;
          contiguousDown = colorMatch(
            getColorAtPixel(imageData, operator.x, operator.y),
            baseColor
          );
        }
      }

      return true;
    }

    function convertURIToImageData(URI) {
      return new Promise(function(resolve, reject) {
        if (URI == null) return reject();
        var canvas = document.createElement("canvas"),
          context = canvas.getContext("2d"),
          image = new Image();
        image.addEventListener(
          "load",
          function() {
            canvas.width = image.width;
            canvas.height = image.height;
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            resolve(context.getImageData(0, 0, canvas.width, canvas.height));
          },
          false
        );
        image.src = URI;
      });
    }

    async function convertToGrayScale(index) {
      // konvaMainImageRefs.value[0].getStage().cache()
      // konvaMainImageRefs.value[0].getStage().filters([window.Konva.Filters.Grayscale])

      // konvaLayer.value.getStage().cache()
      //       konvaLayer.value.getStage().filters([window.Konva.Filters.Grayscale])

      // console.log("konva iamge start");
      // var pixelRatio =
      //   defaultImageResolution / canvasStore.state.configMainImage[0].height;
      // // console.log(pixelRatio);
      // var canvas = konvaMainImageRefs.value[0]
      //   .getStage()
      //   .toCanvas({ pixelRatio: pixelRatio });

      // // console.log(canvas);

      // var ctx = canvas.getContext("2d");

      // console.log(ctx);

      // var imageData;
      // if(canvasStore.state.configMainImage[0]._outline)
      //       {
      //         console.log("gspecial case")
      // //        canvasStore.state.configMainImage[0].image.src = canvasStore.state.configMainImage[0]._outlineHistoryImage;

      //                 canvasStore.state.configMainImage[0]._grayScaleHistoryImage = canvasStore.state.configMainImage[0]._outlineHistoryImage;

      //                 imageData = await convertURIToImageData(canvasStore.state.configMainImage[0]._outlineHistoryImage)

      //                 //console.log(imgD)
      //       }
      //       else
      //       {

      let editableImage = createEditableImage(
        canvasStore.state.configMainImage[index].image
      );

      if (
        canvasStore.state.configMainImage[index]._originalImageSrc == undefined
      ) {
        //save to hirtory
        // console.log("save");
        canvasStore.state.configMainImage[
          index
        ]._originalImageSrc = editableImage.canvas.toDataURL("image/png");
        // ~ save to hirtory

        // imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      } else {
        // restore from history
        editableImage.imageData = await convertURIToImageData(
          canvasStore.state.configMainImage[index]._originalImageSrc
        );
        //~restore from history
      }
      // }

      const data = editableImage.imageData.data;

      // let outlineColor = hexAToRGBA(canvasStore.state.configGeneral._outlineColor.color)

      for (var i = 0; i < data.length; i += 4) {
        // if(data[i] != outlineColor.r && data[i+1] != outlineColor.g && data[i+2] != outlineColor.b  )
        // {
        var avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
        data[i] = avg; // red
        data[i + 1] = avg; // green
        data[i + 2] = avg; // blue
        // }
      }
      editableImage.ctx.putImageData(editableImage.imageData, 0, 0);

      //       console.log(imageData);

      var img = editableImage.canvas.toDataURL("image/png");

      canvasStore.state.configMainImage[index].image = new Image();
      canvasStore.state.configMainImage[index].image.src = img;

      // // special case: outline is true
      //   if(canvasStore.state.configMainImage[0]._outline)
      //     {
      //               canvasStore.state.configMainImage[0]._outlineHistoryImage = img;
      //     }
      //     //~ special case: outline is true
    }

    function convertToOutline(index) {
      // console.log(konvaMainImageRefs.value)

      // console.log("konva iamge start");
      // var pixelRatio =
      // defaultImageResolution / canvasStore.state.configMainImage[0].height;
      // console.log(pixelRatio);
      // var canvas = konvaMainImageRefs.value[0]
      //   .getStage()
      //   .toCanvas({ pixelRatio: pixelRatio });

      let editableImage = createEditableImage(
        canvasStore.state.configMainImage[index].image
      );

      // console.log(canvas);

      // save to history
      if (
        canvasStore.state.configMainImage[index]._originalImageSrc == undefined
      ) {
        // console.log("save");
        canvasStore.state.configMainImage[
          index
        ]._originalImageSrc = editableImage.canvas.toDataURL("image/png");
      }

      // ~ save to history

      // canvasStore.state.configMainImage[0]._outlineHistoryImage =
      // canvas.toDataURL("image/png");

      // var ctx = canvas.getContext("2d");

      // console.log(ctx);

      // const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      let actualOutlineColor = hexAToRGBA(
        canvasStore.state.configGeneral._outlineColor.color
      );

      console.log("actualOutlineColor");
      console.log(actualOutlineColor);

      const data = editableImage.imageData.data;
      for (var i = 0; i < data.length; i += 4) {
        if (
          data[i] !== actualOutlineColor.r ||
          data[i + 1] !== actualOutlineColor.g ||
          data[i + 2] !== actualOutlineColor.b ||
          (data[i + 3] !== actualOutlineColor.a && data[i + 3] !== 0)
          /* we should test for alpha = 0, we don't want to change the transparent pixels as it would make it repaintable by paintbucket tool which is unwanted behaviour (changing transparent background color should happen through the background changing functionality) */
        ) {
          //                     data[i] = 255; //
          //           data[i + 1] = 255; //
          // data[i + 2] = 255; //
          data[i + 3] = 1; // alpha - it is 1 not 0 to let it recolorable by paintbucket
        }
      }
      editableImage.ctx.putImageData(editableImage.imageData, 0, 0);

      //       console.log(imageData);

      var img = editableImage.canvas.toDataURL("image/png");

      canvasStore.state.configMainImage[index].image = new Image();
      canvasStore.state.configMainImage[index].image.src = img;
    }

    function changeOutlineColor(fromColor, toColor) {
      startHeavyCalculation(() => {
        var fromColorRGBA = hexAToRGBA(fromColor);
        var toColorRGBA = hexAToRGBA(toColor);

        // console.log(konvaMainImageRefs.value)

        // console.log("konva iamge start");
        // var pixelRatio =
        //   defaultImageResolution / canvasStore.state.configMainImage[0].height;
        // console.log(pixelRatio);
        // var canvas = konvaMainImageRefs.value[0]
        //   .getStage()
        //   .toCanvas({ pixelRatio: pixelRatio });

        // console.log(canvas);
        for (let i = 0; i < canvasStore.state.configMainImage.length; i++) {
          let editableImage = createEditableImage(
            canvasStore.state.configMainImage[i].image
          );

          // save to history
          if (
            canvasStore.state.configMainImage[i]._originalImageSrc == undefined
          ) {
            // console.log("save");
            canvasStore.state.configMainImage[
              i
            ]._originalImageSrc = editableImage.canvas.toDataURL("image/png");
          }

          // ~ save to history

          // canvasStore.state.configMainImage[0]._outlineHistoryImage =
          // canvas.toDataURL("image/png");

          // var ctx = canvas.getContext("2d");

          // console.log(ctx);

          // const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

          const data = editableImage.imageData.data;
          for (var j = 0; j < data.length; j += 4) {
            if (
              data[j] == fromColorRGBA.r &&
              data[j + 1] == fromColorRGBA.g &&
              data[j + 2] == fromColorRGBA.b &&
              data[j + 3] == fromColorRGBA.a
            ) {
              data[j] = toColorRGBA.r;
              data[j + 1] = toColorRGBA.g;
              data[j + 2] = toColorRGBA.b;
              data[j + 3] = toColorRGBA.a;
            }
          }
          editableImage.ctx.putImageData(editableImage.imageData, 0, 0);

          //       console.log(imageData);

          var img = editableImage.canvas.toDataURL("image/png");

          canvasStore.state.configMainImage[i].image = new Image();
          canvasStore.state.configMainImage[i].image.src = img;
        }
      });

      endHeavyCalculation();
    }

    function evaluateAppearanceState() {
      startHeavyCalculation(() => {
        // console.log("eval");
        // console.log(canvasStore.state.configMainImage[0]._originalImageSrc);

        for (let i = 0; i < canvasStore.state.configMainImage.length; i++) {
          evaluateAppearanceStateForSingleImage(i);
        }
      });

      endHeavyCalculation();

      // canvasStore.state.configMainImage[
      //   canvasStore.state.configMainImage.length - 1
      // ].image.onload = function() {
      //   changeOutlineColor(
      //     canvasStore.state.configGeneral._defaultOutlineColor.color,
      //     canvasStore.state.configGeneral._outlineColor.color
      //   );
      // };
    }

    function evaluateAppearanceStateForSingleImage(i) {
      if (
        canvasStore.state.configGeneral._outline &&
        !canvasStore.state.configGeneral._grayScale
      ) {
        // console.log("1");
        convertToOutline(i);
      } else if (
        !canvasStore.state.configGeneral._outline &&
        canvasStore.state.configGeneral._grayScale
      ) {
        // console.log("2");
        convertToGrayScale(i);
      } else if (
        canvasStore.state.configGeneral._outline &&
        canvasStore.state.configGeneral._grayScale
      ) {
        // console.log("3");
        convertToOutline();
      } else if (
        !canvasStore.state.configGeneral._outline &&
        !canvasStore.state.configGeneral._grayScale
      ) {
        // console.log("1");
        canvasStore.state.configMainImage[i].image.src =
          canvasStore.state.configMainImage[i]._originalImageSrc;
        canvasStore.state.configMainImage[i]._originalImageSrc = undefined;
      }
    }

    const handleTextChanged = (e) => {
      console.log("textChanged" + e);
    };

    // function dataURItoBlob(dataURI) {
    //     // convert base64/URLEncoded data component to raw binary data held in a string
    //     var byteString;
    //     if (dataURI.split(',')[0].indexOf('base64') >= 0)
    //         byteString = atob(dataURI.split(',')[1]);
    //     else
    //         byteString = unescape(dataURI.split(',')[1]);

    //     // separate out the mime component
    //     var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    //     // write the bytes of the string to a typed array
    //     var ia = new Uint8Array(byteString.length);
    //     for (var i = 0; i < byteString.length; i++) {
    //         ia[i] = byteString.charCodeAt(i);
    //     }

    //     return new Blob([ia], {type:mimeString});
    // }

    // function dataURLtoBlob(dataurl) {
    //     var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    //     while(n--){
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new Blob([u8arr], {type:mime});
    // }

    function getDataURL(ratio) {
      return konvaCanvas.value.getStage().toDataURL({
        pixelRatio: ratio, // or other value you need
      });
    }

    // function imgToBlob() {
    //   return new Promise(function (resolve) {
    //     konvaCanvas.value.getStage().toBlob(resolve, "image/jpeg", 0.95); // <-----
    //   });
    // }

    function dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      var dw = new DataView(ab);
      for (var i = 0; i < byteString.length; i++) {
        dw.setUint8(i, byteString.charCodeAt(i));
      }
      // write the ArrayBuffer to a blob, and you're done
      return new Blob([ab], { type: mimeString });
    }

    function getFile() {
      // imgToBlob().then(function(blob){
      //   console.log('blob created')
      //   console.log(blob)
      // });
      var ratio = canvasStore.defaultImageResolution / canvasStore.state.configStage.height;
      return dataURItoBlob(getDataURL(ratio));
    }

    function getThumbnailFile() {
      var ratio = 700 / canvasStore.state.configStage.height;
      return dataURItoBlob(getDataURL(ratio));
    }

    function downloadImage() {
      var ratio = canvasStore.defaultImageResolution / canvasStore.state.configStage.height;
      // console.log(konvaCanvas.value.getStage());
      const uri = konvaCanvas.value.getStage().toDataURL({
        pixelRatio: ratio, // or other value you need
      });
      //  console.log(uri);
      var link = document.createElement("a");
      link.download = downloadFileName.value + ".png";
      link.href = uri;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    // const handleFavouriteClick = (/*e*/) => {
    //   canvasStore.methods.restoreDefaultState();

    //   // console.log(canvasStore.defaultState)

    //   // alert("A backend elkészültével ez a gomb a kedvencekbe helyezi a képet.");

    //   // localStorage.setItem('store_state', JSON.stringify(store.state))

    //   // Object.assign(store.state, JSON.parse(localStorage.getItem('store_state')))

    //   // var image  = canvasStore.state.configMainImage[0].image
    //   //   console.log (image)
    //   // var state = JSON.parse(localStorage.getItem('canvasStore'))

    //   // Object.assign(canvasStore.state.configText, state.configText)

    //   // state.configMainImage[0].image =  new window.Image();
    //   // state.configMainImage[0].image.src = "fut.png";
    //   // Object.assign(canvasStore.state, state)

    //   //       var a = performance.now();
    //   // //       var state = canvasStore.state
    //   // //         localStorage.setItem('canvasStore', JSON.stringify(state))

    //   // //      // canvasStore.state = JSON.parse(localStorage.getItem('canvasStore'))

    //   // // console.log(JSON.parse(localStorage.getItem('canvasStore')))
    //   // // // canvasStore.state = state

    //   //               for (var key of Object.keys(canvasStore.state)) {

    //   //                canvasStore.state[key] = JSON.parse(localStorage.getItem("canvasStore_" + key))

    //   //         }

    //   //       var b = performance.now();
    //   //       alert('It took ' + (b - a) + ' ms.');
    // };

    const handleDownloadClick = (/*e*/) => {
      
      // canvasStore.methods.saveToFavorites()

// console.log("image source list")
// console.log(canvasStore.methods.getMainImageSourcesList())
// console.log(canvasStore.methods.getMainImageBlobList())
// console.log(canvasStore.methods.getStringifiedState())




downloadImage();
    };


function handleSaveToFavoritesClick()
{
  if(persistentStore.state.loggedIn)
  {
    startHeavyCalculation(() => {
        apiPostSaveWork()
      });
  }
  else
  {
    alert("Csak akkor tudja a kedvencek közé menteni a képet, ha bejelentkezik.")
  }
   

}


function apiPostSaveWork() {
      
      let thumbnailImage = getThumbnailFile()
            let finalImage = getFile();

 
 let stringifiedState = canvasStore.methods.getStringifiedState()
     let mainImageSourceList =  canvasStore.methods.getMainImageSourcesList()
     let mainImageBlobList = canvasStore.methods.getMainImageBlobList()
     

      const formData = new FormData();
      formData.append("label", downloadFileName.value);
       
      formData.append("parentId", persistentStore.state.userId)

      formData.append("thumbnailImage", thumbnailImage, thumbnailImage.name);
            formData.append("finalImage", finalImage, finalImage.name);


      formData.append("state", stringifiedState);
      formData.append("mainImageSourceList", JSON.stringify(mainImageSourceList)/*.join("␟")*/);
      

          //console.log(thumbnailImage)


      let image_index = 0;
      for(let i = 0; i < mainImageSourceList.length; i++)
      {
        if(mainImageSourceList[i] == "dataurl")
        {
          console.log( mainImageBlobList[image_index])
          formData.append("image_"+i, mainImageBlobList[image_index], "image_"+i+".png");
          image_index++;
        }        
      }

     


      axios
        .post(  
          "verbalio_server/index.php?favorites=savework",
          // {
          //   headers: {  'content-type': 'multipart/form-data' },

          // }
          formData,
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }
          // { withCredentials: true }
        )
        .then((resp) => {
  

          console.log(resp);
          console.log(resp.data);

          endHeavyCalculation() 

        
        })
        .catch((err) => {
          endHeavyCalculation() 
          alert("Nem sikerült a kép kedvenvekbe mentése. Kérjük próbálja újra. Ha a probléma nem szűnik meg, kérjük forduljon a rendszergazdához.")
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }





    var dragStartX = 0;
    var dragStartY = 0;

    function handleTouchStart(e) {
      console.log("touch start " + e);
      if (
        canvasStore.state.configMainImage[
          canvasStore.state.actualMainImageIndex
        ].draggable
      ) {
        konvaMainImageRefs.value[canvasStore.state.actualMainImageIndex]
          .getNode()
          .startDrag();
      }
    }

    function handleClick(e, index) {
      console.log("image clicked> " + index + " " + e);
      console.log(
        "zindex: " + konvaMainImageRefs.value[index].getNode().zIndex()
      );
      // konvaMainImageRefs.value[index].getNode().moveToTop()
    }

    const handleDragend = (e, index) => {
      // console.log("dragended" + e);
      console.log("dragEnd" + index);
      console.log(e);
      console.log(dragStartX);
      console.log(dragStartY);

      console.log("e: " + e.evt.x);
      console.log("e: " + e.evt.y);

      console.log(e.target.attrs.x);

      canvasStore.state[canvasStore.state.actualMainImage.type][
        canvasStore.state.actualMainImage.index
      ]._dragX += e.target.attrs.x - dragStartX;
      canvasStore.state[canvasStore.state.actualMainImage.type][
        canvasStore.state.actualMainImage.index
      ]._dragY += e.target.attrs.y - dragStartY;

      // konvaMainImageRefs.value[index].getNode().moveToTop()

      // console.log("difX: " + canvasStore.state.configMainImage[0]._dragX)
      // console.log("difY: " + canvasStore.state.configMainImage[0]._dragY)

      //  canvasStore.methods.recalculateImageSizes()
    };

    const handleDragstart = (e, index) => {
      //     document.write('<img src="'+img+'"/>');

      // konvaMainImageRefs.value[index].getNode().moveToTop()
      console.log(index);

      dragStartX = e.target.attrs.x; //e.evt.x
      dragStartY = e.target.attrs.y; //e.evt.y

      // console.log("s: "+ dragStartX);
      //        console.log("s: "+dragStartY);
      //        console.log(e.target.attrs.x)
      // console.log("konva iamge end");

      // save drag element:
      // console.log("dragStarted" + e);
      console.log(e);
      /*
      //console.log(konvaUpperText.value)

console.log( konvaUpperText.value )

*/
      // console.log(Object.getOwnPropertyNames(konvaUpperText.value));
      // console.log(konvaUpperText.value.__konvaNode);
      // we also can save uri as file
      // but in the demo on Konva website it will not work
      // because of iframe restrictions
      // but feel free to use it in your apps:
      // downloadURI(uri, 'stage.png');

      // console.log("konva: " + konvaCanvas.value.getStage());

      /*var ratio = 10//3000 / lastSize.value;

      const uri = konvaCanvas.value.getStage().toDataURL({
        pixelRatio: ratio, // or other value you need
      });
      //console.log(uri);
      var link = document.createElement("a");
      link.download = "stage.png";
      link.href = uri;
      link.click();*/
    };

    windowResizeEventHanler();

    window.addEventListener("resize", windowResizeEventHanler);

    function windowResizeEventHanler() {
      var currentSize = window.innerHeight * 0.7;

      canvasStore.methods.setStageSize(currentSize, currentSize);
    }
    /*



      /*
      if (lastSize.value != 0) {
        var originalWidth = configImage.value.width;
        var originalHeight = configImage.value.height;

        configImage.value.width =
          (originalWidth / lastSize.value) * currentSize;
        configImage.value.height =
          (originalHeight / lastSize.value) * currentSize;
      }
*/
    /*    lastSize.value = currentSize;

      console.log("configKonva width" + configKonva.value.width);*/

    function handleAddExtraImageButtonClick() {
      store.state.extraImageAdderState = true;
      router.push("search");
    }

    function textHeightChanged() {
      // console.log("textHeightChanged")
      // console.log(konvaUpperText.value.__konvaNode)
      // console.log(konvaUpperText.value.__konvaNode.height())
      // console.log(konvaUpperText.value.__konvaNode.getTextHeight())
      canvasStore.methods.textHeightChanged(
        konvaUpperText.value.__konvaNode.height() -
          canvasStore.state.configText.upperText.padding * 2,
        konvaLowerText.value.__konvaNode.height() -
          canvasStore.state.configText.lowerText.padding * 2
      );
    }

    function updateFirstLetter(location) {
      // console.log("update first leter " + location);

      var firstRowWidth = 0;

      if (location == "upperText") {
        if (konvaUpperText.value != null) {
          firstRowWidth = konvaUpperText.value.__konvaNode.textArr[0].width;
        }
      } else if (location == "lowerText") {
        if (konvaLowerText.value != null) {
          firstRowWidth = konvaLowerText.value.__konvaNode.textArr[0].width;
        }
      }

      //console.log(konvaLowerText.value.__konvaNode.height())

      canvasStore.methods.setFirstLetter(
        location,
        canvasStore.state.configText[location].text,
        firstRowWidth
      );
    }

    function alignLowerTextToBottom() {
      canvasStore.methods.alignLowerTextToBottom();
    }

    function setDownloadFileName()
    {
      if(canvasStore.state.configText.upperText.text.trim() != "")
      {
                  downloadFileName.value = canvasStore.state.configText.upperText.text.trim().replaceAll(" ", "_");
          } else {
            downloadFileName.value = canvasStore.state.imageMetaData.synonyms[0]
              .trim()
              .replaceAll(" ", "_");
          }
    }
    // watch for upperrText.text change...if happens, we need to update the first letter
    watch(
      () => canvasStore.state.configText.upperText.text,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          // if (newValue.trim() != "") {
          //   downloadFileName.value = newValue.trim().replaceAll(" ", "_");
          // } else {
          //   downloadFileName.value = canvasStore.state.imageMetaData.synonyms[0]
          //     .trim()
          //     .replaceAll(" ", "_");
          // }
          setDownloadFileName()

          updateFirstLetter("upperText");
          textHeightChanged();
        }
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    // watch for upperText.fontFamily change...if happens, we need to update the first letter
    watch(
      () => canvasStore.state.configText.upperText.fontFamily,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          updateFirstLetter("upperText");
          textHeightChanged();
        }
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    // watch for upperText.fontSize change...if happens, we need to update the first letter
    watch(
      () => canvasStore.state.configText.upperText.fontSize,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          updateFirstLetter("upperText");
          textHeightChanged();
        }
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    // watch for upperText.fontSize change...if happens, we need to update the first letter
    watch(
      () => canvasStore.state.configBackgroundRect.strokeWidth,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          updateFirstLetter("upperText");
          updateFirstLetter("lowerText");
          textHeightChanged();
        }
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    // watch for upperText.fontSize change...if happens, we need to update the first letter
    watch(
      () => canvasStore.state.configGeneral._outlineColor,
      (newValue, oldvalue) => {
        changeOutlineColor(oldvalue.color, newValue.color);
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    // const configMainImageLength = computed(
    //   () => canvasStore.state.configMainImage.length
    // );

    // // when new image is added, we have to color it according to the settings
    // watch(
    //   konvaMainImageRefs.value,
    //   (/*newValue, oldvalue*/) => {
    //     //         console.log("configMainImage changedd");
    //     //         console.log(configMainImageLength.value);
    //     //         console.log(newValue);
    //     //         console.log(oldvalue);

    //     // alert("a")
    //     //         if (newValue.length > oldvalue.length) {
    //     //           alert("b")
    //     //           console.log("configMainImage changed length");
    //     if (canvasStore.state.configMainImage.length > 1) {
    //       // alert("");
    //       canvasStore.state.configMainImage[
    //         canvasStore.state.configMainImage.length - 1
    //       ].image.onload = function() {
    //         changeOutlineColor(
    //           canvasStore.state.configGeneral._defaultOutlineColor.color,
    //           canvasStore.state.configGeneral._outlineColor.color
    //         );

    //         startHeavyCalculation(() => {
    //           evaluateAppearanceStateForSingleImage(
    //             canvasStore.state.configMainImage.length - 1
    //           );
    //         });

    //         endHeavyCalculation();

    //         // evaluateAppearanceState()
    //       };
    //     }

    //     // }
    //   },
    //   { flush: "post" }
    // ); // {flush: post} only triggers callback after DOM update

    // watch for lowerText.text change...if happens, we need to update the first letter
    // we also need to upgrade the y position (vertical alignment)
    watch(
      () => canvasStore.state.configText.lowerText.text,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          updateFirstLetter("lowerText");
          textHeightChanged();
          alignLowerTextToBottom();
        }
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    // watch for lowerText.fontFamily change...if happens, we need to update the first letter
    // we also need to upgrade the y position (vertical alignment)
    watch(
      () => canvasStore.state.configText.lowerText.fontFamily,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          updateFirstLetter("lowerText");
          textHeightChanged();
          alignLowerTextToBottom();
        }
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    // watch for lowerText.fontSize change...if happens, we need to update the first letter
    // we also need to upgrade the y position (vertical alignment)
    watch(
      () => canvasStore.state.configText.lowerText.fontSize,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          updateFirstLetter("lowerText");
          textHeightChanged();
          alignLowerTextToBottom();
        }
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    watch(
      () => canvasStore.state.configGeneral._grayScale,
      (/*newValue, oldValue*/) => {
        // console.log("grayScale " + newValue + " oldvalue " + oldValue);
        setTimeout(function() {
          evaluateAppearanceState();
        }, 25);
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    watch(
      () => canvasStore.state.configGeneral._outline,
      (/*newValue, oldValue*/) => {
        // console.log("grayScale " + newValue + " oldvalue " + oldValue);
        setTimeout(function() {
          evaluateAppearanceState();
        }, 25);
      },
      { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update


    // watch(
    //   () => canvasStore.state.configMainImage,
    //   (newValue, oldValue) => {
    //     if(newValue.length > oldValue.length)
    //     {
    //     console.log("valtozott a meret!!!")
    //     }
    //     else
    //     {
    //       console.log("nem valtozott!!!")
    //     }
    //   },
    //   {deep:true, flush: "post" }
    // ); // {flush: post} only triggers callback after DOM update


    // watch for bring to front request (=topElementIndex change)
    // watch(
    //   () => canvasStore.state.topElementIndex,
    //   (newValue) => {
    //     console.log("topelement index changed")
    //      konvaMainImageRefs.value[newValue].getNode().moveToTop()
    //   }
    //   // ,
    //   // { flush: "post" }
    // ); // {flush: post} only triggers callback after DOM update

    watch(
      () => canvasStore.state.actualMainImageZIndex,
      (newValue, oldValue) => {
        let actualZIndex = konvaMainImageRefs.value[
          canvasStore.state.actualMainImageIndex
        ]
          .getNode()
          .zIndex();

        // console.warn(actualZIndex);
        if (newValue > oldValue) {
          if (actualZIndex < konvaMainImageRefs.value.length) {
            konvaMainImageRefs.value[canvasStore.state.actualMainImageIndex]
              .getNode()
              .moveUp();
          }
        } else {
          if (actualZIndex > 1) {
            konvaMainImageRefs.value[canvasStore.state.actualMainImageIndex]
              .getNode()
              .moveDown();
          }
        }
      }
      // ,
      // { flush: "post" }
    ); // {flush: post} only triggers callback after DOM update

    // watch(
    //   () => canvasStore.state.actualMainImageIsOnTop,
    //   (newValue) => {
    //     console.log("actual main image is on top changed");
    //     if (newValue) {
    //       konvaMainImageRefs.value[canvasStore.state.actualMainImageIndex]
    //         .getNode()
    //         .moveToTop();
    //     } else {
    //       // konvaMainImageRefs.value[canvasStore.state.actualMainImageIndex].getNode().moveToBottom()
    //     }
    //   }
    //   // ,
    //   // { flush: "post" }
    // ); // {flush: post} only triggers callback after DOM update


// watch(
//   ()=>canvasStore.state.configMainImage.length,
//   (newValue, oldValue) => {
//     // if (newValue.length != oldValue.length) {
//       console.log("valtozas@@");
//       console.log(newValue);
//       console.log(oldValue);
//     // }
//   },
  
// );


    onMounted(() => {
      // init

            persistentStore.methods.init();

      isStudent.value = persistentStore.state.userRole == "student";

      // canvasStore.methods.setText(canvasStore.state.configText.upperText._rawText)
      // canvasStore.methods.setText(canvasStore.state.configText.lowerText._rawText)
      // canvasStore.state.configText.upperText._rawText = "a"
      // canvasStore.state.configText.lowerText._rawText = "a"

      //     canvasStore.state.configText.upperText._rawText = ""
      // canvasStore.state.configText.lowerText._rawText = ""

      updateFirstLetter("upperText");
      updateFirstLetter("lowerText");
      textHeightChanged();
      alignLowerTextToBottom();

      if (props.controlsVisible) {
        // downloadFileName.value = canvasStore.state.imageMetaData.synonyms[0]
        //   .trim()
        //   .replaceAll(" ", "_");
        setDownloadFileName()
      }


      // evaluateAppearanceState()

    });

    return {
      canvasStore,
      konvaCanvas,
      isStudent,
      // handleFavouriteClick,
      handleDownloadClick,
      handleSaveToFavoritesClick,
      configLayer,
      configCircle,
      handleDragstart,
      handleDragend,
      handleTextChanged,
      lastSize,
      konvaUpperText,
      konvaLowerText,
      konvaLayer,
      konvaImage,
      handlePaintBucketClick,
      floodFill,
      fastFloodFill,
      handlePaintBucketTouch,
      getDataURL,
      getFile,
      getThumbnailFile,
      downloadFileName,
      setMainImageRefs,
      handleClick,
      handleTouchStart,
      heavyCalculationInProgress,
      deleteButtonDisabled,
      undoButtonDisabled,
      redoButtonDisabled,
      handleAddExtraImageButtonClick,
      imageDeleterFloatingWindowVisible,
      imageDeleteRequested,
    };
  },
};
</script>

<style scoped>

 

#infiniteLoadingBar {
  position: fixed;
  width: 100vw;
  z-index: 999999999999;
  /* padding-top: 1vh; */
}

#canvasDiv {
  display: grid;

  grid-template-rows: min-content;
  grid-template-columns: min-content;

  /* border-width: 1px; */
  /* border-color: red; */
  /* border-style: solid; */
}

/*.konvajs-content {*/
#canva {
  border: 1px solid #959595;

  /*margin-top: 100px;

  height: 50vh;
  width: 50vh;

  background-color: wheat;*/
}

.circle {
  border: 1px solid #000000;
}

/* #canvasDiv:hover {
  cursor: url('data:image/x-icon;base64,AAACAAEAICAQAAAAAADoAgAAFgAAACgAAAAgAAAAQAAAAAEABAAAAAAAAAIAAAAAAAAAAAAAEAAAAAAAAAAAAAAAR0dHAP///wAiIiIAMjIyABYWFgA9PT0AODg4AE9PTwBfX18AKioqAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAiIiIiIiIiIgAAAAAAAAAACgAAAKMAAAIAAAAAAAAAAAAAAAoiMAACAAAAAAAAAAAAAAACIiYAAgAAAAAAAAAAYAAAIiIiMAIAAAAAAAAAADAAAiIiIiACAAAAAAAAAAUFACIiIiIiQgAAAAAAAAADAKIiIiIiIgIAAAAAAAAAAGMiImIiIiECAAAAAAAAAAUAIiApIiJgAgAAAAAAAAAApgIiAiIgAAIAAAAAAAAAAAAKIoIiAAACAAAAAAAAAAAABwICIAAAAgAAAAAAAAAAAAAAAlAAAAIAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAAAACgAIAAAgAAAAAAAAAAAAAAAKAAAAIAAAAAAAAAD///////////////////////////////////////////////////////////////////////////////+AAH//359//58Pf/8eB3//HAN//xgBf/8QAH//AAB//wABf/8AA3//gAd//8APf//wD3///C9///5vf//+73///x9//w=='), auto;
}  */

#controlDiv {
  margin-top: 0.4vh;
  display: grid;
  /* gap: 0.3vh; */
  grid-template-columns: 1fr;
  /* align-items: center;
  justify-items: start; */
  padding-left: 1vh;
  padding-right: 1vh;
}

.row{
 /* display: grid;
  grid-template-columns: max-content 1fr;
  
   align-items: center;*/
  /* justify-items: left;  */
  /* background-color: red; */
  padding-left: 1vh;
  gap: 0.5vh;
}


.extraImageControlsDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.extraImageButtonContainer {
  height: 5.7vh;
  /* background-color: blueviolet; */
  display: grid;
  justify-content: stretch;
}

.imageButtonContainer {
  height: 5.7vh;
  /* background-color: blueviolet; */
  grid-template-columns: 1fr 1fr;
  display: grid;
  justify-content: stretch;
  margin-left: 2vh;
}

.restoreButtonsDiv {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}

.downloadSectionDiv {
  display: grid;
  grid-template-columns: min-content 1fr;
}

.divTopSeparator {
  border-top: 1px;
  border-top-color: #ccc;
  border-top-style: solid;
}

.restoreButtonContainer {
  height: 5.7vh;
  /* background-color: blueviolet; */
  display: grid;
  justify-content: stretch;
}

.downloadFileNameContainer {
  /* background-color: red; */
  display: grid;
  justify-content: left;
  align-content: center;

  grid-template-columns: min-content min-content max-content;

  margin-left: 1.5vh;
}

.downloadFileNameLabel {
  font-size: var(--font_size_editor_box_label);
  display: grid;
  justify-content: left;

  user-select: none;
}

.downloadFileNameInput {
  font-size: var(--font_size_editor_box_input);
  margin-left: 1vh;
  margin-right: 0.5vh;
  width: 6vw;
}
</style>
