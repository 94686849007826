<template>
  <div>
    <div id="confirmation">
      <div>
        Regisztráció megerősítése...
      </div>

      <Spinner v-if="isLoading" />
      <div id="feedback">
      <div id="success" v-if="isSuccesful">
        <i class="succesIndicatorIcon fas fa-check-circle"></i>
        Sikeres.    
          Kattintson   <router-link to="/logout"> ide a bejelentkezéshez</router-link>.
      </div>
      <div id="error" v-else>
        <i
         
            class="errorIndicatorIcon fas fa-exclamation-circle"
          ></i>
        Nem sikerült. :(
      </div>
</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { /* useRouter,*/ useRoute } from "vue-router";
import axios from "axios";

import Spinner from "@/components/Spinner.vue";

// import VueAxios from 'vue-axios'
// Vue.use(VueAxios.axios)
import PersistentStore from "../store/persistent";

export default {
  components: {
    Spinner,
  },

  setup() {
    // const router = useRouter();
    const route = useRoute();
    const persistentStore = PersistentStore;

    /* const resultText = ref("");*/
    const isLoading = ref("true");
    const isSuccesful = ref("false");
    

    function apiPost() {
      axios
        .post(
          "verbalio_server/index.php?users=confirmregistration",
          {
            confirmationId: route.query.confirmationId,
            role: route.query.role,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          /* resultText.value = "sikeres!";*/
                   isSuccesful.value = true;
                   isLoading.value=false;
                   // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
          /* resultText.value = "sikertelen!";*/
          isSuccesful.value = false;
          isLoading.value=false;
        });
    }

    onMounted(() => {
      persistentStore.methods.init();

      //  console.log(route.query.confirmationId)
      apiPost();
    });

    return {
      apiPost,
      isSuccesful,
      isLoading,
      /*  resultText,*/
    };
  },
};
</script>

<style scoped>
#confirmation {
  /*color: darkred;*/
  font-size: var(--font_size_normal);
  font-weight: bold;
  padding: 3vh;
}

#success 
{
  color: green;
}

#error 
{
  color: red;
}

#feedback
{
  margin-top: 3vh;
}
 
</style>
