<template>
  <div id="synonymsBox">
   
   <div
    v-for="index in synonyms.length+1"
      :key="'sy_' + (index-1)"
    >
    <input
      class="synonymInput"
  
      type="text"
      :tabindex="index"
      :value="synonyms.at((index-1))"
      placeholder="+ szinoníma"
      @focus="updateSynonyms($event.target.value, (index-1))"
      @blur="updateSynonyms($event.target.value, (index-1))"
    />


    <button v-if="(index-1)<synonyms.length" @click="updateSynonyms('', (index-1))">x</button>
</div>
  </div>
</template>

<script>
// import { ref } from "vue";
// import { onMounted } from "vue";
// import axios from "axios";

export default {
  props: {
    synonyms: Array,
  },
  setup(props, { emit }) {
    function updateSynonyms(newValue, index) {
      //   console.log(newValue + " " + index);

      newValue = newValue.trim();
      var newSynonyms = [...props.synonyms];

      if (index == props.synonyms.length) {
        // console.log("push");
         newSynonyms.push("")
      } else {
        if (newValue == "") {
        //   console.log("splice");
          newSynonyms.splice(index, 1);
        } else {
        //   console.log("override");
          newSynonyms[index] = newValue;
        }
      }

      console.log(newSynonyms);

      emit("update:synonyms", newSynonyms);
    }

    return {
      updateSynonyms,
    };
  },
};
</script>

<style scoped>
#synonymsBox {
  text-align: left;
  font-size: var(--font_size_normal);
  display:grid;  
}

.synonymInput:focus::-webkit-input-placeholder {   color:transparent;      }
.synonymInput:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
.synonymInput:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
.synonymInput:focus:-ms-input-placeholder { color:transparent; } /*IE 10+*/

 
</style>