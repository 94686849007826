<template>
  <FloatingWindowBase>
    <template v-slot:title>
      {{ windowTitle }}
    </template>

    <template v-slot:content>
      <div class="imageListContainer">
        <div

   
          v-for="(image, index) in imageModel"
          :key="'itd_' + index"
          @click="selectImage(index)"
          class="imageItemContainer"
          v-bind:class="{
            selectedImage: selectedImageIndex == index,
          }"
        
        

        >
        
          <img
            class="thumbnailImage"
            :src="
              image._unmodifiedImageSrc.replace('_raw.png', '_thumbnail.png')
            "
            draggable="false"
          />

          {{ image._label }}
        </div>
      </div>
    </template>

    <template v-slot:buttons>
      <FloatingWindowButton @click="emit('cancelButtonClicked')">
        Mégse
      </FloatingWindowButton>
      <FloatingWindowButton
        @click="
          if (selectedImageIndex != -1) {
            emit('acceptButtonClicked', selectedImageIndex);
          }
        "
        :class="{ disabled: selectedImageIndex == -1 }"
      >
        {{ acceptButtonLabel }}
      </FloatingWindowButton>
    </template>
  </FloatingWindowBase>
</template>

<script>
import { ref, onMounted } from "vue";

import FloatingWindowBase from "@/components/FloatingWindowBase.vue";
import FloatingWindowButton from "@/components/FloatingWindowButton.vue";

export default {
  components: {
    FloatingWindowBase,
    FloatingWindowButton,
  },
  props: {
    windowTitle: {
      type: String,
      default: "Melyik képet töröljük a vászonról?",
    },
    acceptButtonLabel: {
      type: String,
      default: "Törlés",
    },
    imageModel: Array,
  },
  emits: ["cancelButtonClicked", "acceptButtonClicked"],

  setup(props, { emit }) {
    const selectedImageIndex = ref(-1);

    onMounted(() => {
    
    selectedImageIndex.value = -1
    
    });
    
    function selectImage(index) {
      selectedImageIndex.value = index;
    }



    return {
      emit,

      selectedImageIndex,

      selectImage,
    };
  },
};
</script>

<style scoped>
.floatingWindowInputMargin {
  margin-bottom: 4vh;
  padding: 0.5vh;
}

.disabled {
  color: gray;
}
.disabled:hover {
  color: gray;
}

.imageListContainer {
  height: 45vh;

  /* background-color: pink; */
  /* display:grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: start; */
  overflow-y: auto;
  overflow-x: auto;

  min-width: auto;

  margin-left: -2.5vh;
  margin-right: -2.5vh;

  /* padding-right: 5vh;   */

  /* background-color: blueviolet; */
}

.imageItemContainer
{
  display: grid;

  grid-template-columns: min-content 1fr;

grid-gap: 2vh;

  align-items: center;
  justify-items: start;
}

.imageItemContainer:hover {
  background-color: #fffbd4;
  color: black;
}

.selectedImage.imageItemContainer:hover,
.selectedImage {
  background-color: var(--primary_color);
  color: var(--light_text_color);

  /* font-size: 10px; */
}

.thumbnailImage {
  height: 10vh;
  /* padding: 1vh; */
  margin-right: 1vh;
}
</style>
