<template>
  <!-- <div>
    <button @click="apiGetFavorites">Get favoirtes</button>
    <button @click="folderAdderWindowVisible = true">
      Add favoirte folder
    </button>
  </div> -->


  <FloatingWindowBase
    id="deleteConfirmationWindow"
    v-if="deleteConfirmationWindowVisible"
  >
    <template v-slot:title>
      Biztos törli?
    </template>

    <template v-slot:buttons>
      <FloatingWindowButton @click="deleteConfirmationWindowVisible = false"
        >Mégse</FloatingWindowButton
      >
      <FloatingWindowButton @click="apiDeleteFavouriteItem"
        >Törlés</FloatingWindowButton
      >
    </template>
  </FloatingWindowBase>

  <SingleInputFloatingWindow
    id="renameWindow"
    v-if="renameWindowVisible"
    windowTitle="Mi legyen az új neve?"
    acceptButtonLabel="Átnevezés"
    @cancelButtonClicked="renameWindowVisible = false"
    @acceptButtonClicked="apiRenameFavouriteItem"
    v-model:inputModel="renameWindowInputModel"
  >
  </SingleInputFloatingWindow>

  <SingleInputFloatingWindow
    id="folderAdderWindow"
    v-if="folderAdderWindowVisible"
    windowTitle="Mi legyen az új mappa neve?"
    acceptButtonLabel="Hozzáadás"
    @cancelButtonClicked="folderAdderWindowVisible = false"
    @acceptButtonClicked="apiPostAddFavoriteFolder"
    v-model:inputModel="folderAdderWindowInputModel"
  >
  </SingleInputFloatingWindow>

  <FloatingWindowBase id="renamingWindow" v-if="false">
    <template v-slot:title>
      Mi legyen a mappa új neve?
    </template>

    <template v-slot:content>
      <FloatingWindowInput
        class="floatingWindowInputMargin"
        ref="renameWindowInput"
        v-model="renameWindowInputModel"
      />
    </template>

    <template v-slot:buttons>
      <FloatingWindowButton @click="renameWindowVisible = false">
        Mégse
      </FloatingWindowButton>
      <FloatingWindowButton>
        Átnevezés
      </FloatingWindowButton>
    </template>
  </FloatingWindowBase>

  <ItemMoverFloatingWindow
    v-if="itemMoverWindowVisible"
    @cancelButtonClicked="itemMoverWindowVisible = false"
    @acceptButtonClicked="(newParentId) => apiMoveFavouriteItem(newParentId)"
    v-model:inputModel="renameWindowInputModel"
    :userId="actualUserId"
  >
  </ItemMoverFloatingWindow>

<div id="contentContainer"> 

  <div
    id="favouriteBreadCrumbContainer"
    :class="{
      hidefavouriteBreadCrumbContainer: !favouriteBreadCrumbContainerVisible,
    }"
  >
  

         <!-- <select
         v-if="isAdmin"
          id="studentSelector"
          v-model="selectedStudent"
         >
          <option v-for="(student, student_index) in studentsModel" :key="'st_' + student_index" :value="student.userId">{{student.fullName}} - {{student.email}}</option>
    
 
        </select> -->


    <BreadCrumb
      v-model:items="breadCrumbModel"
      v-model:selectedItem="selectedBreadCrumb"
    />


    <div id="freeStorageSpaceContainer" >
    <div id="freeStorageSpaceIndicatorContainer"  >

      <div id="freeStorageSpaceLabel" >

  Felhasznált tárhely: {{usedStorageSpacePercentage}}%
  </div>


  <div id="availableStorageSpaceIndicator">

    <div id="usedStorageSpaceIndicator"
    
    v-bind:style="{
        width: usedStorageSpacePercentage + '%'
      }"
    
    > </div>

   </div>

      </div>
    </div>

  </div>



  <div id="imageGridContainer">
    <Spinner id="imageLoadingSpinner" v-show="loadingState" />


<div id="emptyFolderNotifier" v-show="filteredFavoriteModel.length==0">
    <div  v-if="breadCrumbModel.length<=1">
      <i class="far fa-folder-open"></i>

      A kedvencek mappád még üres. <br/><br/>
  <div>
     
          A <router-link to="/search">  <i class="fa fa-fw fa-plus"></i> HOZZÁADÁS</router-link> oldalon bármelyik neked tetsző képet ide helyezheted,<br/>ha a kép melletti <i class="far fa-fw  fa-plus-square"></i>  gombra kattintasz.
    </div>
    </div>


    <div   v-else>
      <i class="far fa-folder-open"></i>

      {{breadCrumbModel[breadCrumbModel.length-1].label}} mappád még üres. <br/><br/>
  <div>
    A <router-link to="/search">  <i class="fa fa-fw fa-plus"></i> HOZZÁADÁS</router-link> oldalon bármelyik neked tetsző képet ide helyezheted,<br/>ha a kép melletti <i class="far fa-fw  fa-plus-square"></i>  gombra kattintasz.
    </div>
    </div>

    </div>


    <ImageGridBase
      id="imageGrid"
      v-show="!loadingState"
      :images="filteredFavoriteModel"
      :actualUserId="actualUserId"
      @folderClicked="(folder) => stepIntoFolder(folder)"
    >
      <template v-slot:controlButtons="slotProps">
        <QuickImageButton
          class="quickControl"
          iconClass="fas fa-ellipsis-v"
          @click="showContextMenu($event, slotProps.image)"
        />

        <a
          v-if="slotProps.image.type != 'folder' && !isStudent"
          class="controlLink"
          :href="
            (slotProps.image.type == 'savedWork'? 'verbalio_server/savedWorks/' /*+ actualUserId + '/'*/ : 'verbalio_server/uploadedImages/') +
              slotProps.image.photoId +
              '_final.png'
          "
          :download="
            slotProps.image.synonyms[0].trim().replaceAll(' ', '_') + '.png'
          "

        
        >
          <!-- <QuickImageButton
            class="quickControl"
            iconClass="far fa-fw fa-arrow-alt-circle-down"
          />

          <a
            v-if="slotProps.image.type != 'folder'  && !isStudent"
            class="controlLink"
            :href="
              'verbalio_server/print.php?imagePath=' +     (slotProps.image.type == 'savedWork'? 'savedWorks/'  /*+ actualUserId + '/'*/ : 'uploadedImages/') +
              slotProps.image.photoId +
              '_final.png'
            "
            target="_blank"
          >
            <QuickImageButton
              class="quickControl"
              iconClass="fas fa-fw fa-print"
            />
          </a> -->
        </a>
      </template>
    </ImageGridBase>
  </div>

</div>

  <div
    id="contextMenuContainer"
    v-if="contextMenuvisible"
    @click="hideContextMenu"
  >
    <div
      id="contextMenu"
      v-bind:style="{
        '--mouse-x': contextMenuPosition.x + 'px',
        '--mouse-y': contextMenuPosition.y + 'px',
      }"
    >
      <div class="contextMenuItem" @click="itemMoverWindowVisible = true">
        Áthelyezés
      </div>
      <div
        class="contextMenuItem"
        @click="deleteConfirmationWindowVisible = true"
      >
        Törlés
      </div>
      <div
        class="contextMenuItem"
        @click="showRenameWindow"
       
      >
        Átnevezés
      </div>
    </div>
  </div>

  <!-- <FloatingActionButton
    label="Új kép"
    iconClass="fas fa-fw fa-image"
    @click="router.push('search')"
    id="newPicture"
    
    />

  <FloatingActionButton
    label="Új mappa"
    iconClass="fas fa-fw fa-folder-plus"
    @click="folderAdderWindowVisible = true"
  /> -->
</template>

<script>
import axios from "axios";
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios.axios)
import { ref, onMounted, watch, computed } from "vue";
import PersistentStore from "../store/persistent";

import BreadCrumb from "@/components/BreadCrumb.vue";

import QuickImageButton from "@/components/QuickImageButton.vue";
import ImageGridBase from "@/components/ImageGridBase.vue";

import Spinner from "@/components/Spinner.vue";

import FloatingWindowBase from "@/components/FloatingWindowBase.vue";
import FloatingWindowButton from "@/components/FloatingWindowButton.vue";
import FloatingWindowInput from "@/components/FloatingWindowInput.vue";

import SingleInputFloatingWindow from "@/components/SingleInputFloatingWindow.vue";
// import FloatingActionButton from "@/components/FloatingActionButton.vue";

import ItemMoverFloatingWindow from "@/components/ItemMoverFloatingWindow.vue";

import Store from "../store/store";

import { useRouter } from 'vue-router'


export default {
  components: {
    BreadCrumb,
    ImageGridBase,
    Spinner,
    QuickImageButton,
    FloatingWindowBase,
    FloatingWindowButton,
    FloatingWindowInput,
    SingleInputFloatingWindow,
    // FloatingActionButton,
    ItemMoverFloatingWindow,
  },
  setup() {
     const router = useRouter();
    const persistentStore = PersistentStore;
    const store = Store;


    const isStudent = ref(false);
    const isAdmin = ref(false);

    const breadCrumbModel = ref([]);
    const selectedBreadCrumb = ref();

    const loadingState = ref(true);

    const favoriteModel = ref([]);
    const filteredFavoriteModel = ref([]);

    const contextMenuPosition = ref({ x: 0, y: 0 });
    const contextMenuvisible = ref(false);
    const contextMenuActualItem = ref({});

    const deleteConfirmationWindowVisible = ref(false);
    const renameWindowVisible = ref(false);
    const renameWindowInputModel = ref("");

    const itemMoverWindowVisible = ref(false);

    const folderAdderWindowVisible = ref(false);
    const folderAdderWindowInputModel = ref("");

    const favouriteBreadCrumbContainerVisible = ref(true);

    const studentsModel = ref([])
    const selectedStudent = ref("")

        const actualUserId = computed(() => /*persistentStore.state.userRole == 'admin'? selectedStudent.value : */ persistentStore.state.userId);


        const usedStorageSpace = ref(0);
        const availableStorageSpace = ref(1);
        const usedStorageSpacePercentage = computed(() => Math.round(usedStorageSpace.value/availableStorageSpace.value*100));


    //     function apiGetAvailableDiskSpace() {
    //       console.log("getting availableDiskSpace...")
    //   axios
    //     .post(
    //       "verbalio_server/index.php?upload=availableDiskSpace",
    //       {
       
    //       },
    //       {
    //         headers: { Authorization: `Bearer ${persistentStore.state.token}` },
    //       }
    //       // { withCredentials: true }
    //     )
    //     .then((resp) => {
    //       console.log("apiGetAvailableDiskSpace arrived...");
    //       console.log(resp);
    //       console.log(resp.data);
    //       alert(resp.data)
    //       // studentsModel.value = studentsModel.value.concat(new Array(resp.data))

         
    //       // console.log(JSON.parse(localStorage.getItem('userData')))

    //       // console.log(store.state);
    //     })
    //     .catch((err) => {
    //       console.log("error");
    //       console.log(err);
    //       console.log("error end");
    //       // resultText.value = "sikertelen!";
    //     });
    // }

        function apiGetStudents() {
          console.log("getting students...")
      axios
        .post(
          "verbalio_server/index.php?users=students",
          {
       
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          console.log("students arrived...");
          console.log(resp);
          console.log(resp.data);
          studentsModel.value = studentsModel.value.concat(resp.data)

         
          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
          // resultText.value = "sikertelen!";
        });
    }


    function apiGetAvailableStorageSpace() {
  
      axios
        .post(
          "verbalio_server/index.php?favorites=availableStorageSpace",
          {
            userId:  actualUserId.value,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }

          // { withCredentials: true }
        )
        .then((resp) => {
          console.log('apiGetAvailableStorageSpace')
          console.log(resp);
          console.log(resp.data);

          availableStorageSpace.value = resp.data.availableSpace
          usedStorageSpace.value = resp.data.usedSpace

         
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }
 
    function apiGetFavorites(withLoadingIndeicator = true) {
      loadingState.value = withLoadingIndeicator;



      apiGetAvailableStorageSpace();

      console.log("api get fav...");
      // console.log(persistentStore.state.userId);
      // console.log(persistentStore.state.token)

      console.log("actual breadCrumbModel:");
      console.log(breadCrumbModel.value);


      axios
        .post(
          "verbalio_server/index.php?favorites=get",
          {
            userId:  actualUserId.value,
            parentId: actualParentId(), //persistentStore.state.userId,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }

          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          // breadCrumbModel.value = resp.data;
          favoriteModel.value = resp.data;

          // console.log("breval");
          // console.log(actualParentId());

          filterAndSortFavoriteModel();

          selectedBreadCrumb.value = resp.data[0];

          loadingState.value = false;
          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function apiPostAddFavoriteFolder() {
      folderAdderWindowVisible.value = false;
      let newFolderLabel = folderAdderWindowInputModel.value;
      folderAdderWindowInputModel.value = "";

      axios
        .post(
          "verbalio_server/index.php?favorites=add",
          {
             userId:  actualUserId.value,
            parentId: actualParentId(),
            label: newFolderLabel,
            type: "folder",
            photoId: "",
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }

          // { withCredentials: true }

        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          apiGetFavorites(false);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function apiDeleteFavouriteItem() {
      deleteConfirmationWindowVisible.value = false;

console.log("deleting this item:")
console.log(contextMenuActualItem.value)

    // hozzaadjuk a userId-t
    

      axios
        .post(
          "verbalio_server/index.php?favorites=delete",
          {
            // headers: { Authorization: `Bearer ${persistentStore.state.token}` },
   userId:  actualUserId.value,
    item: contextMenuActualItem.value,
            
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }

          // { withCredentials: true }
        )
        .then((resp) => {
          console.log('delete finished');
          console.log(resp);
          console.log(resp.data);

          apiGetFavorites(false);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function apiRenameFavouriteItem() {
      renameWindowVisible.value = false;
      contextMenuActualItem.value.label = renameWindowInputModel.value;

      axios
        .post(
          "verbalio_server/index.php?favorites=rename",
          {
            // headers: { Authorization: `Bearer ${persistentStore.state.token}` },
             userId:  actualUserId.value,
            item: contextMenuActualItem.value,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }

          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          apiGetFavorites(false);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function apiMoveFavouriteItem(newParentId) {
      // console.log(newParentId);
      itemMoverWindowVisible.value = false;

      axios
        .post(
          "verbalio_server/index.php?favorites=move",
          {
            // headers: { Authorization: `Bearer ${persistentStore.state.token}` },
                userId: /*persistentStore.state.userRole == 'admin'? selectedStudent.value :*/ persistentStore.state.userId,
            favoriteId: contextMenuActualItem.value.favoriteId,
            parentId: newParentId,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }

          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          apiGetFavorites(false);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function filterAndSortFavoriteModel() {
      let parentId = actualParentId();

      console.log("actual parent id " + parentId);

      console.log(favoriteModel);

      filteredFavoriteModel.value = favoriteModel.value
        // .filter((item) => {
        //   return item.parentId == parentId;
        // })
        .sort((a, b) => {
          if (a.type == "folder") {
            if (b.type == "folder") {
              return a.label.localeCompare(b.label);
            } else {
              return -1;
            }
          } else {
            // if (b.type != "folder") {
            //   return a.synonyms[0].localeCompare(b.synonyms[0]);
            // } else {
            //   return 1;
            // }
          }
        });

      console.log("filtered model:");
      console.log(favoriteModel.value);
      console.log(filteredFavoriteModel.value);
      // filteredFavoriteModel.value = [...favoriteModel.value]
    }
    function showContextMenu(event, item) {
      // console.log('hello open local')
      //  let target = document.querySelector("#endOfDocument");

      //  console.log(target)

      console.log(event);

      contextMenuActualItem.value = item;
      contextMenuPosition.value.x = event.clientX;
      contextMenuPosition.value.y = event.clientY;

      contextMenuvisible.value = true;
    }

    function hideContextMenu() {
      contextMenuvisible.value = false;
    }

    function stepIntoFolder(folder) {
      // console.log(folder)
      store.state.actualFavoriteParentId = folder.favoriteId
      breadCrumbModel.value.push(folder);

      //store.state.breadCrumbModel = new Array()
      //store.state.breadCrumbModel = [...breadCrumbModel.value]
    }

    function actualParentId() {
      
      return breadCrumbModel.value[breadCrumbModel.value.length - 1].favoriteId;
    }

    function showRenameWindow() {
      renameWindowVisible.value = true;

      renameWindowInputModel.value = contextMenuActualItem.value.label;

      // // renameWindowInput.value.focus()
      // console.log(renameWindowInput.value);

      // renameWindowInput.value.$el.focus();
    }

    // watch(renameWindowInput, (newValue) => {
    //   if (newValue != null) {
    //     newValue.$el.focus();
    //   }
    // });


  function initBreadCrumbModel(favoriteId)
  {
    if(store.state.breadCrumbModel.length>0)
    {
      

      breadCrumbModel.value = new Array()
      breadCrumbModel.value =  [...store.state.breadCrumbModel]
      
      // breadCrumbModel.value.forEach(element => {
      //   stepIntoFolder(element)
      // });
      
    }
    else
    {
      

    store.state.actualFavoriteParentId = favoriteId

    breadCrumbModel.value = new Array()
    breadCrumbModel.value.push({
        favoriteId: favoriteId,
        label: "Kezdőlap",
        load: true,
        parentId: null,
        photoId: null,
        synonyms: [""],
        tagIds: [""],
        type: "folder",
        userId: persistentStore.state.userId,
        visibility: null,
        waterMarkCreator: null,
        waterMarkId: null,
        waterMarkPath: null,
      });
      
    }

  }

    onMounted(() => {
      console.log("onmounted fav page");

      addScrollListener();

      persistentStore.methods.init();

      isStudent.value = persistentStore.state.userRole == "student";
      isAdmin.value = persistentStore.state.userRole == "admin";

      console.log("mounted" )
      console.log(breadCrumbModel.value)
      if(breadCrumbModel.value.length==0)
      {
      initBreadCrumbModel(persistentStore.state.userId)
      }

// admin eseten le kell kerni a hallgatok listajat
if(persistentStore.state.userRole == 'admin')
{
  let adminUserData = 
  {
    userId: persistentStore.state.userId,
    fullName: persistentStore.state.userFullName,
    email: ' ÉN'/*persistentStore.state.userEmail*/,
  }

  studentsModel.value = new Array(adminUserData)

selectedStudent.value = adminUserData.userId

    apiGetStudents()

    // apiGetAvailableDiskSpace()
}

      //apiGetFavorites();
    });

    watch(
      breadCrumbModel,
      () => {
        console.log("breadcrumbmodelchanged");
         console.log(breadCrumbModel.value)
        // filterAndSortFavoriteModel();
        store.state.breadCrumbModel = breadCrumbModel.value
        if(breadCrumbModel.value.length > 0)
        {
          store.state.actualFavoriteParentId = breadCrumbModel.value[breadCrumbModel.value.length-1].favoriteId

          console.log("call apiGetFavorites()");

        apiGetFavorites();
        }
      },
      { deep: true }
    );


    // watch(
    //   selectedBreadCrumb,
    //   (newValue) => {
         
    //     if(newValue != undefined)
    //     {
    //     console.log("favid:" + newValue.favoriteId)
    //     store.state.actualFavoriteParentId = newValue.favoriteId
    //     }
    //   },
    //   { immediate: true }
    // );


      watch(
      selectedStudent,
      (newValue) => {
        console.log("selectedStudentChanged " + newValue);
 
        if(newValue != "")
        {
        initBreadCrumbModel(newValue)
        console.log("breadCrumbModel value")
        console.log(breadCrumbModel.value)
        // apiGetFavorites(true)
        //persistentStore.state.userId
        }
      },
      { immediate: true }
    );

    /* When the user scrolls down, hide the searchbar. When the user scrolls up, show the favouriteBreadCrumbContainer */
    var prevScrollpos;
    var initHappened; // on initing the view, we have to always show the favouriteBreadCrumbContainer

    function addScrollListener() {
      initHappened = false;
      prevScrollpos = window.pageYOffset;
      favouriteBreadCrumbContainerVisible.value = true;

      window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if (!initHappened) {
          favouriteBreadCrumbContainerVisible.value = true;
        } else {
          if (prevScrollpos > currentScrollPos) {
            favouriteBreadCrumbContainerVisible.value = true;
          } else {
            favouriteBreadCrumbContainerVisible.value = false;
          }
          prevScrollpos = currentScrollPos;
        }
      };
      
      initHappened = true;

    }

    return {
      apiGetFavorites,
      apiPostAddFavoriteFolder,
      apiMoveFavouriteItem,
      breadCrumbModel,
      selectedBreadCrumb,
      loadingState,
      favoriteModel,
      filteredFavoriteModel,
      showContextMenu,
      hideContextMenu,
      contextMenuPosition,
      contextMenuvisible,
      contextMenuActualItem,
      deleteConfirmationWindowVisible,
      renameWindowVisible,
      renameWindowInputModel,

      folderAdderWindowVisible,
      folderAdderWindowInputModel,

      showRenameWindow,

      apiDeleteFavouriteItem,
      apiRenameFavouriteItem,
      stepIntoFolder,

      favouriteBreadCrumbContainerVisible,
      itemMoverWindowVisible,

      isStudent,
      isAdmin,
      studentsModel,
      selectedStudent,
      actualUserId,
      usedStorageSpacePercentage,
      router
    };
  },
};
</script>

<style scoped>

/*#contentContainer
{
  display: grid;
}*/


#imageGridContainer {
  display: grid;
  justify-content: center;
  width: 100%;

  margin-top: 4vh;

  padding-bottom: 20vh;

  /* padding-top: 5vh; */

  /* float: right;   */

  /* background-color: yellow; */
}

.quickControl {
  height: 7vh;
}

.controlLink {
  text-decoration: none;
}

#contextMenuContainer {
  z-index: 100000;

  background-color: #00000050;

  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
}

#contextMenu {
  --mouse-x: 100px;
  --mouse-y: 100px;
  /* display: none; */
  position: fixed;

  z-index: 19999;
  margin: 0;
  left: 0;
  top: 0;
  /* The following line is responsible for all the magic */
  transform: translateX(min(var(--mouse-x), calc(100vw - 100%)))
    translateY(min(var(--mouse-y), calc(100vh - 100%)));

  background-color: white;

  user-select: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1vh;

  box-shadow: 0px 0px 10px 0px rgba(3, 85, 216, 0.15);
}

.contextMenuItem {
  background-color: white;

  padding: 2vh;

  font-size: var(--font_size_normal);
  font-weight: bold;
}

.contextMenuItem:hover {
  background-color: #fffbd4;
}

.contextMenuItem:active {
  background-color: var(--secondary_color);
  color: var(--light_text_color);
}

.floatingWindowInputMargin {
  margin-bottom: 4vh;
}

#favouriteBreadCrumbContainer {
  position: sticky;
  position: -webkit-sticky;
  background-color: var(--background_color_content_see_through);

 backdrop-filter: blur(10px);
 -webkit-backdrop-filter: blur(10px);
  padding: 2vh;

  /*width: 100%;*/
 left: 0;
  top: var(--height_header); 
  
  transition: top 0.5s; /* Transition effect when sliding down (and up) */

  box-shadow: 0 0 0.8vh 0 rgba(3, 85, 216, 0.15);
}

#favouriteBreadCrumbContainer.hidefavouriteBreadCrumbContainer {
  top: -40vh;
  overflow: hidden;
}

#responsive.mobile .hidefavouriteBreadCrumbContainer {
  top: -100vh;
  overflow: hidden;
}

#freeStorageSpaceContainer
{
  /*background-color: red;*/
  position: absolute;
  right: 1.1vw;
  top:0;
  /* display: grid; */
  display: none;
  height: 100%;

   align-content: center; 


  
}

#freeStorageSpaceIndicatorContainer
{
 
  
  /*height: 25px;*/
  /*width: 15vw;*/

  height: 5vh;
   background-color: rgb(255, 232, 245);  
  border-radius:  1vh;
   
  display: grid;
  grid-template-rows: 4fr 5px;   
  font-size: var(--font_size_normal);
}

#responsive.tablet #freeStorageSpaceIndicatorContainer
{
/*width: 17vw;  */
/*right: 1.1vw;*/
}

#responsive.mobile #freeStorageSpaceIndicatorContainer
{
  font-size: var(--font_size_small);
width: 20vw;  
height: 7vh;

/*right: 1.1vw;*/
}



#freeStorageSpaceLabel
{
    display: grid;
  
  justify-content: center;
  align-content: center;

  
  
}

#availableStorageSpaceIndicator
{
  background-color:  var(--secondary_color);
  border-radius: 0px;
}

#usedStorageSpaceIndicator
{
  background-color: red;
  height: 100%;
  border-radius: 0px;
  margin-left: 0px;
   
}

#emptyFolderNotifier
{
  margin-left: 10%;
  margin-right: 10%;

  padding-top: 5vh;

  font-size: var(--font_size_large);
  line-height: 4.5vh;

  font-weight: bold;
}

#newPicture
{
  margin-bottom: 15vh;

}

</style>
