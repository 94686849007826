<template>

      <div        class="colorItem"              
      
      ></div>

   
</template>

<script>
// import { onMounted, ref, watch } from "vue";
 

export default {
  components: {},
  props: {
    
  },

  setup(/*props*/) {
 
   

    return {
 
    };
  },
};
</script>

<style scoped>
.selected {
  outline-color: black;
  outline-width: 0.31vh;
  outline-style: solid;
  outline-offset: 0.31vh;
}

.redBlack
{
     background: linear-gradient(90deg, #ff0000 50%, #000000 50%);    
  
}


.transparent
{
    background-image: /* tint image */
                      linear-gradient(to right, rgba(218, 218, 218, 0.75), rgba(218, 218, 218, 0.75)),
                      /* checkered effect */
                      linear-gradient(to right, black 50%, white 50%),
                      linear-gradient(to bottom, black 50%, white 50%);
    background-blend-mode: normal, difference, normal;
    background-size: 1.3vh 1.3vh;
}

.lightColor
{
  border-style: solid;
  border-color: #cccccc;
  border-width: 0.1vh;
}

.colorItem {
  width: 3.7vh;
  height: 3.7vh;

  
}

.colorItem:hover
{
     
      opacity: 0.8;  
}

 





</style>