<template>
 
    <div class="imageButton">
      <i class="imageButtonIcon" v-bind:class="iconClass"></i>
      <slot> </slot>
    </div>
   
</template>

<script>
export default {
  props: {
    iconClass: { type: String, required: true }, 
  },
};
</script>

<style scoped>
.imageButton {
  display: grid;
  /* grid-template-columns: 1fr;minmax(auto, min-content) minmax(auto, max-content); */
  /* grid-template-columns:   11fr 8fr;*/
  /*grid-template-columns: minmax(min-content, 2fr) minmax(min-content, 8fr);*/
  align-content: center;
  justify-content: center;

  /* padding-top: 0.5vh; */

  /* column-gap: 1.5vh; */
  /* padding-left: 1vh; */
  /* padding-right: 1vh; */
  /*background-color: tomato;*/

  color: var(--primary_color);

user-select: none;

border-radius: 1vh;

 
}

 .imageButton:hover
 {
     background-color: var(--secondary_color);
     color: var(--light_text_color);
 }

 
.imageButtonIcon {
  font-size: var(--icon_size_normal);
  display: grid;
  align-content: center;
  justify-content: center;
  /*background-color: tomato;*/
}

 

 
 
 
</style>