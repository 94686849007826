<template>
  <CenteredWindowBase
    :isVisible="colorChooserWindowVisible"
    title="Színválasztó"
    @hideRequested="colorChooserWindowVisible = false"
  >
    <div class="colorType" v-for="(color, index) in colors" :key="index">
      <div class="colorTypeLabel">{{ color.type }}:</div>

      <div class="colorItemContainer">
        <!-- <div
          class="colorItem"
          :class="{ selected: item == selectedColor }"
          :style="{ backgroundColor: item }"
          v-for="(item, index) in color.items"
          :key="index"
          @click="selectColor(item)"
        ></div> -->

        <ColorChooserItem
          v-for="(item, index) in color.items"
          :key="'ci_' + index"
          :class="{
            selected:
              item.color == selectedColor.color &&
              item.type == selectedColor.type,
            redBlack: item.type == 'redBlack',
            transparent: item.type == 'transparent',
            lightColor: item.lightColor,
          }"
          :style="{ backgroundColor: item.color }"
          @click="selectColor(item)"
        ></ColorChooserItem>
      </div>
    </div>
  </CenteredWindowBase>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import CenteredWindowBase from "@/components/CenteredWindowBase.vue";
import CanvasStore from "../store/canvas";
import ColorChooserItem from "@/components/ColorChooserItem.vue";

export default {
  components: {
    CenteredWindowBase,
    ColorChooserItem,
  },
  props: {
    colorSet: String,
  },
  emits: ["colorSelected"],
  setup(props, { emit }) {
    const canvasStore = CanvasStore;
    const colorChooserWindowVisible = ref("true");

    const selectedColor = ref(
      "{ type: 'normal', color: '#000000', lightColor: false }"
    );

    const colors = ref();

    const textColors = [
      {
        type: "Betűszínek",
        items: [
          { type: "normal", color: "#000000", lightColor: false },
          { type: "redBlack", color: "#000000", lightColor: false },
          { type: "normal", color: "#ffffff", lightColor: true },
          { type: "normal", color: "#bebebe", lightColor: false },
          { type: "normal", color: "#ff0000", lightColor: false },
          { type: "normal", color: "#ffa500", lightColor: false },
          { type: "normal", color: "#ffff00", lightColor: true },
          { type: "normal", color: "#00ff00", lightColor: false },
          { type: "normal", color: "#027300", lightColor: false },
          { type: "normal", color: "#00ffff", lightColor: false },
          { type: "normal", color: "#0000ff", lightColor: false },
          { type: "normal", color: "#a020f0", lightColor: false },
          { type: "normal", color: "#cf8ff7", lightColor: false },
          { type: "normal", color: "#5c320c", lightColor: false },
        ],
      },
        {
        type: "Világos betűszínek",
        items: [
 
           { type: "normal", color: "#fcd6fb", lightColor: true },
          { type: "normal", color: "#ffffcc", lightColor: true },
          { type: "normal", color: "#e5ffe5", lightColor: true },
          { type: "normal", color: "#ffe5e5", lightColor: true },
          { type: "normal", color: "#e5e5ff", lightColor: true },
          { type: "normal", color: "#ffedcc", lightColor: true },
          { type: "normal", color: "#e5ffff", lightColor: true },
           { type: "normal", color: "#f2f2f2", lightColor: true },
        ],
      },
    ];

    const paintBucketColors = [
      {
        type: "Általános színek",
        items: [
          { type: "transparent", color: "#ffffff00", lightColor: false },

          { type: "normal", color: "#000001", lightColor: false },
          { type: "normal", color: "#ffffff", lightColor: true },
          { type: "normal", color: "#bebebe", lightColor: false },
          { type: "normal", color: "#ff0000", lightColor: false },
          { type: "normal", color: "#ffa500", lightColor: false },

          { type: "normal", color: "#ffff00", lightColor: true },
          { type: "normal", color: "#00ff00", lightColor: false },
          { type: "normal", color: "#027300", lightColor: false },
          { type: "normal", color: "#00ffff", lightColor: false },
          { type: "normal", color: "#0000ff", lightColor: false },
          { type: "normal", color: "#a020f0", lightColor: false },
          { type: "normal", color: "#cf8ff7", lightColor: false },
          { type: "normal", color: "#5c320c", lightColor: false },
        ],
      },
           
      {
        type: "Hajszínek",
        items: [
          { type: "normal", color: "#ffff00", lightColor: true },
          { type: "normal", color: "#010101", lightColor: true },
          { type: "normal", color: "#bebebe", lightColor: true },
          { type: "normal", color: "#5c320c", lightColor: true },
          { type: "normal", color: "#ee0000", lightColor: true },
        ],
      },
      {
        type: "Bőrszínek",
        items: [
          { type: "normal", color: "#f0dec0", lightColor: true },
          { type: "normal", color: "#ddae77", lightColor: true },
          { type: "normal", color: "#8b7751", lightColor: true },
          { type: "normal", color: "#725317", lightColor: true },
        ],
      },

       {
        type: "Világos színek",
        items: [
 
           { type: "normal", color: "#fcd6fb", lightColor: true },
          { type: "normal", color: "#ffffcc", lightColor: true },
          { type: "normal", color: "#e5ffe5", lightColor: true },
          { type: "normal", color: "#ffe5e5", lightColor: true },
          { type: "normal", color: "#e5e5ff", lightColor: true },
          { type: "normal", color: "#ffedcc", lightColor: true },
          { type: "normal", color: "#e5ffff", lightColor: true },
           { type: "normal", color: "#f2f2f2", lightColor: true },
        ],
      },
    ];

    const borderColors = [
     {
        type: "Világos keretszínek",
        items: [
            { type: "normal", color: "#fcd6fb", lightColor: true },
          { type: "normal", color: "#ffffcc", lightColor: true },
          { type: "normal", color: "#e5ffe5", lightColor: true },
          { type: "normal", color: "#ffe5e5", lightColor: true },
          { type: "normal", color: "#e5e5ff", lightColor: true },
          { type: "normal", color: "#ffedcc", lightColor: true },
          { type: "normal", color: "#e5ffff", lightColor: true },
          { type: "normal", color: "#ffffff", lightColor: true },
          { type: "normal", color: "#f2f2f2", lightColor: true },
        ],
      },
            {
        type: "Sötét keretszínek",
        items: [
          { type: "normal", color: "#000001", lightColor: false },
          { type: "normal", color: "#bebebe", lightColor: false },
          { type: "normal", color: "#ff0000", lightColor: false },
          { type: "normal", color: "#ffa500", lightColor: false },

          { type: "normal", color: "#ffff00", lightColor: true },
          { type: "normal", color: "#00ff00", lightColor: false },
          { type: "normal", color: "#027300", lightColor: false },
          { type: "normal", color: "#00ffff", lightColor: false },
          { type: "normal", color: "#0000ff", lightColor: false },
          { type: "normal", color: "#a020f0", lightColor: false },
          { type: "normal", color: "#cf8ff7", lightColor: false },
          { type: "normal", color: "#5c320c", lightColor: false },
        ],
      },
    ];

    const backgroundColors = [
      {
        type: "Világos háttérszínek",
        items: [
          { type: "transparent", color: "#ffffff00", lightColor: false },
           { type: "normal", color: "#fcd6fb", lightColor: true },
          { type: "normal", color: "#ffffcc", lightColor: true },
          { type: "normal", color: "#e5ffe5", lightColor: true },
          { type: "normal", color: "#ffe5e5", lightColor: true },
          { type: "normal", color: "#e5e5ff", lightColor: true },
          { type: "normal", color: "#ffedcc", lightColor: true },
          { type: "normal", color: "#e5ffff", lightColor: true },
          { type: "normal", color: "#ffffff", lightColor: true },
          { type: "normal", color: "#f2f2f2", lightColor: true },
        ],
      },
            {
        type: "Sötét háttérszínek",
        items: [
          { type: "normal", color: "#000001", lightColor: false },
          { type: "normal", color: "#bebebe", lightColor: false },
          { type: "normal", color: "#ff0000", lightColor: false },
          { type: "normal", color: "#ffa500", lightColor: false },

          { type: "normal", color: "#ffff00", lightColor: true },
          { type: "normal", color: "#00ff00", lightColor: false },
          { type: "normal", color: "#027300", lightColor: false },
          { type: "normal", color: "#00ffff", lightColor: false },
          { type: "normal", color: "#0000ff", lightColor: false },
          { type: "normal", color: "#a020f0", lightColor: false },
          { type: "normal", color: "#cf8ff7", lightColor: false },
          { type: "normal", color: "#5c320c", lightColor: false },
        ],
      },
    ];

    // +-1-gyel elter, hogy meg tudjuk kulonboztetni a paintbucket coloroktol
    const outlineColors = [
     
      {
        type: "Világos kontúrszínek",
        items: [
          { type: "transparent", color: "#ffffff03", lightColor: false },
          { type: "normal", color: "#fcd6fd", lightColor: true },
          { type: "normal", color: "#ffffcd", lightColor: true },
          { type: "normal", color: "#e5ffe6", lightColor: true },
          { type: "normal", color: "#ffe5e6", lightColor: true },
          { type: "normal", color: "#e5e5fe", lightColor: true },
          { type: "normal", color: "#ffedcd", lightColor: true },
          { type: "normal", color: "#e5fffe", lightColor: true },
          { type: "normal", color: "#fffffe", lightColor: true },
          { type: "normal", color: "#f2f2f3", lightColor: true },
        ],
      },
       {
        type: "Sötét kontúrszínek",
        items: [
          { type: "normal", color: "#000000", lightColor: false },
          { type: "normal", color: "#bebebf", lightColor: false },
          { type: "normal", color: "#ff0001", lightColor: false },
          { type: "normal", color: "#ffa501", lightColor: false },

          { type: "normal", color: "#ffff01", lightColor: true },
          { type: "normal", color: "#00ff01", lightColor: false },
          { type: "normal", color: "#027301", lightColor: false },
          { type: "normal", color: "#00fffe", lightColor: false },
          { type: "normal", color: "#0000fe", lightColor: false },
          { type: "normal", color: "#a020f1", lightColor: false },
          { type: "normal", color: "#cf8ff8", lightColor: false },
          { type: "normal", color: "#5c320d", lightColor: false },
        ],
      },
    ];

    function setColorSet() {
      if (props.colorSet == "upperText") {
        colors.value = textColors;
        selectedColor.value =
          canvasStore.state.configText.upperText._rawFillColor;
      } else if (props.colorSet == "lowerText") {
        colors.value = textColors;
        selectedColor.value =
          canvasStore.state.configText.lowerText._rawFillColor;
      } else if (props.colorSet == "paintBucketColors") {
        colors.value = paintBucketColors;
        selectedColor.value = canvasStore.state.configPaintBucket._color;
      } else if (props.colorSet == "borderColors") {
        colors.value = borderColors;
        selectedColor.value =
          canvasStore.state.configBackgroundRect._rawStrokeColor;
      } else if (props.colorSet == "backgroundColors") {
        colors.value = backgroundColors;
        selectedColor.value =
          canvasStore.state.configBackgroundRect._rawFillColor;
      } else if (props.colorSet == "outlineColors") {
        colors.value = outlineColors;
        selectedColor.value = canvasStore.state.configGeneral._outlineColor;
      }
    }

    function selectColor(item) {
      console.log("color selected in windows: " + item);
      console.log(item);
      selectedColor.value = item;
      emit("colorSelected", item);

      if (props.colorSet == "upperText") {
        canvasStore.state.configText.upperText._rawFillColor = item;
      } else if (props.colorSet == "lowerText") {
        canvasStore.state.configText.lowerText._rawFillColor = item;
      } else if (props.colorSet == "paintBucketColors") {
        canvasStore.state.configPaintBucket._color = item;
      } else if (props.colorSet == "borderColors") {
        canvasStore.state.configBackgroundRect._rawStrokeColor = item;
      } else if (props.colorSet == "backgroundColors") {
        canvasStore.state.configBackgroundRect._rawFillColor = item;
      } else if (props.colorSet == "outlineColors") {
        canvasStore.state.configGeneral._outlineColor = item;
      }
    }

    onMounted(() => {
      setColorSet();
    });

    watch(
      () => props.colorSet,
      () => {
        setColorSet();
      }
    );

    return {
      colorChooserWindowVisible,
      colors,
      emit,
      selectColor,
      selectedColor,
    };
  },
};
</script>

<style scoped>
.selected {
  outline-color: black;
  outline-width: 0.4vh;
  outline-style: solid;
  outline-offset: 0.4vh;
}

.colorTypeLabel {
  display: grid;
  justify-content: start;
  /*background-color: yellow;*/
  padding-top: 2vh;
  padding-bottom: 0;

  font-size: var(--font_size_editor_box_label);
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #cccccc;
  margin-left: 2vh;
  margin-right: 2vh;
}

.colorItemContainer {
  display: grid;
  grid-template-columns: min-content min-content min-content min-content min-content min-content min-content min-content;
  gap: 2vh;
  padding: 2vh;
  margin-left: 1vh;
}

.colorItem {
  width: 4vh;
  height: 4vh;
}
</style>
