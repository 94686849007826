<template>
  <EditorBox label="Felső felirat" iconClass="far fa-comment">
    <Column>
      <Row>
        <Label helpId="upperTextBox_text"> üzenet:</Label>
        <AutoCompleteInput
          :inputValue="textInputValue"
          @update:inputValue="updateModelValue('textInputValue', $event)"
          :options="autoCompleteOptions"
        >
        </AutoCompleteInput>
      </Row>

      <!-- <Row>
        <Label helpId="upperTextBox_font"> betűtípus:</Label>

        <select
          id="fontFamilySelect"
          :value="fontFamily"
          @input="updateModelValue('fontFamily', $event.target.value)"
        >
          <option value="Arial">Arial</option>
          <option value="Open Sans">Open Sans</option>
          <option value="Roboto">Roboto</option>
          <option value="Ubuntu">Ubuntu</option>
          <option value="Inter">Inter</option>

      
        </select>
      </Row> -->

      <SwitchRow>
        <Label helpId="upperTextBox_upperCase">csupa nagybetűs:</Label>
        <ToggleSwitch
          id="upperCaseToggle"
          :modelValue="upperCase"
          @update:modelValue="updateModelValue('upperCase', $event)"
        >
        </ToggleSwitch>
      </SwitchRow>

      <Row class="rightAlignControl">
        <Label helpId="upperTextBox_color"> betűszín:</Label>
        <ColorChooserBar
          :colorSet="location"
          :selectedColor="textColor"
          @update:selectedColor="updateModelValue('textColor', $event)"
        >
        </ColorChooserBar>
      </Row>

      <Row class="noBottomSeparator">
        <Label helpId="upperTextBox_fontSize"> betűméret:</Label>

        <EditableRangeSlider
          id="fontSizeSlider"
          :sliderValue="fontSizeInPt"
          @update:sliderValue="updateModelValue('fontSizeInPt', $event)"
          min="10"
          max="100"
        >
        </EditableRangeSlider>
      </Row>
    </Column>

  
  </EditorBox>
</template>

<script>
import { ref, onMounted } from "vue";
import EditorBox from "@/components/EditorBox/EditorBox.vue";
import Label from "@/components/EditorBox/Label.vue";
import Row from "@/components/EditorBox/Row.vue";
import Column from "@/components/EditorBox/Column.vue";
import SwitchRow from "@/components/EditorBox/SwitchRow.vue";
import CanvasStore from "../../store/canvas";

import AutoCompleteInput from "@/components/AutoCompleteInput.vue";

import ToggleSwitch from "@/components/ToggleSwitch.vue";

import EditableRangeSlider from "@/components/EditableRangeSlider.vue";

import ColorChooserBar from "@/components/ColorChooserBar.vue";



export default {
  components: {
    EditorBox,
    AutoCompleteInput,
    ToggleSwitch,
    EditableRangeSlider,
    ColorChooserBar,
    Column,
    Row,
    SwitchRow,
    Label,
   },
  props: {
    location: {
      type: String,
      required: true,
      note: "Can be upperText or lowerText",
    },
    textInputValue: {},
    fontFamily: {},
    textColor: {},
    upperCase: {},
    fontSizeInPt: {},
  },
  setup(props, { emit }) {
    const canvasStore = CanvasStore;

    const inputText = ref("");
    const font = ref("");

    const checkbox = ref("true");

    const colorChooserWindowVisible = ref("true");

    const autoCompleteOptions = ref([
      ""
    ]);




    function clicked() {
      canvasStore.methods.setText("upperText", "hello");
    }

    function updateModelValue(model, value) {
      emit("update:" + model, value);
    }


onMounted(() => {
      let options = []
      options.push(canvasStore.state.imageMetaData.synonyms[0])
      autoCompleteOptions.value = options
    });


    return {
      clicked,
      inputText,
      font,
      autoCompleteOptions,
      checkbox,
      canvasStore,
      updateModelValue,
      colorChooserWindowVisible,
    };
  },
};
</script>

<style scoped>
/*#upperCaseToggle {
  font-size: 2.2vh;
}*/

#fontFamilySelect {
  font-size: var(--font_size_editor_box_input);
  padding: 0.1vh;
}
</style>
