<template>
  <div class="switchRow">
      <slot> </slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.switchRow
{
    display: grid;
    grid-template-columns: 1fr min-content;
    
    justify-content: start;
    /* background-color: blueviolet; */

     border-bottom: rgba(128, 128, 128, 0.171);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 1vh;
}

.switchRow.noBottomSeparator
{

    border-bottom-width: 0px;
    padding-bottom: 0;
}
</style>