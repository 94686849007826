<template>
  <div class="windowContainer" v-if="isVisible">
      <div id="base" @click="emit('hideRequested')">
      <div id="shader"></div>
    </div>  
    <div id="window" key="1">
      <div id="header">
        <div id="title">{{ title }}</div>
        <div id="closeButton" @click="emit('hideRequested')">
          
           <i id="backButtonIcon" class="fas fa-times"></i>

        </div>
      </div>
      <div id="content">
        <div>
          <slot> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*  import { ref } from "vue";*/

export default {
  props: {
    isVisible: Boolean,
    title: String,
  },
  emits: ["hideRequested"],
  setup(props, { emit }) {
 

    return { props, emit,   };
  },
};
</script>

<style scoped>
#base {
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;

  display: grid;
}

#shader {
  background-color: rgba(0, 0, 0, 0.6);

  /* margin-left: 35vw; */
}

.windowContainer {
  position: fixed;
   top: 0;
  left: 0;  
  width: 100vw;
  height: 100vh;
  /* background-color: blue; */


  display: grid;
  align-content: center;
  justify-content: center;
}

#window {
  display: grid;

  grid-template-rows: min-content 1fr;

  justify-content: stretch;

  height: 55vh;
  width: 55vh;

  background-color: #ffffff;
  border-radius: 2vh;
  overflow: hidden;
  z-index: 999;



    box-shadow: 0 0 0.8vh 0 rgba(3, 85, 216, 0.15);
   /* margin-left: 35vw;  */
 
}

#responsive.tablet #window 
{
 width: 65vw;
 height: 60vh;
}

#responsive.mobile #window 
{
 width: 95vw;
 height: 70vh;
}

#header {
  background-color: var(--secondary_color);
    color: var(--light_text_color);
  height: var(--centered_window_header_height);

  display: grid;

  grid-template-columns: 1fr min-content;
}

#title {
  display: grid;
  align-content: center;
  justify-content: center;
  padding-left: var(--centered_window_header_height);
  user-select: none;
  font-size: var(--font_size_normal);
  
    user-select: none;
    text-transform: uppercase;

}

#closeButton {
 

  font-size: 2.5vh;
  width: var(--centered_window_header_height);

  display: grid;
  align-content: center;
}

#closeButton:hover{
    background-color: var(--hover_color);
}

#content {
  display: grid;

  background-color: white;
  overflow: auto;
}
</style>