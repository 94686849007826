<template>
  <div id="uploadPage">
    <div id="previewCanvas">
      <ImageEditorCanvas
        ref="previewCanvas"
        :controlsVisible="false"
        v-show="canvasStore.state.configMainImage[0].image != null"
      />

      <SwitchRow class="noBottomSeparator outlineSwitchRow">
        <Label> kontúros:</Label>

        <ToggleSwitch
          class="toggleSwitch"
          v-model="canvasStore.state.configGeneral._outline"
        >
        </ToggleSwitch>
      </SwitchRow>
    </div>

    <div v-show="!editMode" class="sectionHeader">Fájl:</div>
    <div v-show="!editMode" class="section">
      <input
        ref="fileInput"
        type="file"
        accept=".png"
        @change="onChoosenFileChanged"
      />
    </div>

    <div v-show="!editMode" class="sectionHeader">Rajzoló:</div>
    <div v-show="!editMode" class="section">
      <WaterMarks
        ref="waterMarkSelector"
        @change="setWaterMark($event.target.value)"
      />
    </div>

    <div class="sectionHeader">Szinonímák:</div>
    <div class="section">
      <SynonymsBox v-model:synonyms="synonyms" />
    </div>

    <div class="sectionHeader">Kategóriák:</div>
    <div class="section">
      <SearchFilters
        :restoreButtonsVisible="false"
        v-model:selectedTags="selectedTags"
      />
    </div>
    <br />
    <button @click="handleUploadClick">
      Feltöltés
    </button>
    <br />
    <br />
    <br />
    <button v-if="editMode" @click="deleteConfirmationWindowVisible = true">
      Törlés
    </button>
  </div>

  <div id="loadingDiv" v-show="loadingState" @click="loadingDivClicked">
    <div id="loadingIndicator" v-if="!loadingSucceeded">
      <div>
        <Spinner id="imageLoadingSpinner" v-show="loadingState" />
        <br />
        Feltöltés folyamatban...
      </div>
    </div>

    <div id="successIndicator" v-if="loadingSucceeded">
      <div>
        <i id="successIndicatorIcon" class="fas fa-check"></i>
        <br />
        Sikeres feltöltés.
      </div>
    </div>
  </div>

  <FloatingWindowBase
    id="deleteConfirmationWindow"
    v-if="deleteConfirmationWindowVisible"
  >
    <template v-slot:title>
      Biztos törli?
    </template>

    <template v-slot:buttons>
      <FloatingWindowButton @click="deleteConfirmationWindowVisible = false"
        >Mégse</FloatingWindowButton
      >
      <FloatingWindowButton @click="apiPostDelete">Törlés</FloatingWindowButton>
    </template>
  </FloatingWindowBase>
</template>

<script>
// import { useRouter } from "vue-router";

import { ref, onMounted } from "vue";
import axios from "axios";
import ImageEditorCanvas from "@/components/ImageEditorCanvas.vue";
import Spinner from "@/components/Spinner.vue";
// import { useRouter } from "vue-router";
// import axios from "axios";

// import VueAxios from 'vue-axios'
// Vue.use(VueAxios.axios)
// import PersistentStore from "../store/persistent";

import SearchFilters from "@/components/SearchFilters.vue";
import SynonymsBox from "@/components/SynonymsBox.vue";
import WaterMarks from "@/components/WaterMarks.vue";
import CanvasStore from "../store/canvas";

import PersistentStore from "../store/persistent";
import Store from "../store/store";

import FloatingWindowBase from "@/components/FloatingWindowBase.vue";
import FloatingWindowButton from "@/components/FloatingWindowButton.vue";

import ToggleSwitch from "@/components/ToggleSwitch.vue";
import SwitchRow from "@/components/EditorBox/SwitchRow.vue";
 

export default {
  components: {
    SearchFilters,
    SynonymsBox,
    WaterMarks,
    ImageEditorCanvas,
    Spinner,
    FloatingWindowBase,
    FloatingWindowButton,
    ToggleSwitch,
    SwitchRow,
  },

  setup() {
    const persistentStore = PersistentStore;
    const store = Store;

    // const router = useRouter();

    const editMode = ref(false);
    const editModeActualPhotoId = ref("");

    const loadingState = ref(false);
    const loadingSucceeded = ref(false);

    const selectedTags = ref([]);
    const synonyms = ref([]);
    const waterMarkId = ref("");
    const canvasStore = CanvasStore;

    const previewCanvas = ref(null);
    const waterMarkSelector = ref(null);

    const fileInput = ref(null);

    const deleteConfirmationWindowVisible = ref(false);

    // const imageFileChooser = ref(null)

    var rawImage = null;
    var rawImage_loadedToMemory = null;
    // var rawImage_loadedToMemory_width = 0;
    // var rawImage_loadedToMemory_height = 0;

    function setWaterMark(newWaterMarkId) {
      // console.log("wm id: " + waterMarkId)

      waterMarkId.value = newWaterMarkId;
      var path = waterMarkSelector.value.getPath(newWaterMarkId);
      // console.log("wm path: " + path)

      canvasStore.methods.setWaterMarkImageSource("/watermarks/" + path);
    }

    function handleUploadClick() {



      if(!editMode.value)
      {
        if(rawImage == null)
        {
         alert("Nincs kép kitallózva.")
          return;
        }

        // if(rawImage_loadedToMemory == null || !rawImage_loadedToMemory.complete 
        // || rawImage_loadedToMemory.width != canvasStore.defaultImageResolution
        // || rawImage_loadedToMemory.height != canvasStore.defaultImageResolution)
        // {
        //            alert("A kép nem " + canvasStore.defaultImageResolution + "*" + canvasStore.defaultImageResolution +  " pixel felbontású." )
           
        //   return;
        // }

        //     if(waterMarkId.value == "")
        // {
        //  alert("Nincs rajzoló kiválasztva!")
        //   return;
        // }

              if(synonyms.value.join("").trim() == "")
      {
        alert("Legalább egy szinonímát kötelező megadni.")
        return;
      }

        
      }



      if (editMode.value) {
        apiPostEdit();
      } else {
        start_apiPostAdd();
      }
    }

    function apiPostDelete() {
      axios
        .post(
          "verbalio_server/index.php?upload=delete",
          {
            // headers: { Authorization: `Bearer ${persistentStore.state.token}` },
            photoId: editModeActualPhotoId.value,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }

          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          store.state.searchPageNeedsUpdate = true;

          history.back();
          // router.push("search");
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function start_apiPostAdd() {
      console.log("Upload started...");

      loadingSucceeded.value = false;
      loadingState.value = true;

      if (canvasStore.state.configGeneral._outline == false) {
        apiPostAdd();
      } else {
        canvasStore.state.configGeneral._outline = false;

        setTimeout(function() {
          apiPostAdd();
        }, 3000);
      }
    }

    function apiPostAdd() {
      // console.log(imagesArray);

      //console.log(previewCanvas.value.getFile())

      var finalImage = previewCanvas.value.getFile();

      // canvasStore.state.configWaterMarkImage.visible = false
      // previewCanvas.value.redrawCanvas()

      // setTimeout(1000)

      var waterMarkSource = canvasStore.state.configWaterMarkImage.image.src;
      canvasStore.state.configWaterMarkImage.image.src = "";
      var thumbnailImage = previewCanvas.value.getThumbnailFile();
      canvasStore.state.configWaterMarkImage.image.src = waterMarkSource;

      // canvasStore.state.configWaterMarkImage.visible = true

      // console.log(imageFileChooser.value)

      const formData = new FormData();
      formData.append("rawImage", rawImage, rawImage.name);
      formData.append("finalImage", finalImage, finalImage.name);
      formData.append("thumbnailImage", thumbnailImage, thumbnailImage.name);

      formData.append("synonyms", synonyms.value.join("␟"));
      formData.append("waterMarkId", waterMarkId.value);
      formData.append("tagIds", selectedTags.value.join("␟"));

      axios
        .post(
          "verbalio_server/index.php?upload=add",
          // {
          //   headers: {  'content-type': 'multipart/form-data' },

          // }
          formData,
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          loadingSucceeded.value = true;

          store.state.searchPageNeedsUpdate = true;

          setTimeout(function() {
            initEmpty();
            loadingState.value = false;
          }, 1500);

          console.log(resp);
          console.log(resp.data);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          // persistentStore.state.userId = resp.data.userId;
          // persistentStore.state.userFullName = resp.data.fullName;
          // persistentStore.state.userRole = resp.data.role;
          // persistentStore.state.token = resp.data.token;
          // persistentStore.state.loggedIn = true;

          // router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function apiPostEdit() {
      console.log("Edit Upload started...");

      loadingSucceeded.value = false;
      loadingState.value = true;

      const formData = new FormData();
      // formData.append("rawImage", rawImage, rawImage.name);
      // formData.append("finalImage", finalImage, finalImage.name);
      // formData.append("thumbnailImage", thumbnailImage, thumbnailImage.name);

      formData.append("photoId", editModeActualPhotoId.value);

      formData.append("synonyms", synonyms.value.join("␟"));
      // formData.append("waterMarkId", waterMarkId.value);
      formData.append("tagIds", selectedTags.value.join("␟"));

      axios
        .post(
          "verbalio_server/index.php?upload=edit",
          // {
          //   headers: {  'content-type': 'multipart/form-data' },

          // }
          formData,
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          loadingSucceeded.value = true;

          store.state.searchPageNeedsUpdate = true;

          setTimeout(function() {
            // initEmpty();
            loadingState.value = false;
            // router.push("search");
            history.back();
          }, 1000);

          console.log(resp);
          console.log(resp.data);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          // persistentStore.state.userId = resp.data.userId;
          // persistentStore.state.userFullName = resp.data.fullName;
          // persistentStore.state.userRole = resp.data.role;
          // persistentStore.state.token = resp.data.token;
          // persistentStore.state.loggedIn = true;

          // router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    onMounted(() => {
      window.scrollTo(0, 0); // az elejere scrollozunk

      console.log("onMounted Upload.vue");
      persistentStore.methods.init();
      canvasStore.methods.restoreDefaultState();

      if (store.state.uploadedImageToEdit != undefined) {
        editMode.value = true;
        editModeActualPhotoId.value = store.state.uploadedImageToEdit.photoId;

        // synonyms.value = store.state.uploadedImageToEdit.synonyms;
        synonyms.value = store.state.uploadedImageToEdit.synonyms;

        selectedTags.value = store.state.uploadedImageToEdit.tagIds;

        setTimeout(function() {
          // canvasStore.methods.setMainImageSource(
          //   "verbalio_server/uploadedImages/" +
          //     store.state.uploadedImageToEdit.photoId +
          //     "_final.png"
          // );

          setImageSource(

                "verbalio_server/uploadedImages/" +
              store.state.uploadedImageToEdit.photoId +
              "_final.png"
          )

          // waterMarkSelector.value.setSelectedWatermark(store.state.uploadedImageToEdit.waterMarkId)
          // setWaterMark(store.state.uploadedImageToEdit.waterMarkId)

          console.log(store.state.uploadedImageToEdit);
          store.state.uploadedImageToEdit = undefined;
        }, 100);
      }
    });

    function setImageSource(src)
    {
      canvasStore.state.configGeneral._outline = false;
      setTimeout(function() {
        canvasStore.methods.setMainImageSource(src);

        setTimeout(function() {
          canvasStore.state.configGeneral._outline = true;
        }, 2500);
      }, 200);
    }


    function onChoosenFileChanged(event) {
      console.log(event.target.files[0]);
      rawImage = event.target.files[0];
      

      var URL = window.webkitURL || window.URL;
      var url = URL.createObjectURL(event.target.files[0]);

rawImage_loadedToMemory = new Image();
      rawImage_loadedToMemory.src = url

      setImageSource(url)
    }

    function loadingDivClicked() {
      console.log("loading div clicked");
    }

    function initEmpty() {
      canvasStore.methods.restoreDefaultState();
      fileInput.value.value = "";
      waterMarkSelector.value.setSelectedWatermark(undefined);
      synonyms.value = new Array();
      selectedTags.value = new Array();
    }

    return {
      selectedTags,
      synonyms,
      handleUploadClick,
      apiPostAdd,
      apiPostEdit,
      apiPostDelete,
      // imageFileChooser,
      onChoosenFileChanged,
      rawImage,
      previewCanvas,
      waterMarkSelector,
      setWaterMark,
      canvasStore,
      loadingDivClicked,
      loadingState,
      loadingSucceeded,
      fileInput,
      editMode,
      deleteConfirmationWindowVisible,
    };
  },
};
</script>

<style scoped>
#uploadPage {
  text-align: left;
  padding-top: 2vh;
  padding-left: 5vh;
  padding-right: 5vh;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
}

.sectionHeader {
  font-size: var(--font_size_normal);
  font-weight: bold;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.section {
  margin-left: 2vh;
}

#previewCanvas {
  position: fixed;

  right: 2vh;

  top: var(--height_header);

  margin-top: 2vh;
}

#loadingDiv {
  position: fixed;
  height: var(--height_content);
  width: 100vw;

  top: var(--height_header);
  left: 0;

  display: grid;
  align-content: center;
  justify-content: center;

  font-size: 6vh;
  color: #ffffff;

  background-color: #00000099;
}

#successIndicatorIcon {
  color: #00ff00;
  font-size: 7vh;
}

.outlineSwitchRow {
  margin-top: 3vh;
}
</style>
