<template>
  <div class="editableRangeSliderContainer">
    <input
      class="editInput"
      type="text"
      autocomplete="off"
      v-model="editedValue"
    />

    <RangeSlider
      class="sliderInput"
      :min="min"
      :max="max"
      :modelValue="sliderValue"
      @update:modelValue="updateModelValue('sliderValue', $event)"
    >
    </RangeSlider>
  </div>
</template> 

<script>
import { onMounted, ref, watch } from "vue";
import RangeSlider from "@/components/RangeSlider.vue";

export default {
  components: {
    RangeSlider,
  },

  props: {
    sliderValue: Number,
    min: Number,
    max: Number,
  },

  setup(props, { emit }) {
    const editedValue = ref(0);
   

    onMounted(() => {
      editedValue.value = props.sliderValue;
    });

    watch(
      () => props.sliderValue,
      (newValue) => {
        editedValue.value = newValue;
      }
    );

        watch(
      () => editedValue.value,
      (newValue) => {
      
      var originalInput = String(newValue)

      var filteredInput = "";

 

      for (var i = 0; i < originalInput.length; i++) {
        if (
          (i == 0 && originalInput[i] == '-' ) ||
          originalInput[i] == 0 ||
          originalInput[i] == 1 ||
          originalInput[i] == 2 ||
          originalInput[i] == 3 ||
          originalInput[i] == 4 ||
          originalInput[i] == 5 ||
          originalInput[i] == 6 ||
          originalInput[i] == 7 ||
          originalInput[i] == 8 ||
          originalInput[i] == 9
        ) {
          filteredInput += originalInput[i];
        }
      }

      if (Number.parseInt(filteredInput) < props.min) {
        filteredInput = props.min;
      } else if (Number.parseInt(filteredInput) > props.max) {
        filteredInput = props.max;
      } 
      
      updateModelValue('sliderValue', filteredInput);
      editedValue.value = filteredInput;
 
      }
    );

    // watch(editedValue, (newValue) => {
    //   var filteredInput = "";

    //   console.log('edited')

    //   for (var i = 0; i < newValue.length; i++) {
    //     if (
    //       newValue[i] == 0 ||
    //       newValue[i] == 1 ||
    //       newValue[i] == 2 ||
    //       newValue[i] == 3 ||
    //       newValue[i] == 4 ||
    //       newValue[i] == 5 ||
    //       newValue[i] == 6 ||
    //       newValue[i] == 7 ||
    //       newValue[i] == 8 ||
    //       newValue[i] == 9
    //     ) {
    //       filteredInput += newValue[i];
    //     }
    //   }

    //   if (Number.parseInt(filteredInput) < 0) {
    //     filteredInput = props.min;
    //   } else if (Number.parseInt(filteredInput) > props.max) {
    //     filteredInput = props.max;
    //   }
      
    //   updateModelValue(filteredInput);
    //   editedValue.value = filteredInput;
    // });

    function updateModelValue(model, value) {
      emit("update:" + model, value);
    }

    return {
      updateModelValue,
      editedValue,
  
    };
  },
};
</script>

<style scoped>
.editableRangeSliderContainer {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0.5vh;
  
}

.editInput {
  width: 2em;
  text-align: center;
   font-size: var(--font_size_editor_box_input);
   padding: 0.1vh;
}

.sliderInput {
  font-size: 1.1vh;
  margin-top: 0.75vh;
}
</style>