<template>
  <div id="editorDashboard">
    <EditorBoxUpperText
      v-model:textInputValue="canvasStore.state.configText.upperText._rawText"
      v-model:fontFamily="canvasStore.state.configText.upperText.fontFamily"
      v-model:textColor="canvasStore.state.configText.upperText._rawFillColor"
      v-model:upperCase="canvasStore.state.configText.upperText._upperCase"
      v-model:fontSizeInPt="canvasStore.state.configText.upperText._rawFontSizeInPt"
      location="upperText"
      label="Üzenet"

    @restoreRequested="canvasStore.methods.restoreDefaultState_upperText"

    />
    <EditorBoxAppearance 
    v-model:outlineVersion="canvasStore.state.configGeneral._outline"
  
    v-model:borderSize="canvasStore.state.configBackgroundRect._rawStrokeWidthInPx"

    @restoreRequested="canvasStore.methods.restoreDefaultState_appearance"
     
    />
        <EditorBoxPaintBucket
   v-model:grayScaleVersion="canvasStore.state.configGeneral._grayScale"

       @restoreRequested="canvasStore.methods.restoreDefaultState_paintBucket"
    />
     
    <EditorBoxUpperText
      v-model:textInputValue="canvasStore.state.configText.lowerText._rawText"
      v-model:fontFamily="canvasStore.state.configText.lowerText.fontFamily"
            v-model:textColor="canvasStore.state.configText.lowerText._rawFillColor"
      v-model:upperCase="canvasStore.state.configText.lowerText._upperCase"
      v-model:fontSizeInPt="canvasStore.state.configText.lowerText._rawFontSizeInPt"
      location="lowerText"
      label="Alsó felirat"

      @restoreRequested="canvasStore.methods.restoreDefaultState_lowerText"
    />
     
    <EditorBoxSizing
    @restoreRequested="canvasStore.methods.restoreDefaultState_sizing"
     />
    <EditorBoxExtras 
    @restoreRequested="canvasStore.methods.restoreDefaultState_extras"
    />
  </div>
</template>

<script>
import EditorBoxUpperText from "@/components/EditorBox/EditorBoxUpperText.vue";
import EditorBoxAppearance from "@/components/EditorBox/EditorBoxAppearance.vue";
import EditorBoxPaintBucket from "@/components/EditorBox/EditorBoxPaintBucket.vue";
import EditorBoxSizing from "@/components/EditorBox/EditorBoxSizing.vue";
import EditorBoxExtras from "@/components/EditorBox/EditorBoxExtras.vue";

import CanvasStore from "../store/canvas";

export default {
  components: {
    EditorBoxUpperText,
    EditorBoxAppearance,
    EditorBoxPaintBucket,
    EditorBoxSizing,
    EditorBoxExtras
  },

  setup() {
    const canvasStore = CanvasStore;
   return {

      canvasStore,
    };
  },
};
</script>

<style scoped>
#editorDashboard {
  /* background-color: #F6F6F9; */

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content min-content;
  gap: 3vh;
  padding: 3vh;
}

#responsive.tablet #editorDashboard {
 
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
 
}

#responsive.mobile #editorDashboard {
 
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
 
}



</style>