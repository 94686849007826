<template>
  <div>
    <div class="extraImageContainer">
      <div
        class="extraImage"
        :class="{
          selected: canvasStore.state.extraImageNameList.includes(color.name),
        }"
        :style="{
          'background-image': 'url(' + color.controlImage_passive + ')',
        }"
        v-for="(color, index) in extraImages"
        :key="index"
        @click="toggleImage(color)"
      >
        <!-- <img
          :src="
            /*canvasStore.state.extraImageNameList.includes(color.name)? color.controlImage_active :*/ color.controlImage_passive
          "
        />  -->
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from "vue";
// import Store from "../store/store";
import CanvasStore from "../store/canvas";

export default {
  components: {},
  props: {
    extraImages: Array,
  },
  emits: [],
  setup(props, { emit }) {
    // const store = Store;
    const canvasStore = CanvasStore;

    function toggleImage(item) {
      console.log("toggleImage");
      console.log(item);

      canvasStore.methods.toggleExtraImage(
        item.name,
        item.image,
        item.type,
        item.location
      );

      // emit("update:selectedColor", item);
    }

    return {
      emit,
      toggleImage,
      canvasStore,
      // store,
      // selectedColor,
      // extraImages,
    };
  },
};
</script>

<style scoped>
.selected {
  outline-color: black;
  outline-width: 0.31vh;
  outline-style: solid;
  outline-offset: 0.31vh;
}

.extraImageContainer {
  display: grid;
  /* grid-template-columns: min-content min-content min-content min-content min-content ; */
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  row-gap: 2vh;
  column-gap: 1vh;

  /* background-color: blue; */
  justify-items: end;
}

.extraImage {
  width: 3.7vh;
  height: 3.7vh;

  /* margin-left: 0.6vh; */
  /* margin-right: 0.6vh; */

  background-size: 3.7vh;
}

/* .extraImage img {
  width: 3.7vh;
  height: 3.7vh;
   

} */

.extraImage:hover {
  opacity: 0.8;
}
</style>
