<template>
  <div>
    <div class="colorItemContainer">
      <ColorChooserItem
        v-for="(color, index) in colors"
        :key="index"
        :class="{
          selected:
            color.color == selectedColor.color &&
            color.type == selectedColor.type,
          redBlack: color.type == 'redBlack',
          transparent: color.type == 'transparent',
          lightColor: color.lightColor,
        }"
        :style="{ backgroundColor: color.color }"
        @click="selectColor(color)"
      ></ColorChooserItem>

      <div id="addButton" @click="addButtonClicked">
        <i id="addIcon" class="fas fa-fw fa-plus"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import Store from "../store/store";

import ColorChooserItem from "@/components/ColorChooserItem.vue";

export default {
  components: {
    ColorChooserItem,
  },
  props: {
    colorSet: String,
    selectedColor: Object,
  },
  emits: [],
  setup(props, { emit }) {
    const store = Store;

    const colorChooserWindowVisible = ref("true");

    //const selectedColor = ref("");

    const colors = ref();

    const textColors = [
      { type: "normal", color: "#000000", lightColor: false },
      { type: "redBlack", color: "#000000", lightColor: false },
      { type: "normal", color: "#0000ff", lightColor: false },
    ];

    const paintBucketColors = [
      { type: "transparent", color: "#ffffff01", lightColor: false },

      { type: "normal", color: "#000001", lightColor: false },
      { type: "normal", color: "#ffffff", lightColor: true },
      { type: "normal", color: "#bebebe", lightColor: false },
      { type: "normal", color: "#ff0000", lightColor: false },
      { type: "normal", color: "#ffa500", lightColor: false },
      { type: "normal", color: "#ffff00", lightColor: true },
      { type: "normal", color: "#00ff00", lightColor: false },
      { type: "normal", color: "#0000ff", lightColor: false },
      { type: "normal", color: "#5c320c", lightColor: false },
      { type: "normal", color: "#ff00e9", lightColor: false },
    ];
    const borderColors = [
      { type: "normal", color: "#000000", lightColor: false },
      { type: "normal", color: "#ff0000", lightColor: false },
      { type: "normal", color: "#0000ff", lightColor: false },
    ];

    const backgroundColors = [
      { type: "transparent", color: "#ffffff00", lightColor: false },

      { type: "normal", color: "#ffffcc", lightColor: true },
      { type: "normal", color: "#e5ffe5", lightColor: true },
    ];
    
// +-1-gyel elter, hogy meg tudjuk kulonboztetni a paintbucket coloroktol
    const outlineColors = [
    { type: "normal", color: "#000000", lightColor: false },
      { type: "normal", color: "#ff0001", lightColor: false },
      { type: "normal", color: "#0000fe", lightColor: false },
    ];

    function setColorSet() {
      console.log("setcolorset: " + props.colorSet);
      if (props.colorSet == "upperText" || props.colorSet == "lowerText") {
        colors.value = textColors;
      } else if (props.colorSet == "paintBucketColors") {
        colors.value = paintBucketColors;
      } else if (props.colorSet == "borderColors") {
        colors.value = borderColors;
      } else if (props.colorSet == "backgroundColors") {
        colors.value = backgroundColors;
      } else if (props.colorSet == "outlineColors") {
        colors.value = outlineColors;
      }
    }

    function selectColor(item) {
      emit("update:selectedColor", item);
    }

    function updateColors() {
      var contains = colors.value.some(
        (item) =>
          item.color === props.selectedColor.color &&
          item.type === props.selectedColor.type
      );
      if (!contains) {
        console.log("Doesnt contain");
        colors.value[colors.value.length - 1] = props.selectedColor;
      } else {
        console.log("contains");
      }
    }

    function addButtonClicked() {
      store.methods.showColorChooserWindow(props.colorSet);
    }

    onMounted(() => {
      setColorSet();
      updateColors();
    });

    watch(
      () => props.colorSet,
      () => {
        setColorSet();
        updateColors();
      }
    );

    watch(
      () => props.selectedColor,
      () => {
        console.log("Selected color: " + props.selectedColor);
        updateColors();
      }
    );

    return {
      colorChooserWindowVisible,
      colors,
      emit,
      selectColor,
      addButtonClicked,
      store,
      // selectedColor,
    };
  },
};
</script>

<style scoped>
.colorItemContainer {
  display: grid;
  grid-template-columns: min-content min-content min-content min-content;
  gap: 2vh;
}

#addButton {
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: 3vh;

  border-radius: 50%;
}

#addButton:hover {
  /* background-color: #dddddd;  */
  opacity: 0.8;
}
</style>
