<template>
  
  <input
    class="slider"
    type="range"
    :min="min"
    :max="max"
    :value="modelValue"
    @input="updateModelValue($event.target.value)"
  />
</template>

<script>
export default {
  props: {
    modelValue: Number,
    min: Number,
    max: Number,
  },

  setup(props, { emit }) {
    function updateModelValue(value) {
      emit("update:modelValue", value);
    }

    return {
      updateModelValue,
    };
  },
};
</script>

<style scoped>
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1em;
  border-radius: 0.5em;
  background: #ccc;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  font-size: 2vh;

  cursor: pointer;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: var(--primary_color);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: var(--primary_color);
  cursor: pointer;
}
</style>