<template>
  <!-- <div>Search Filters</div> -->
  <!-- <button @click="apiGetTags">API Call</button> -->
  <div id="breadCrumbContainer">
    <div
      class="breadCrumb"
      :class="{ selectedBreadCrumb: index + 1 == items.length }"
      v-for="(item, index) in items"
      :key="'bc_' + index"
    >
      <div class="breadCrumbItemContainer">
        
        <div class="breadCrumbLabel" @click="selectBreadCrumb(index)">
          <i class="fas fa-home" v-if="index==0"> </i>
          {{ item.label }}
        </div>
        <div class="breadCrumbSeparator" >
          <i class="fas fa-chevron-right"> </i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from "vue";
// import { onMounted } from "vue";
// import axios from "axios";

export default {
  props: {
    items: Array,
    // selectedItem: Object,
  },
  setup(props, { emit }) {
    // function updateselectedItem(item) {

    // }

    function selectBreadCrumb(index) {
      // var item =

      // console.log(index);
      // console.log(props.items);

      let modifiedItems = [...props.items];

      // console.log(
      modifiedItems.splice(index + 1, modifiedItems.length - index - 1);
      // );
      emit("update:items", modifiedItems);
    }

    return {
      selectBreadCrumb,
    };
  },
};
</script>

<style scoped>
#breadCrumbContainer {
  display: flex;
  flex-wrap: wrap;

  width: 80vw;
  
  row-gap: 1vh;

  /*background-color: red;*/

  user-select: none;
}

.breadCrumb {
  /* background-color: brown; */
  font-size: var(--font_size_normal);
  font-weight: bold;
}

.selectedBreadCrumb {
  /* background-color: yellow; */
}

.breadCrumbItemContainer {
  display: grid;
  grid-template-columns: max-content min-content;
}

.breadCrumbLabel {
  background-color: #dedee0;
  padding: 2vh;

  border-radius: 1vh;
}

.breadCrumbLabel:hover {
  background-color: var(--secondary_color);
  color: var(--light_text_color);
}

.breadCrumbSeparator {
  padding: 2vh;
}
</style>
