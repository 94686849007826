<template>

  <div class="label"> 
          <!-- <HelpButton :helpId="helpId"></HelpButton> -->

 
<slot> </slot>

  </div>
</template>

<script>
//  import HelpButton from "@/components/HelpButton.vue";

export default {

  props:
  {
    helpId: String,
  },
  components: {
    /*HelpButton,*/
  },
}
</script>

<style scoped>

.label {
  /* background-color: brown; */
  display: grid;
  grid-template-columns: /*min-content*/ max-content;
  align-content: center;
  justify-content: start;
  font-size: var(--font_size_editor_box_label); 
  user-select: none;
  gap: 0.6vh;
    /* color: #535151; */
}

 
</style>