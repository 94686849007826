<template>   
        <div class="floatingWindowButton">
            <slot> </slot>
        </div>
</template>

<script>
export default {};
</script>

<style scoped>
 

.floatingWindowButton {
  color: var(--primary_color);
  font-weight: bold;
  font-size: var(--font_size_normal);
  
  /* padding: 1vh; */
}

.floatingWindowButton:hover {
  color: red;
}
</style>
