<template>
  <FloatingWindowBase
 

  >
    <template v-slot:title>
      {{ windowTitle }}
    </template>

    <template v-slot:content>
      <div class="folderTreeContainer">
        <div class="folderTreeInnerGrowableContainer">
          <Spinner id="folderLoadingSpinner" v-show="loadingState" />
          <div
            class="folderTreeItemContainer"
            v-for="(folder, index) in openFoldersModel"
            :key="'ff_' + index"
            @click="selectFolder(folder.favoriteId)"
            v-bind:class="{
              selectedFolder: selectedFolderId == folder.favoriteId,
            }"
          >
            <div
              class="folderTreeItem"
              v-bind:style="{ 'margin-left': folder.level * 2.5 + 'vh' }"
            >
              <div
                class="folderToggleButton"
                v-if="folder.hasChildren && folder.open"
                @click="
                  $event.stopPropagation();
                  closeFolder(folder.favoriteId);
                "
              >
                <i class="fas faw fa-angle-down"></i>
              </div>
              <div
                class="folderToggleButton"
                v-if="folder.hasChildren && !folder.open"
                @click="
                  $event.stopPropagation();
                  openFolder(folder.favoriteId);
                "
              >
                <i class="fas faw fa-angle-right"></i>
              </div>

              <div v-if="!folder.hasChildren" class="folderNoButton"></div>
              <div class="folderLabel">
                {{ folder.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:buttons>
      <FloatingWindowButton @click="emit('cancelButtonClicked')">
        Mégse
      </FloatingWindowButton>
      <FloatingWindowButton
        @click="
          if ( selectedFolderId != '') {
            emit('acceptButtonClicked', selectedFolderId);
          }
        "
        :class="{ disabled: selectedFolderId == '' }"
      >
        {{ acceptButtonLabel }}
      </FloatingWindowButton>
    </template>
  </FloatingWindowBase>
</template>

<script>
import { ref, onMounted /*watch*/, computed, watch } from "vue";

import axios from "axios";

import PersistentStore from "../store/persistent";

import FloatingWindowBase from "@/components/FloatingWindowBase.vue";
import FloatingWindowButton from "@/components/FloatingWindowButton.vue";

import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    FloatingWindowBase,
    FloatingWindowButton,
    Spinner,
  },
  props: {
    windowTitle: {
      type: String,
      default: "Hova helyezzük a kijelölt elemet?",
    },
    acceptButtonLabel: {
      type: String,
      default: "Áthelyezés",
    },
    inputModel: String,
    userId: String,
  },
  emits: ["cancelButtonClicked", "acceptButtonClicked"],

  setup(props, { emit }) {
    const persistentStore = PersistentStore;
    /*const renameWindowVisible = ref(true);*/
    const floatingWindowInput = ref(null);
    const floatingWindowInputModel = ref("");

    const selectedFolderId = ref("");

    // const selectedFolderPath = ref([]);

    const loadingState = ref(true);

    const allFolderModel = ref([]);
    const filteredAllFolderModel = ref([]);

    const openFolderIds = ref(["root"]);

    const openFoldersModel = computed(() => {
      let openFolders = [];

      // filter the items of open folders
      filteredAllFolderModel.value.forEach((folder) => {
        if (openFolderIds.value.includes(folder.parentId)) {
          openFolders.push(folder);
        }
      });

      // set open and hasChildren property for every folder
      openFolders.forEach((folder) => {
        folder.open = openFolderIds.value.includes(folder.favoriteId);
        folder.hasChildren =
          filteredAllFolderModel.value.find((item) => {
            // console.log(item.parentId + " - " + folder.favoriteId)
            return item.parentId == folder.favoriteId;
          }) != undefined;
      });

      // console.log("openfolders");
      // console.log(openFolders);

      return openFolders;
    });

 

    watch(openFoldersModel, (newValue) => {
      // remove selection if the selected fodler is no londer visible (its parent was collapsed)
      if (
        newValue.find((item) => {
          return item.favoriteId == selectedFolderId.value;
        }) == undefined
      ) {
        selectedFolderId.value = "";
      }
    });

    

    const selectedFolderModel = computed(() => {
      // get actual path
      let reachedRoot = false;
      let actualId = selectedFolderId.value;
      let folderPath = ["root"];
      while (!reachedRoot) {
        console.log("*** computed folder path iteration");
        if (actualId == "root") {
          reachedRoot = true;
        } else {
          let actualFolder = filteredAllFolderModel.value.find((item) => {
            return item.favoriteId == actualId;
          });
          folderPath.push(actualId);
          actualId = actualFolder.parentId;
        }
      }

      // add items inside actual path, plus their neighbours on that level
      let selectedFodlers = [];
      filteredAllFolderModel.value.forEach((folder) => {
        if (folderPath.includes(folder.parentId)) {
          selectedFodlers.push(folder);
        }
      });

      console.log("selectedFolders computed: ");
      console.log(folderPath);

      return selectedFodlers;
    });

    function selectFolder(id) {
      // let reachedRoot = false;
      // let actualId = selectedFolderId.value;

      // let folderPath = [];

      // while (!reachedRoot) {
      //   if (actualId == "root") {
      //     reachedRoot = true;
      //   } else {
      //     let actualFolder = filteredAllFolderModel.value.find((item) => {
      //       return item.favoriteId == actualId;
      //     });
      //     folderPath.push(actualId);
      //     actualId = actualFolder.parentId;
      //   }
      // }

      // console.log(folderPath )
      // selectedFolderPath.value = folderPath;

      selectedFolderId.value = id;
      openFolder(id);
    }

    onMounted(() => {
      persistentStore.methods.init();

      // selectedFolderPath.value.push(persistentStore.state.userId);
      console.log("on mounted item mover")
      console.log(props.userId)
      apiGetAllFavoriteFolder();
    });

    

    function apiGetAllFavoriteFolder(withLoadingIndeicator = true) {
      loadingState.value = withLoadingIndeicator;

      console.log("api get all favorite folder...");
      // console.log(persistentStore.state.userId);
      // console.log(persistentStore.state.token);
      console.log(props.userId)

      axios
        .post(
          "verbalio_server/index.php?favorites=getAllFolder",
          {
            userId: props.userId,
            //parentId: actualParentId(), //persistentStore.state.userId,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }

          // { withCredentials: true }
        )
        .then((resp) => {
          console.log("megjott az eredmeny")
          console.log(resp);
          console.log(resp.data);

          // breadCrumbModel.value = resp.data;
          let rootObject = {
            parentId: "root",
            favoriteId: props.userId,
            label: "Kezdőlap",

            type: "folder",
            photoId: "",
            userId: props.userId,
          };

          resp.data.push(rootObject);

          // console.log("appended resp data");
          console.log(resp.data);
          allFolderModel.value = resp.data;
          filterAndSortFavoriteModel();

          // console.log("breval");
          // console.log(actualParentId());

          // filterAndSortFavoriteModel();

          // selectedBreadCrumb.value = resp.data[0];

          loadingState.value = false;
          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function filterAndSortFavoriteModel() {
      // sorting the folders in abc order
      filteredAllFolderModel.value = allFolderModel.value
        // .filter((item) => {
        //   return item.parentId == selectedFolderId.value;
        // })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });

      // adding a level to each folder (how deep it is located in the folder tree structure)
      filteredAllFolderModel.value.forEach((folder) => {
        let i = 0;
        let foundRootFolder = false;
        let rootFolderId = "root";
        let actualParentId = folder.parentId;

        // console.log(rootFolderId);

        while (!foundRootFolder && i < 100) {
          if (actualParentId == rootFolderId) {
            foundRootFolder = true;
          } else {
            // console.log("else: " + actualParentId);
            // actualParentId = filteredAllFolderModel.value.find((item) => {
            //   item.favoriteId == actualParentId;
            // }).parentId;

            let foundItem = filteredAllFolderModel.value.find((item) => {
              return item.favoriteId == actualParentId;
            });
            // console.log("found item")
            // console.log(foundItem)
            if(foundItem!=undefined)
            {
            actualParentId = foundItem.parentId;
            }
            i++;
          }
        }
        // console.log("level: " + i);

        folder.level = i;
      });

      // sorting the folders by level
      filteredAllFolderModel.value = filteredAllFolderModel.value
        // .filter((item) => {
        //   return item.parentId == selectedFolderId.value;
        // })
        .sort((a, b) => {
          // console.log(a.level + " - " + b.level)
          return a.level < b.level ? -1 : 1;
        });

      // // sort by parentId
      // let actualParentId = persistentStore.state.userId;
      // let sortedArrayByParentId = []
      // filteredAllFolderModel.value.forEach(folder => {
      //   if(folder.parentId = actualParentId)
      //   {
      //     sortedArrayByParentId.push(folder)

      //     let hasChildren = true
      //     let parentIdtoFind = folder.favoriteId
      //     while(hasChildren)
      //     {
      //       var children =  filteredAllFolderModel.value.filter((item) => { return item.parentId == parentIdtoFind });

      //       if(children.length)
      //     }
      //   }
      // });

      // sort folders below the right parentId
      // let sortedArrayByParentId = [];
      // filteredAllFolderModel.value.forEach((actualFolder) => {
      //   var children = filteredAllFolderModel.value.filter((item) => {
      //     return item.parentId == actualFolder.favoriteId;
      //   });

      //   sortedArrayByParentId.push(actualFolder);

      //   children.forEach((child) => {
      //     sortedArrayByParentId.push(child);
      //   });
      // });

      // filteredAllFolderModel.value = sortedArrayByParentId;

      filteredAllFolderModel.value = recursivelyOrderFoldersBelowParentFolder(
        filteredAllFolderModel.value,
        0
      );

      console.log("filtered model done:");
      // console.log(favoriteModel.value);
      // console.log(filteredFavoriteModel.value);
      // filteredFavoriteModel.value = [...favoriteModel.value]
    }

    function recursivelyOrderFoldersBelowParentFolder(folders, level) {
      let sortedArrayByParentId = [];

      folders.forEach((actualFolder) => {
        if (actualFolder.level == level) {
          sortedArrayByParentId.push(actualFolder);

          var children = filteredAllFolderModel.value.filter((item) => {
            return item.parentId == actualFolder.favoriteId;
          });

          if (children.length > 0) {
            sortedArrayByParentId.push(
              ...recursivelyOrderFoldersBelowParentFolder(children, level + 1)
            );
          }
        }
      });

      return sortedArrayByParentId;
    }

    // function recursivelyOrderFoldersBelowParentFolder(folders) {
    //   let sortedArrayByParentId = [];

    //   console.log("recurs");
    //   console.log(folders);
    //   folders.forEach((actualFolder) => {
    //     var children = filteredAllFolderModel.value.filter((item) => {
    //       return item.parentId == actualFolder.favoriteId;
    //     });

    //     if(!sortedArrayByParentId.includes(actualFolder))
    //         {
    //     sortedArrayByParentId.push(actualFolder);
    //         }
    //     if (children.length > 0) {
    //       var childrenOfChildren = recursivelyOrderFoldersBelowParentFolder(children)
    //       childrenOfChildren.forEach((element) =>
    //       {
    //         if(!sortedArrayByParentId.includes(element))
    //         {
    //           sortedArrayByParentId.push(element);
    //         }
    //       })

    //     }
    //   });

    //   return sortedArrayByParentId;
    // }

    function openFolder(id) {
      openFolderIds.value.push(id);
    }

    function closeFolder(id) {
      //recursively close children
      let children = [...openFoldersModel.value];
      children = children.filter((folder) => {
        return folder.parentId == id;
      });

      //  console.log(children)
      //    console.log(openFoldersModel.value)
      //  console.log(openFolderIds.value)

      children.forEach((child) => {
        closeFolder(child.favoriteId);
      });

      // close folder
      const index = openFolderIds.value.indexOf(id);
      if (index > -1) {
        openFolderIds.value.splice(index, 1);
      }
    }

    return {
      floatingWindowInput,
      floatingWindowInputModel,
      emit,
      loadingState,
      filteredAllFolderModel,
      selectedFolderId,

      selectFolder,
      selectedFolderModel,
      openFoldersModel,
      openFolder,
      closeFolder,
    };
  },
};
</script>

<style scoped>
.floatingWindowInputMargin {
  margin-bottom: 4vh;
  padding: 0.5vh;
}

.disabled {
  color: gray;
}
.disabled:hover {
  color: gray;
}

.folderTreeContainer {
  height: 65vh;

  /* background-color: pink; */
  /* display:grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: start; */
  overflow-y: auto;
  overflow-x: auto;

  min-width: auto;

  margin-left: -2.5vh;
  margin-right: -2.5vh;

  /* padding-right: 5vh;   */

  /* background-color: blueviolet; */
}

.folderTreeInnerGrowableContainer
{
  min-width: 100%;
  width: max-content;

}

.folderTreeItemContainer:hover {
  background-color: #fffbd4;
  color: black;
}

.selectedFolder.folderTreeItemContainer:hover,
.selectedFolder {
  background-color: var(--primary_color);
  color: var(--light_text_color);
}

.folderTreeItem {
  display: grid;
  justify-content: left;
  align-content: stretch;
  height: 5.5vh;
  /* gap: 0.5vh; */
  grid-template-columns: max-content 1fr;
  /* border-left-width: 0.2vh;
  border-left-style: solid;
  border-left-color: #000000; */
}

.folderToggleButton {
  /* padding:2vh; */

  display: grid;
  align-content: center;
  justify-content: right;

  width: 4vh;
  padding-right: 1vh;
}

.folderToggleButton:hover {
  /* padding:2vh; */
  /* background-color: var(--secondary_color);
  color: var(--light_text_color); */
  color: red;
}

.folderNoButton {
  width: 5vh;
}

.folderLabel {
  display: grid;
  align-content: center;
  justify-content: left;
}
</style>
