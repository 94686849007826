<template>
  <div class="loading-box">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

.loading-box{
    position: relative;
    width: 657px;
    height: 10px;
   /* border-radius: 50px;*/
    /* border: 2px solid #ededed; */
    overflow: hidden;
}

.loader{
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50px;
    background: linear-gradient(45deg, #3E7AD4, #ea00ff);

    /* left: -100%; */
    animation: load 2s linear infinite;
}

@keyframes load{
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
}
</style>
