<template>
  <div>
    <div id="pageTitle">Regisztráció</div>

    <div id="successIndicator" v-if="isSignUpSuccesful">
      <i class="succesIndicatorIcon fas fa-check-circle"></i>

      Sikeres regisztráció. <br> <br> A befejezéshez kattintson a <u>{{ emailSentTo }}</u> e-mail címre
      küldött linkre.
    </div>

    <div v-else>
      <div id="loadingIndicator" v-if="isLoading">
        <Spinner />
        <div>
          Ellenőrzés folyamatban...
        </div>
      </div>
      <div id="signUpFormContainer" v-else>
        <div id="loginFieldsContainer">
          <!-- <div class="inputLabel">Név:</div>
    <input type="text" v-model="fullName" />-->
          <div class="inputLabel">E-mail:</div>
          <input type="text" v-model="email" />
          <div class="inputLabel">Jelszó:</div>
          <input type="password" v-model="password" />
          <div class="inputLabel">Jelszó megerősítése:</div>
          <input type="password" v-model="passwordAgain" />
        </div>

        <div id="feedbackText">
          <i
            v-show="feedback.length > 0"
            class="errorIndicatorIcon fas fa-exclamation-circle"
          ></i>

          {{ feedback }}
        </div>

        <div id="privacyText">
          <strong>Adatvédelem:</strong>Tájékoztatjuk, hogy honlapunk számos
          oldalát anélkül tekintheti meg, hogy bármiféle személyes információt
          szolgáltatna magáról. A weboldalon azonban a szerkesztő felülethez
          kapcsolódó tárhely (szerkesztett képek mentése és tárolása)
          igénybevétele regisztrációhoz kötött. Ebben az esetben az Ön által
          megadott adatokat (e-mail cím) az üzemeltető szigorúan bizalmasan
          kezeli, és gondoskodik arról, hogy azokhoz illetéktelen személyek ne
          férhessenek hozzá. Az e-mail címen túlmenően személyes adatokat nem
          gyűjtünk. A hatályos törvényi előírásoknak megfelelően személyes
          adatnak minősül bármely meghatározott (azonosított vagy azonosítható)
          természetes személlyel kapcsolatba hozható adat, ha az adatból
          közvetve vagy közvetlenül levonható az érintettre vonatkozó
          következtetés. Az e-mail cím alapján semmilyen formában nem törekszünk
          arra, hogy az adatok alapján a Felhasználó természetes személyt
          azonosítsuk. A rendelkezésünkre álló hardware és software eszközökkel
          erre lehetőségünk sincs.
        </div>

        <button @click="validateAndSend">Regisztráció</button>

        <div id="alternativeActions">
          Már van fiókom -
          <router-link to="/login"> bejelentkezem</router-link>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";

import Spinner from "@/components/Spinner.vue";
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios.axios)
import PersistentStore from "../store/persistent";

export default {
  components: {
    Spinner,
  },
  setup() {
    const router = useRouter();
    const persistentStore = PersistentStore;

    const fullName = ref("");
    const email = ref("");
    const password = ref("");
    const passwordAgain = ref("");

    const feedback = ref("");

    const isLoading = ref(false);

    const isSignUpSuccesful = ref(false);
    const emailSentTo = ref("");

    function validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    function validateAndSend() {
      /* if (fullName.value.trim() == "") {
        feedback.value = "Hiányzik a név!";
        return;
      }*/

      if (!validateEmail(email.value.trim())) {
        feedback.value = "Az e-mail helytelen.";
        return;
      }

      if (password.value.trim().length < 6) {
        feedback.value = "A jelszó legalább 6 karakter legyen.";
        return;
      }

      if (password.value.trim() != passwordAgain.value.trim()) {
        feedback.value = "A két jelszó nem egyezik.";
        return;
      }

      apiPost();
    }

    function apiPost() {
      isLoading.value = true;

      axios
        .post(
          "verbalio_server/index.php?users=signup",
          {
            email: email.value.trim(),
            password: password.value.trim(),
            fullName: fullName.value.trim(),
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          // //localStorage.setItem('userData', JSON.stringify(resp.data))

          // persistentStore.state.userId = resp.data.userId;
          // persistentStore.state.userFullName = resp.data.fullName;
          // persistentStore.state.userRole = resp.data.role;
          // persistentStore.state.token = resp.data.token;
          // persistentStore.state.loggedIn = true;

          // router.push("search");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);

          isSignUpSuccesful.value = true;
          emailSentTo.value = email.value;

          fullName.value = "";
          email.value = "";
          password.value = "";
          passwordAgain.value = "";

          isLoading.value = false;

          feedback.value = "";
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");

          isLoading.value = false;
          feedback.value =
            "Sikertelen regisztráció! Az e-mail cím már foglalt."; // todo: komolyabb hibakezeles!!
        });
    }

    onMounted(() => {
      persistentStore.methods.init();

      if (persistentStore.state.token != "") {
        router.push("search");
      }
    });

    return {
      validateAndSend,
      apiPost,
      fullName,
      email,
      password,
      passwordAgain,
      feedback,
      isLoading,
      isSignUpSuccesful,
      emailSentTo,
    };
  },
};
</script>

<style scoped>
#pageTitle {
  font-size: var(--font_size_large);
  font-weight: bold;
  padding-top: 5vh;
  padding-bottom: 2.5vh;
}

input {
  font-size: var(--font_size_normal);
}

button {
  margin-top: 1vh;
  padding: 2vh;
  font-size: var(--font_size_normal);
}

#loginFieldsContainer {
  display: grid;
  grid-template-columns: 1fr;

  justify-content: center;

  align-content: center;

  width: 25%;
  margin-left: auto;
  margin-right: auto;

  row-gap: 1vh;
}

#responsive.tablet #loginFieldsContainer {
  width: 35%;
}

#responsive.mobile #loginFieldsContainer {
  width: 75%;
}

#feedbackText {
  color: darkred;
  font-size: var(--font_size_normal);
  font-weight: bold;
  padding: 3vh;
}
#successIndicator {
  color: green;
  font-size: var(--font_size_normal);
  font-weight: bold;
  padding: 3vh;
}

.inputLabel {
  font-size: var(--font_size_normal);
  display: grid;
  align-content: center;
  justify-content: start;
  margin-top: 1vh;
}

#alternativeActions {
  font-size: var(--font_size_normal);
  margin-top: 5vh;
  padding-top: 1vh;
  border-top-width: 1px;
  border-top-color: gray;
  border-top-style: solid;
  width: 35%;
  margin-left: auto;
  margin-right: auto;

  line-height: 1.8;
}

#responsive.tablet #alternativeActions {
  width: 50%;
}

#responsive.mobile #alternativeActions {
  width: 90%;
}

#privacyText {
  background-color: #bdccf0;
  border-radius: 5px;
  padding: 1vh;
  margin-left: 7.5%;
  margin-right: 7.5%;
  margin-bottom: 2vh;
}

#loadingIndicator {
  font-size: var(--font_size_normal);
}
</style>
