<template>
  <div>
    <div id="pageTitle">Bejelentkezés</div>

    <div id="loginFieldsContainer">
    <div class="inputLabel">E-mail:</div>
    <input 
      type="text"   
      v-model="email"  
    />  
    <div class="inputLabel">Jelszó:</div>
        <input 
      type="password"     
            v-model="password"  
    />
  </div>
         <div id="feedbackText">
          
          <i  v-show="feedback.length>0" class="errorIndicatorIcon fas fa-exclamation-circle"></i>
          {{ feedback }} </div>
    
    <button @click="apiPost">Bejelentkezés</button>

 <!-- <div id="alternativeActions">
  
  Még nincs fiókom - 
   <router-link to="/signup">
    regisztrálok</router-link>.
    <br>
    
    Elfelejtettem a jelszavam - 
   <router-link to="/login">
    újat igényelek</router-link>. 

   </div> -->
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios.axios)
import PersistentStore from "../store/persistent";

export default {
  setup() {
    const router = useRouter();
    const persistentStore = PersistentStore;
        const feedback = ref("");


    const email = ref("")
    const password = ref("")

    function apiCall() {
      axios
        .get("verbalio_server/", {
          headers: { Authorization: `Bearer ${"token"}` },
        })
        .then((resp) => {
          console.log(resp.data);
        });

      console.log("hello world");
    }

    function apiPost() {
      axios
        .post(
          "verbalio_server/index.php?users=login",
          {
            email: email.value,
            password: password.value,
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          persistentStore.state.userId = resp.data.userId;
          persistentStore.state.userFullName = resp.data.fullName;
          persistentStore.state.userEmail = resp.data.email;
          persistentStore.state.userRole = resp.data.role;
          persistentStore.state.token = resp.data.token;
          persistentStore.state.loggedIn = true;

          router.push("search");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
          feedback.value = "Hibás e-mail cím vagy jelszó."
        });
    }

    onMounted(() => {
       persistentStore.methods.init();
      
       if(persistentStore.state.token != "")
       {
        router.push("favorites");
       }

    });

    return {
      apiCall,
      apiPost,
      email,
      password,
      feedback
    };
  },
};
</script>

<style scoped>


#pageTitle
{
  font-size: var(--font_size_large);
  font-weight: bold; 
  padding-top: 5vh;
  padding-bottom: 2.5vh;
  
}

input
{
  font-size: var(--font_size_normal);
}

button {
  margin-top: 1vh;
  padding: 2vh;
  font-size: var(--font_size_normal);
}



#loginFieldsContainer
{
 display: grid;
 grid-template-columns: 1fr; 

 justify-content: center;

 align-content: center;

 width: 25%;
 margin-left: auto;
 margin-right: auto;
 
 
 row-gap: 1vh;
}

#responsive.tablet #loginFieldsContainer
{
  width: 35%;
}

#responsive.mobile #loginFieldsContainer
{
  width: 75%;
}


#feedbackText
{
  color: darkred;
  font-size: var(--font_size_normal);
  font-weight: bold;
  padding: 3vh;
 
}

.inputLabel
{
  font-size: var(--font_size_normal);
  display: grid;
  align-content: center;
  justify-content: start;
  margin-top: 1vh;
}

#alternativeActions {
  
  font-size: var(--font_size_normal);
 margin-top: 10vh;
 padding-top: 1vh;
 border-top-width: 1px;
 border-top-color: gray;
 border-top-style: solid;
 width: 35%;
 margin-left: auto;
 margin-right: auto;

 line-height: 1.8;

}

#responsive.tablet #alternativeActions
{
  width: 50%;
}

#responsive.mobile #alternativeActions
{
  width: 90%;
}



</style>