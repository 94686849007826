<template>
  <div class="row">
      <slot> </slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.row
{
    display: grid;
    grid-template-columns: 3fr 7fr;

    gap: 2vh;

    
    
       /* justify-content: flex-end;    */
    /* background-color: blueviolet; */
    border-bottom: rgba(128, 128, 128, 0.171);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 1vh;
}


.row.rightAlignControl
{

       grid-template-columns:    1fr min-content;
}

.row.noBottomSeparator
{

    border-bottom-width: 0px;
    padding-bottom: 0;
}



</style>