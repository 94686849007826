<template>
  <label class="switch">
    <input type="checkbox" :value="modelValue" :checked="modelValue" @change="updateModelValue($event.target.checked)"/>
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
  },

  setup(props, { emit }) {
    function updateModelValue(value) {
        
      emit("update:modelValue", value);
    }

    return {
      updateModelValue,
    };
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 3.75em;
  height: 2.125em;

  font-size: 1.9vh;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.625em;
  width: 1.625em;
  left: 0.25em;
  bottom: 0.25em;
  background-color: white;
  -webkit-transition: transform 0.4s;
  transition: transform 0.4s;
}

input:checked + .slider {
  background-color: var(--primary_color);
}

input:focus + .slider {
  /* box-shadow: 0 0 1px #2196f3; */
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.625em);
  -ms-transform: translateX(1.625em);
  transform: translateX(1.625em);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2.125em;
}

.slider.round:before {
  border-radius: 50%;
}
</style>