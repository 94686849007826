<template>
  <div id="searchPage">


    
  <SingleInputFloatingWindow
    id="folderAdderWindow"
    v-if="folderAdderWindowVisible"
    windowTitle="Mi legyen a hozáadott mappa neve?"
    acceptButtonLabel="Hozzáadás"
    @cancelButtonClicked="folderAdderWindowVisible = false"
    @acceptButtonClicked="apiPostAddFavoriteFolder"
    v-model:inputModel="folderAdderWindowInputModel"
  >
  </SingleInputFloatingWindow>

    <!-- <button @click="apiGetPhotos">Get photos</button> -->
    <SearchFilters
      id="searchFilterBox"
      ref="searchFilters"
      v-model:selectedTags="selectedTags"
      @tagsLoaded="tagsLoaded"
    />

    <div
      id="searchInputContainer"
      :class="{ shrinkSearchInputContainer: !searchInputContainerVisible }"
    >
    
      <input
        id="searchInput"
        type="search"
        placeholder="keresés..."
        v-model="searchInput"
        :class="{ shrinkSearchInput: !searchInputContainerVisible }"
      />
      
      <div id="detailsSwitchContainer" v-if="isUploader">
        <div id="detailsSwitchLabel">Részletek:</div>
        <ToggleSwitch class="toggleSwitch" v-model="showDetails">
        </ToggleSwitch>
      </div>
    </div>

    <div id="imageGridContainer">
      <Spinner id="imageLoadingSpinner" v-show="loadingState" />

      <!-- <ImageGrid
        id="imageGrid"
        v-show="!loadingState"
        :images="searchInput.length > 0 ? foundImages : filteredImages"
      /> -->

      <ImageGridBase
        id="imageGrid"
        v-show="!loadingState"
        :images="searchInput.length > 0 ? foundImages : filteredImages"
        :allTags="allTags"
        :showDetails="showDetails"
      >
        <template v-slot:controlButtons="slotProps">
          <QuickImageButton
            class="quickControl"
            :iconClass="
  'far fa-fw fa-plus-square'
            "
            @click="persistentStore.state.loggedIn? slotProps.image.favoriteCount++ : undefined, apiPostAddToFavorites(slotProps.image.photoId)"
          >
            <div class="favoriteLabel" v-if="slotProps.image.favoriteCount > 0">
              {{ slotProps.image.favoriteCount }}
            </div>
          </QuickImageButton>

          <QuickImageButton
            class="quickControl"
            :iconClass="
                 'fa fa-fw fa-folder-plus'
            "
            @click="folderAdderWindowVisible = true, folderAdderWindowPictureModel = slotProps.image,
            folderAdderWindowInputModel = slotProps.image.synonyms[0]"
          > 
          </QuickImageButton>
<!-- 
          <a
            class="controlLink"
            :href="
              'verbalio_server/uploadedImages/' +
                slotProps.image.photoId +
                '_final.png'
            "
            :download="
              slotProps.image.synonyms[0].trim().replaceAll(' ', '_') + '.png'
            "

             v-if="!isStudent"
          >
            <QuickImageButton
              class="quickControl"
              iconClass="far fa-fw fa-arrow-alt-circle-down"
            />
          </a> -->

          <a
            class="controlLink"
            :href="
              'verbalio_server/print.php?imagePath=' +   'uploadedImages/' + slotProps.image.photoId +  '_final.png'
            "
            target="_blank"
            v-if="!isStudent"
          >
            <QuickImageButton
              class="quickControl"
              iconClass="fas fa-fw fa-print"
            />
          </a>

          <QuickImageButton
            class="quickControl"
            iconClass="fas fa-fw fa-pencil-alt"
            @click="editImage(slotProps.image)"
            v-if="isUploader"
          />
        </template>
      </ImageGridBase>
    </div>

  
  </div>
</template>

<script>
// import Vue from 'vue'
import { useRouter } from "vue-router";

import SingleInputFloatingWindow from "@/components/SingleInputFloatingWindow.vue";


import { ref, watch, onMounted, onActivated, onDeactivated} from "vue";
import axios from "axios";
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios.axios)
import Store from "../store/store";
import SearchFilters from "@/components/SearchFilters.vue";
// import ImageGrid from "@/components/ImageGrid.vue";
import ImageGridBase from "@/components/ImageGridBase.vue";
import Spinner from "@/components/Spinner.vue";

import QuickImageButton from "@/components/QuickImageButton.vue";
import PersistentStore from "../store/persistent";

import ToggleSwitch from "@/components/ToggleSwitch.vue";

export default {
  name: "Search",
  components: {
    SearchFilters,
    // ImageGrid,
    Spinner,
    ImageGridBase,
    QuickImageButton,
    ToggleSwitch,
    SingleInputFloatingWindow
  },
  setup() {
    const store = Store;
    const router = useRouter();
    const persistentStore = PersistentStore;


const lastScrollPosition_y = ref(0)


    const isUploader = ref(false);
    const isStudent = ref(false);

    const searchFilters = ref(null);

    const loadingState = ref(true);

    const allTags = ref(null);

    const selectedTags = ref([]);
    const images = ref([]);
    const filteredImages = ref([]);

    const foundImages = ref([]);

    const showDetails = ref(false);

    const searchInput = ref("");

    const searchInputContainerVisible = ref(true);

    const lazyLoadCount = 12;

    const folderAdderWindowVisible = ref(false);
    const folderAdderWindowInputModel = ref("");
    const folderAdderWindowPictureModel = ref();



    // const filteredImages = computed( () => {
    //   filteredImages = images.value.filter()
    // })

    function tagsLoaded(tags) {
      // console.log("Tags loaded!!!")
      // console.log(tags)
      allTags.value = tags;
      apiGetPhotos();
    }

    // function addTagLabels()
    // {
    //   images.value.forEach(image => {
    //     image.tagIds.forEach(tagId => {

    //     });
    //   });
    // }

    function editImage(image) {
      store.state.uploadedImageToEdit = image;
      router.push("upload");
    }

    function filterImages(scrollToTop = true) {
      loadingState.value = true;

      var t0 = performance.now();

      console.log("filter images");
      var tagsInLogicalOrder = searchFilters.value.getSelectedTagsInLogicalOrder();

      var filteredImagesList = [];

      // var addToList = true;

      console.log(tagsInLogicalOrder);

      images.value.forEach((image) => {
        var addToList_and = true;

        // console.log(image)

        tagsInLogicalOrder.forEach((andTag) => {
          var addToList_or = false;
          // console.log(andTag)
          andTag.forEach((orTag) => {
            addToList_or = addToList_or || image.tagIds.includes(orTag);
          });
          addToList_and = addToList_and && addToList_or;
        });

        if (addToList_and) {
          filteredImagesList.push(image);
        }
        // console.log("gi")
        // console.log(image)
      });

      filteredImages.value = filteredImagesList;

      var t1 = performance.now();
      console.log(
        "Call to filterImages() took " + (t1 - t0) + " milliseconds."
      );

      // scroll to top of page
      if (scrollToTop) {
        console.log("scrollToTop")
        window.scrollTo(0, 0);
      }

      loadingState.value = false;
      // console.log(filteredImagesList);
    }

    var filterPostponer;

    watch(
      selectedTags,
      () => {
        // console.log(selectedTags.value);

        // var filteredImages = images.value.filter((image) => image.)

        if(selectedTags.value.length>0)
        {
          searchInput.value = ""
        }
        

        clearTimeout(filterPostponer);

        filterPostponer = setTimeout(
          function() {
            filterImages();

            if (searchInput.value.length > 0) {
              findImages();
            }
          },

          50
        );
      },
      { flush: "post" } // only triggers callback after DOM update
    );

    function findImages(scrollToTop = true) {
      
      selectedTags.value=[]

      loadingState.value = true;

      var t0 = performance.now();

      console.log("find images");

      var startsWithImageList = [];
      var containsImageList = [];

      var textToSearch = searchInput.value.toLowerCase().trim();

      // var addToList = true;

      filteredImages.value.forEach((image) => {
        // console.log(image)

        var found = false;
        var i = 0;

        while (!found && i < image.synonyms.length) {
          if (image.synonyms[i].toLowerCase().indexOf(textToSearch) > -1) {
            found = true;
            if (i == 0) {
              startsWithImageList.push(image);
            } else {
              containsImageList.push(image);
            }
          } else {
            i++;
          }
        }
      });

      foundImages.value = startsWithImageList.concat(containsImageList);

      var t1 = performance.now();
      console.log("Call to findImages() took " + (t1 - t0) + " milliseconds.");

      // scroll to top of page
      if (scrollToTop) {
        window.scrollTo(0, 0);
      }
      loadingState.value = false;
      // console.log(filteredImagesList);
    }

    var searchPostponer;

    watch(
      searchInput,
      () => {
        clearTimeout(searchPostponer);

        searchPostponer = setTimeout(
          function() {
            findImages();
          },

          200
        );

        // console.log(selectedTags.value);

        // var filteredImages = images.value.filter((image) => image.)

        //filterImages();
      },
      { flush: "post" } // only triggers callback after DOM update
    );

    function apiGetPhotos(scrollToTop=true) {
      axios
        .post(
          "verbalio_server/index.php?photos",
          {
            userId: persistentStore.state.userId,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          // We set the load state


          for (var i = 0; i < resp.data.length; i++) {
            resp.data[i].load = i < lazyLoadCount * 2;
          }

          images.value = resp.data;

          filterImages(scrollToTop);
          // tags.value = resp.data;

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    onMounted(() => {
      // searchInputContainerVisible.value = true;
      addScrollListener()

      persistentStore.methods.init();
      isUploader.value =
        persistentStore.state.userRole == "uploader" ||
        persistentStore.state.userRole == "admin";

          isStudent.value =
        persistentStore.state.userRole == "student";

      // searchInputContainerVisible.value = true
      // apiGetPhotos();

    // window.addEventListener('scroll', () => {
    //   console.log(window.scrollY)
    //   if(window.scrollY!=0)
    //   {
    //   lastScrollPosition_y.value = window.scrollY
    //   }
    //   console.log(window.scrollY)
    // });


    });


    onActivated(() => {
addScrollListener()
      // searchInputContainerVisible.value = true;

window.scrollTo(0, lastScrollPosition_y.value) // visszaallitjuk a legutobbi scroll piziciot

 
//  console.log("lastScrollPosition_y " + lastScrollPosition_y.value)

      if(store.state.searchPageNeedsUpdate)
      {

        apiGetPhotos(false)

        store.state.searchPageNeedsUpdate = false
      }
    });


        onDeactivated(() => {
 
//  console.log("onDEactivateed" + window.scrollY)

//  lastScrollPosition_y.value = window.scrollY // lementjuk a deaktivalas elotti scroll poziciot
    });

    function apiPostAddToFavorites(photoId) {

      if(!persistentStore.state.loggedIn)
      {
        router.push("login");
      }
      else
      {
      axios
        .post(
          "verbalio_server/index.php?favorites=add",
          {
            userId: persistentStore.state.userId,
            parentId: store.state.actualFavoriteParentId, //persistentStore.state.userId,
            label: "",
            type: "photo",
            photoId: photoId,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);

          // apiGetPhotos(false);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
      }
    }

    function apiPostAddFavoriteFolder() {
      folderAdderWindowVisible.value = false;
      let newFolderLabel = folderAdderWindowInputModel.value;
      folderAdderWindowInputModel.value = "";

      axios
        .post(
          "verbalio_server/index.php?favorites=add",
          {
             userId:  persistentStore.state.userId,
            parentId: store.state.actualFavoriteParentId,
            label: newFolderLabel,
            type: "folder",
            photoId: folderAdderWindowPictureModel.value.photoId,
          },
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }

          // { withCredentials: true }

        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          //apiGetFavorites(false);
          router.push("favorites")

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }


    /* When the user scrolls down, hide the searchbar. When the user scrolls up, show the searchbar */
    var initHappened;  
    var prevScrollpos;
function addScrollListener()
  {

    initHappened = false; // on initing the view, we have to always show the searchinputbar
    prevScrollpos = window.pageYOffset;

searchInputContainerVisible.value = true;

    window.onscroll = function() {


      if(window.scrollY!=0) // lementjuk az aktualis scroll poziciot, hogy mikor visszaterunk az oldalra, ott folytathassuk ahol abbahagytuk (ld. onActivated hook)
      {
      lastScrollPosition_y.value = window.scrollY
      }


      if (!initHappened) {
        searchInputContainerVisible.value = true;
      } else {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
          searchInputContainerVisible.value = true;
        } else {
          searchInputContainerVisible.value = false;
        }
        prevScrollpos = currentScrollPos;
      }
    };

    initHappened = true;

  }





    return {
      folderAdderWindowVisible,
      folderAdderWindowInputModel,
      folderAdderWindowPictureModel,
      apiPostAddFavoriteFolder,

      tagsLoaded,
      allTags,
      selectedTags,
      apiGetPhotos,
      images,
      filteredImages,
      searchFilters,
      loadingState,
      searchInput,
      searchInputContainerVisible,
      foundImages,
      apiPostAddToFavorites,
      editImage,
      isUploader,
      isStudent,
      showDetails,
      persistentStore
    };
  },
};
</script>

<style scoped>
/* .toggleSwitch
{
padding: 0;
margin: 0;
} */

#detailsSwitchContainer {
  /* background-color: lightblue; */

  float: right;
  padding-right: 2vh;

  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 7vh;
  align-content: center;

  justify-items: center;
}

#detailsSwitchLabel {
  /* background-color: red; */
  font-weight: bold;
  display: grid;
  justify-content: center;
  align-content: center;
}

#searchFilterBox {
  position: fixed;
  top: var(--height_header);

  height: var(--height_content);

  width: 15vw;
  background-color: #ebebf3;

  /* padding-top: 2.5vh; */
  padding-left: 2vh;

  z-index: 100;

  /* box-shadow: 0 0 0.8vh 0 rgba(3, 85, 216, 0.15); */
}

#responsive.tablet #searchFilterBox {

  width: 25vw;

}

#responsive.mobile #searchFilterBox {

width: 35vw;

}

#imageGridContainer {
  display: grid;
  justify-content: center;
  width: 82vw;

  /*padding-top: 2vh;

  padding-bottom: 5vh;*/

  float: right;

   /*background-color: yellow; */
}

#responsive.tablet #imageGridContainer {
  /* grid-template-columns: 1fr 1fr 1fr; */

  width: 72vw;
}

#responsive.mobile #imageGridContainer {
  /* grid-template-columns: 1fr; */

  width: 62vw;
}

#imageGrid {
  padding-top:   16.5vh;
}
#imageLoadingSpinner {
  padding-top: 4vh;
}
#searchInputContainer {
  padding-top: 3vh;
  padding-bottom: 3vh;
  position: fixed;
  right: 0;
  top: var(--height_header);
  /* top: -100; */
  width: 85vw;
  /* padding-top: 5vh; */
  background-color: var(--background_color_content_see_through);

 
 backdrop-filter: blur(10px);
 -webkit-backdrop-filter: blur(10px);

  transition: padding 0.2s; /* Transition effect when sliding down (and up) */ 

  box-shadow: 0.8vh 0 0.8vh 0 rgba(3, 85, 216, 0.15);
}

#responsive.tablet #searchInputContainer {
  /* grid-template-columns: 1fr 1fr 1fr; */

  width: 75vw;
}

#responsive.mobile #searchInputContainer {
  /* grid-template-columns: 1fr; */

  width: 62vw;
}



#searchInputContainer.shrinkSearchInputContainer {
  
/*height: 0vh;*/

padding-top: 1.7vh;
  padding-bottom: 1.7vh;

 



  /*top: -40vh;
  overflow: hidden;*/
}

#searchInput {
  width: 70vh;
  padding: 2vh;
  background-color: #f8e5ad;

  border-color: black;
  border-width: 0.1vh;
  border-style: solid;
  /* border-radius: 1vh; */

  font-size: var(--font_size_normal);
  transition: padding 0.2s; /* Transition effect when sliding down (and up) */ 
  /* border-radius: 2vh; */

  
}

#searchInput.shrinkSearchInput
{
  padding: 1vh;
}

#responsive.tablet #searchInput {
 
  width: 80%;
}

#responsive.mobile #searchInput {
 
  width: 85%;
}


#searchPage {
  min-height: 100vh;
  /* overflow: scroll;
height: 90vh; */
}

.quickControl {
  height: 7vh;
}

.controlLink {
  text-decoration: none;
}

.favoriteLabel
{
  font-weight: bold;
  
}
</style>
