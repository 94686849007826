<template>
 
    <div class="imageButton" v-bind:class="{disabled: disabled}">
      <i class="imageButtonIcon" v-bind:class="iconClass"></i>
      <div class="imageButtonLabel">{{ label }}</div>
    </div>
   
</template>

<script>
export default {
  props: {
    iconClass: { type: String, required: true },
    label: { type: String, required: true },
    routerLink: { type: String, required: true },
    disabled: { type: Boolean, required: false },
  },
};
</script>

<style scoped>
.imageButton {
  display: grid;
  grid-template-columns: minmax(auto, min-content) minmax(auto, max-content);
  /* grid-template-columns:   11fr 8fr;*/
  /*grid-template-columns: minmax(min-content, 2fr) minmax(min-content, 8fr);*/
  /*align-content: center;*/

  padding-top: 0.5vh;

  column-gap: 1.5vh;
  padding-left: 1vh;
  padding-right: 1vh;
  /*background-color: tomato;*/

  color: var(--primary_color);

user-select: none;

border-radius: 1vh;

 
}

.imageButton.disabled
{
  color: #ccc;
  pointer-events: none;
  touch-action: none;
}

 


 .imageButton:hover
 {
     background-color: var(--secondary_color);
     color: var(--light_text_color);
 }

.imageButtonLabel {
  display: grid;
  align-content: center;
  justify-content: start;
  text-transform: uppercase;
  font-size: var(--font_size_normal);
  /*background-color: teal;*/
}
/*
.verticalMenu .imageButtonLabel {
  font-weight: 600;
}*/

.imageButtonIcon {
  font-size: var(--icon_size_normal);
  display: grid;
  align-content: center;
  /*background-color: tomato;*/
}

 

 
 
 
</style>