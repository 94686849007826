<template>
  <EditorBox label="Ikonok" iconClass="fas fa-fw fa-star">
    <Column>
      <SwitchRow>
        <Label>vízjel:</Label>
        <ToggleSwitch
          class="toggleSwitch"
          v-model="canvasStore.state.configWaterMarkImage.visible"
        >
        </ToggleSwitch>
      </SwitchRow>

      <Row class="rightAlignControl">
        <Label class="topAlignLabel"> bal, jobb:</Label>
        <ExtraImageChooserBar :extraImages="extraImages_leftRight">
        </ExtraImageChooserBar>
      </Row>

      <Row class="rightAlignControl">
        <Label class="topAlignLabel"> jó, rossz:</Label>
        <ExtraImageChooserBar :extraImages="extraImages_goodBad">
        </ExtraImageChooserBar>
      </Row>

            <Row class="rightAlignControl">
        <Label class="topAlignLabel"> érzékelés:</Label>
        <ExtraImageChooserBar :extraImages="extraImages_senses">
        </ExtraImageChooserBar>
      </Row>

            <Row class="rightAlignControl noBottomSeparator">
        <Label class="topAlignLabel"> írásjel:</Label>
        <ExtraImageChooserBar :extraImages="extraImages_marks">
        </ExtraImageChooserBar>
      </Row>


<!-- 
      <div class="addExtraImageButtonContainer">
        <ImageButton
          class="addExtraImageButton"
          label="Új Kép"
          iconClass="fas fa-fw fa-plus"
          @click="handleAddExtraImageButtonClick"
        />

             <ImageButton
          class="addExtraImageButton"
          label="Törlés"
          iconClass="far fa-fw fa-trash-alt"
          @click="handleAddExtraImageButtonClick"
        />
      </div> -->
    </Column>
  </EditorBox>
</template>

<script>
// import { ref} from "vue";
import EditorBox from "@/components/EditorBox/EditorBox.vue";
import CanvasStore from "../../store/canvas";
// import Store from "../../store/store";
// import { useRouter } from "vue-router";

import ToggleSwitch from "@/components/ToggleSwitch.vue";

import ExtraImageChooserBar from "@/components/ExtraImageChooserBar.vue";

import Label from "@/components/EditorBox/Label.vue";
import Row from "@/components/EditorBox/Row.vue";
import Column from "@/components/EditorBox/Column.vue";
import SwitchRow from "@/components/EditorBox/SwitchRow.vue";

// import ImageButton from "@/components/ImageButton.vue";

export default {
  components: {
    EditorBox,
    ToggleSwitch,
    ExtraImageChooserBar,
    Column,
    Row,
    SwitchRow,
    Label,
    // ImageButton,
  },
  setup() {
    const canvasStore = CanvasStore;
    // const store = Store;
    // const router = useRouter();

    const extraImages_leftRight = [
      {
        name: "bal_sziv",
        controlImage_active: "extras/controls/bal_sziv_active.png",
        controlImage_passive: "extras/controls/bal_sziv_passive.png",
        image: "extras/icons/bal_sziv.png",
        type: "icon",
        location: "left",
      },
      {
        name: "jobb_kez",
        controlImage_active: "extras/controls/jobb_kez_active.png",
        controlImage_passive: "extras/controls/jobb_kez_passive.png",
        image: "extras/icons/jobb_kez.png",
        type: "icon",
        location: "right",
      },
    ];

    const extraImages_goodBad = [
      {
        name: "athuzas",
        controlImage_active: "extras/controls/athuzas_active.png",
        controlImage_passive: "extras/controls/athuzas_passive.png",
        image: "extras/icons/athuzas.png",
        type: "overlay",
        location: "center",
      },
      {
        name: "pipa",
        controlImage_active: "extras/controls/pipa_active.png",
        controlImage_passive: "extras/controls/pipa_passive.png",
        image: "extras/icons/pipa.png",
        type: "overlay",
        location: "center",
      },
    ];

    const extraImages_senses = [
      {
        name: "hallas",
        controlImage_active: "extras/controls/hallas_active.png",
        controlImage_passive: "extras/controls/hallas_passive.png",
        image: "extras/icons/hallas.png",
        type: "icon",
        location: "right",
      },
      {
        name: "latas",
        controlImage_active: "extras/controls/latas_active.png",
        controlImage_passive: "extras/controls/latas_passive.png",
        image: "extras/icons/latas.png",
        type: "icon",
        location: "right",
      },
      {
        name: "szaglas",
        controlImage_active: "extras/controls/szaglas_active.png",
        controlImage_passive: "extras/controls/szaglas_passive.png",
        image: "extras/icons/szaglas.png",
        type: "icon",
        location: "right",
      },
      {
        name: "tapintas",
        controlImage_active: "extras/controls/tapintas_active.png",
        controlImage_passive: "extras/controls/tapintas_passive.png",
        image: "extras/icons/tapintas.png",
        type: "icon",
        location: "right",
      },
      {
        name: "izleles",
        controlImage_active: "extras/controls/izleles_active.png",
        controlImage_passive: "extras/controls/izleles_passive.png",
        image: "extras/icons/izleles.png",
        type: "icon",
        location: "right",
      },
    ];

    const extraImages_marks = [
      {
        name: "kerdojel",
        controlImage_active: "extras/controls/kerdojel_active.png",
        controlImage_passive: "extras/controls/kerdojel_passive.png",
        image: "extras/icons/kerdojel.png",
        type: "icon",
        location: "right",
      },
      {
        name: "felkialtojel",
        controlImage_active: "extras/controls/felkialtojel_active.png",
        controlImage_passive: "extras/controls/felkialtojel_passive.png",
        image: "extras/icons/felkialtojel.png",
        type: "icon",
        location: "right",
      },
    ];

    // function handleAddExtraImageButtonClick() {
    //   store.state.extraImageAdderState = true;
    //   router.push("search");
    //   // alert("A backend elkészültével ezzel a gombbal lehet majd hozzáadni további képet.")
    // }

    return {
      canvasStore,
      extraImages_leftRight,
      extraImages_goodBad,
      extraImages_senses,
      extraImages_marks,
      // handleAddExtraImageButtonClick,
    };
  },
};
</script>

<style scoped>
/* .toggleSwitch {
  font-size: 2.2vh;
} */

#borderSizeSlider {
  /*font-size: 4vh;*/
  /*width:90%;*/
}

.topAlignLabel {
  align-content: flex-start;
}

.addExtraImageButtonContainer {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: right;
  height: 5vh;

  
}

.addExtraImageButton {
  display: grid;
  justify-content: right;

}
</style>
