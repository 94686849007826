<template>
  <div id="helpMenuContainer" @click="hideHelpMenu">
    <div id="helpMenu">
      <div class="helpMenuTextItem">
        {{ helpModel[store.state.helpMenuActualId].text }}
      </div>
      <div class="helpMenuItem">
        <ImageButton
          @click.stop="playAudio"
          label="Meghallgatás"
          iconClass="fas fa-fw fa-volume-up"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Store from "../store/store";
import ImageButton from "@/components/ImageButton.vue";

export default {
  components: {
    ImageButton,
  },
  setup() {
    const store = Store;

    let audio = ref();

    const helpModel = ref({
      upperTextBox: {
        text: `Szeretne a képhez írást adni? Ebben a dobozban a képen felül
        megjelenő szöveget formázhatja.`,
        type: "editorBox",
        audio: "",
      },
      upperTextBox_text: {
        text: `Ide írja be azt a szót vagy szókapcsolatot, amit a képhez felül szeretne hozzáadni.
        A szövegdobozba klikkelve kiválaszthatja a képhez tartozó, eredeti kifejezést is.
        Amikor a képet letölti, a fájl megnevezése azonos lesz a képhez tartozó, eredeti kifejezéssel
        vagy azzal a felirattal, amit Ön adott hozzá.`,
        type: "editorRow",
        audio: "",
      },
      upperTextBox_font: {
        text: `Itt formázhatja a felső felirat betűtípusát.
        Különböző talpas (serif) és talpatlan (sans serif) betűk közül választhat.
        Kutatási eredmények alapján úgy tűnik, hogy webes használat esetén a talpas,
        nyomtatott formában a talpatlan betűket könnyebb olvasni.`,
        type: "editorRow",
        audio: "",
      },
      upperTextBox_upperCase: {
        text: `Szeretné, ha a felső felirat minden betűje nagybetű lenne? Csúsztassa a csuszkát
        balról jobbra! Ha csúszkát jobbról balra húzza, azaz kikapcsolja a gombot, akkor a szöveget csupa
        kisbetűvel fogja látni.`,
        type: "editorRow",
        audio: "",
      },
      upperTextBox_color: {
        text: `Szeretné megváltoztatni a betű színét?
        A piros/fekete gombra klikkelve a szó első betűje
        (kétjegyű kezdőbetű esetén mind a két betű) pirosra vált.
        Választhatja még a kék színt. Ha több szín közül szeretne választani, klikkeljen a + jelre.
        Egy felnyíló ablakban összesen 12 szín jelenik meg. Klikkeljen arra a színre, amilyen színű betűt szeretne.
        Ha már nincs szüksége a színválasztóra, klikkeljen a színválasztó felirattól jobbra látható x-re!`,
        type: "editorRow",
        audio: "",
      },
      upperTextBox_fontSize: {
        text: `Szeretné megváltoztatni a betű méretét?
        10-100 közötti betűméretet választhat.
        Begépelheti a kívánt betűméretet a dobozba vagy a csúszka jobbra és balra húzogatásával
        is kiválaszthatja a megfelelő betűméretet. Figyelem! Miközben
        Ön átállítja a betűméretet, a kép mérete automatikusan változik.`,
        type: "editorRow",
        audio: "",
      },
    });

    function playAudio() {
      stopAudio();

      audio.value = new Audio(
        "helpAudio/" + store.state.helpMenuActualId + ".m4a"
      );
      
      audio.value.play();
    }

    function stopAudio() {
      if (audio.value != undefined) {
        console.log("not undefined " + store.state.helpMenuActualId);
        audio.value.pause();
        audio.value.currentTime = 0;
      }
    }

    function hideHelpMenu() {
      stopAudio();
      store.state.helpMenuVisible = false;
    }
    return {
      store,
      hideHelpMenu,
      helpModel,
      playAudio,
    };
  },
};
</script>

<style scoped>
#helpMenuContainer {
  z-index: 100000;

  background-color: #00000010;

  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
}

#helpMenu {
  --mouse-x: 9.5vw;
  --mouse-y: 15vh;
  /* display: none; */
  position: fixed;

  z-index: 19999;
  margin: 0;
  left: 0;
  top: 0;
  /* The following line is responsible for all the magic */
  transform: translateX(min(var(--mouse-x), calc(100vw - 100%)))
    translateY(min(var(--mouse-y), calc(100vh - 100%)));

  background-color: white;

  user-select: none;
  display: grid;
  grid-template-columns: 1fr;
  /* gap: 1vh; */

  box-shadow: 0px 0px 10px 0px rgba(3, 85, 216, 0.15);

  width: 25vw;
}

.helpMenuTextItem {
  background-color: white;

  padding: 2vh;
  padding-bottom: 0vh;

  font-size: var(--font_size_normal);
  font-weight: bold;

  text-align: left;
}
.helpMenuItem {
  background-color: white;

  padding: 2vh;

  font-size: var(--font_size_normal);
  font-weight: bold;
  /* width: 20vw; */

  display: grid;

  justify-content: stretch;
  align-content: stretch;

  width: auto;
  height: 7.5vh;
}

/* .helpMenuItem:hover {
  background-color: #fffbd4;
}

.helpMenuItem:active {
  background-color: var(--secondary_color);
  color: var(--light_text_color);
} */
</style>
