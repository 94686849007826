<template>
  <div id="autoCompleteInput">
    <input
      id="searchInput"
      type="search"
      autocomplete="off"
      
      @focus="showOptions()"
      @blur="exit()"

      :value="inputValue"
      @input="updateModelValue($event.target.value)"
    />
    <div id="resultsContainer" v-show="optionsShown">
      <div
        class="resultItem"
        
        v-for="(option, index) in fitleredOptions"
        :key="index"
      @mousedown="optionSelected(option)"
      >
        {{ option }}
      </div>
    </div>

        <!-- <input id="searchInput" autocomplete="off" type="text"  :value="inputValue" @input="updateModelValue($event.target.value)"
     name="example" list="exampleList" />
    <datalist id="exampleList">
      <option  v-for="(option, index) in fitleredOptions"
        :key="index" :value="option"></option>
       
    </datalist> -->  
  </div>
</template>

<script>

import { ref, computed } from "vue";

export default {
   
  props: {
    inputValue: String,
    options: {
      type: Array,
      required: true,
    },
  },

  setup(props, {emit}) {
    const searchInput = ref(null);

    const optionsShown = ref(false);

    const fitleredOptions = computed(() => {
      const filtered = [];
      const allOption = [];

      const regOption = new RegExp(props.inputValue, "ig");
      for (const option of props.options) {
        if (option.match(regOption)) {
          filtered.push(option);
        }
        else
        {
          allOption.push(option);
        }

      }

      return filtered.sort().concat(allOption.sort());
    });

     

    function showOptions() {
      optionsShown.value = true;
    }

    function exit() {
      optionsShown.value = false;
    }

        function optionSelected(option) {
      //searchInput.value=option
        updateModelValue(option)
      optionsShown.value = false;
    }

    function updateModelValue(value)
    {
      emit('update:inputValue', value)
    }

    return {
      optionsShown,
      showOptions,
      exit,
      fitleredOptions,
      searchInput,
      optionSelected,
      updateModelValue
    };
  },
};
</script>

<style scoped>

#searchInput
{
  font-size: var(--font_size_editor_box_input);
  padding: 0.1vh;
}
 
#autoCompleteInput {
  /* width: 200px;
  height: 50px; */
  background-color: white;

  display: grid;
  justify-content: stretch;
  grid-template-columns: 1fr;
  gap: 1vh;

 
}

#resultsContainer {
  width: 200px;
  margin-top:3vh;
  /* height: 500px; */
  background-color: white;
  position:fixed;
  z-index: 1000000000000;
  cursor: default;

/* border-style: solid;
border-color: black;
border-width: 1px; */
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);

      
}

.resultItem
{
  height: 5vh;
 display: grid;
 align-content: center;
 justify-content: start;
 padding-left: 1vh;
 font-size: var(--font_size_editor_box_input);
}

.resultItem:hover
{
   background-color: rgba(30, 144, 255, 1);
   color: white;
}

</style>