<template>
  <br>
  <div>
    <strong>Kijelentkezés folyamatban...</strong>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
// import axios from "axios";
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios.axios)
import PersistentStore from "../store/persistent";

export default {
  setup() {
    const router = useRouter();
    const persistentStore = PersistentStore;

    if(persistentStore.state.loggedIn)
    {
    persistentStore.state.userId = "";
    persistentStore.state.userFullName = "";
    persistentStore.state.userRole = "";
    persistentStore.state.token = "";
    persistentStore.state.loggedIn = false;

    // reload page, so the old settings will be lost
    router.go();
    }
    else
    {
     
    router.push('login')
    
    }
    return {};
  },
};
</script>

<style scoped></style>
