<template>
  <div id="editorBox">
    <div id="header">
      <div><i id="icon" v-bind:class="iconClass"></i></div>



      <!-- <div><i id="helpIcon" class="far fa-fw fa-question-circle"></i>                
        </div> -->
      
      


      <div id="title">{{ label }}</div>
              <!-- <HelpButton helpId="upperTextBox"/> -->
      <!-- <RestoreButton @click="emit('restoreRequested')" /> -->
    </div>
    <div id="content"><slot> </slot></div>
  </div>
</template>

<script>
//import HelpButton from "@/components/HelpButton.vue";
// import RestoreButton from "@/components/RestoreButton.vue";

export default {
  props: {
    iconClass: { type: String, required: true },
    label: { type: String, required: true },
  },
  emits: ["restoreRequested"],
  components: {
    /*HelpButton,*/
    // RestoreButton,
  },
  setup(props, {emit}) {
     

    return { emit };
  },
};
</script>

<style scoped>
#editorBox {
  background-color: rgb(255, 255, 255);
  display: grid;
  grid-template-rows: 5vh 1fr;

  border-radius: 2vh;
  overflow: hidden;

  box-shadow: 0px 0px 10px 0px rgba(3, 85, 216, 0.15);
}

#header {
  background-color: #fffbd4;
  color: #535151;
  display: grid;
  grid-template-columns: 4vh  1fr  4vh /*4vh*/;
  align-content: center;
  justify-content: center;
  font-size: var(--font_size_normal);
  /* gap: 0.5vh; */

padding-left: 0.4vh;
padding-right: 0.4vh;
  user-select: none;
}

#icon {
  /* background-color: chocolate; */
}

#title {
  /* background-color: coral; */
  text-transform: uppercase;
  font-weight: bold;
  /*padding-left: 4vh;*/
}
</style>
