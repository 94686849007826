<template>
  <EditorBox label="Festékesvödör" iconClass="fas fa-fw fa-fill-drip">
    <Column>
      <SwitchRow>
        <Label> ki/be:</Label>
        <ToggleSwitch
          class="toggleSwitch"
          v-model="canvasStore.state.configPaintBucket._on"
        >
        </ToggleSwitch>
      </SwitchRow>

      <Row class="rightAlignControl">
        <Label class="topAlignLabel"> szín:</Label>
        <ColorChooserBar
          colorSet="paintBucketColors"
          v-model:selectedColor="canvasStore.state.configPaintBucket._color"
        >
        </ColorChooserBar>
      </Row>
      <SwitchRow class="noBottomSeparator">
        <Label> szürkeárnyalatos:</Label>

        <ToggleSwitch
          class="toggleSwitch"
          :modelValue="grayScaleVersion"
          @update:modelValue="updateModelValue('grayScaleVersion', $event)"
        >
        </ToggleSwitch>
      </SwitchRow>
    </Column>
  </EditorBox>
</template>

<script>
// import { ref} from "vue";
import EditorBox from "@/components/EditorBox/EditorBox.vue";
import CanvasStore from "../../store/canvas";

import ToggleSwitch from "@/components/ToggleSwitch.vue";

import ColorChooserBar from "@/components/ColorChooserBar.vue";

import Label from "@/components/EditorBox/Label.vue";
import Row from "@/components/EditorBox/Row.vue";
import Column from "@/components/EditorBox/Column.vue";
import SwitchRow from "@/components/EditorBox/SwitchRow.vue";

export default {
  components: {
    EditorBox,
    ToggleSwitch,
    ColorChooserBar,
    Column,
    Row,
    SwitchRow,
    Label,
  },

  props: {
    grayScaleVersion: Boolean,
  },

  setup(props, { emit }) {
    const canvasStore = CanvasStore;

    function updateModelValue(model, value) {
      emit("update:" + model, value);
    }
    return {
      canvasStore,
      updateModelValue,
    };
  },
};
</script>

<style scoped>
/* .toggleSwitch {
  font-size: 2.2vh;
} */

#borderSizeSlider {
  /*font-size: 4vh;*/
  /*width:90%;*/
}

.topAlignLabel {
  align-content: flex-start;
}
</style>
