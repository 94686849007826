<template>
  <div id="imageEditor">
    <ImageEditorCanvas :controlsVisible="true" />
    <ImageEditorDashboard />

    <Help v-show="store.state.helpMenuVisible"> </Help>

    <ColorChooserWindow
      id="colorChooserWindow"
      :isVisible="store.state.colorChooserWindow_visible"
      :colorSet="store.state.colorChooserWindow_colorSet"
      @hideRequested="store.state.colorChooserWindow_visible = false"
    />


      
  </div>
</template>

<script>

import { onMounted } from "vue";


import Help from "@/components/Help.vue";

import ImageEditorCanvas from "@/components/ImageEditorCanvas.vue";
import ImageEditorDashboard from "@/components/ImageEditorDashboard.vue";
import ColorChooserWindow from "@/components/ColorChooserWindow.vue";
import Store from "../store/store";

export default {
  components: {
    ImageEditorCanvas,
    ImageEditorDashboard,
    ColorChooserWindow,
    Help,
  },
  setup() {
    const store = Store;


    onMounted(() => {

      window.scrollTo(0, 0) // az elejere scrollozunk

   
    });


    return {
      store,
    };
  },
};
</script>

<style scoped>
#imageEditor {
  background-color: #f6f6f9;
  min-height: var(--height_content);

  display: grid;
  grid-template-columns: 3fr 7fr;
}

#responsive.mobile #imageEditor {
  grid-template-columns: 1fr;
}

#colorChooserWindow {
width: 67.5vw;
margin-left: 32.5vw;


}
</style>
