<template>
  <EditorBox label="Méretezés" iconClass="fas fa-fw fa-arrows-alt">
    <Column>
      <Row>
        <Label>melyiket:</Label>
        <!-- id="fontFamilySelect"
          :value="fontFamily"
          @input="updateModelValue('fontFamily', $event.target.value)" -->
        <select
          id="imageSelector"
          v-model="canvasStore.state.actualMainImage"
        >
          <!-- <option value="0">{{canvasStore.state.imageMetaData.synonyms[0]}}</option> -->
          <option
            v-for="(mainImage, i) in canvasStore.state.configMainImage"
            :key="'mio' + i"
            :value="{index: i, type: 'configMainImage'}"
            >{{ mainImage._label }}</option
          >
          <option
            v-for="(extraImageName, i) in canvasStore.state
              .extraImageNameList"
            :key="'eio' + i"
            :value="{index: i, type: 'configExtraImages'}"
            >{{ extraImageName }}</option
          >

          <!-- <option value="Times New Roman">Times New Roman</option> -->
        </select>
      </Row>

      <Row>
        <Label> méret:</Label>
        <EditableRangeSlider
          v-model:sliderValue="
            canvasStore.state[canvasStore.state.actualMainImage.type][
              canvasStore.state.actualMainImage.index
            ]._zoom
          "
          min="10"
          max="210"
        >
        </EditableRangeSlider>
      </Row>

      <Row
      
      v-bind:class="{ disabledRow: canvasStore.state.actualMainImage.type != 'configMainImage' }"

      >
        <Label> forgatás:</Label>
        <EditableRangeSlider
          v-model:sliderValue="
            canvasStore.state[canvasStore.state.actualMainImage.type][
              canvasStore.state.actualMainImage.index
            ].rotation
          "
          min="-180.0"
          max="180.0"
        >
        </EditableRangeSlider>
      </Row>

      <SwitchRow>
        <Label> kép mozgatása:</Label>
        <ToggleSwitch
          class="toggleSwitch"
          v-model="
            canvasStore.state[canvasStore.state.actualMainImage.type][
              canvasStore.state.actualMainImage.index
            ].draggable
          "
        >
        </ToggleSwitch>
      </SwitchRow>

      <Row id="layerOrderRow">
        <Label>rétegsorrend:</Label>
        <!-- <ToggleSwitch class="toggleSwitch" v-model="canvasStore.state.actualMainImageIsOnTop"> </ToggleSwitch> -->
        <button class="layerOrderButton" @click="canvasStore.state.actualMainImageZIndex--">
          háttrébb
        </button>
        <button class="layerOrderButton" @click="canvasStore.state.actualMainImageZIndex++">
          előrébb
        </button>
      </Row>

      <SwitchRow class="noBottomSeparator" 
      v-bind:class="{ disabledRow: canvasStore.state.actualMainImage.type != 'configMainImage' }"
      >
        <Label>kép tükrözése:</Label>
        <ToggleSwitch
          class="toggleSwitch"
          v-model="
            canvasStore.state[canvasStore.state.actualMainImage.type][
              canvasStore.state.actualMainImage.index
            ]._flipped
          "

          
        >
        </ToggleSwitch>
      </SwitchRow>
    </Column>
  </EditorBox>
</template>

<script>
// import { ref} from "vue";
import EditorBox from "@/components/EditorBox/EditorBox.vue";
import CanvasStore from "../../store/canvas";

import ToggleSwitch from "@/components/ToggleSwitch.vue";
import EditableRangeSlider from "@/components/EditableRangeSlider.vue";

import Label from "@/components/EditorBox/Label.vue";
import Row from "@/components/EditorBox/Row.vue";
import Column from "@/components/EditorBox/Column.vue";
import SwitchRow from "@/components/EditorBox/SwitchRow.vue";

export default {
  components: {
    EditorBox,
    ToggleSwitch,
    EditableRangeSlider,
    Column,
    Row,
    SwitchRow,
    Label,
  },
  setup() {
    const canvasStore = CanvasStore;

    return {
      canvasStore,
    };
  },
};
</script>

<style scoped>
#imageSelector {
  font-size: var(--font_size_editor_box_input);
}

#layerOrderRow
{
  grid-template-columns: 3fr 3.5fr 3.5fr;
}

.layerOrderButton
{
  font-size: var(--font_size_editor_box_label);
}


.disabledRow
{
opacity: 0.1;
 filter: grayscale(1);
 pointer-events: none;
 touch-action: none;

}

</style>
