<template>
  <EditorBox label="Megjelenés" iconClass="fas fa-fw fa-eye">
    <Column>

    
      <SwitchRow>
        <Label> kontúros:</Label>

        <ToggleSwitch
          class="toggleSwitch"
          :modelValue="outlineVersion"
          @update:modelValue="updateModelValue('outlineVersion', $event)"
        >
        </ToggleSwitch>
      </SwitchRow>

      <Row class="rightAlignControl">
        <Label> kontúrszín:</Label>

        <ColorChooserBar
          colorSet="outlineColors"
          v-model:selectedColor="canvasStore.state.configGeneral._outlineColor"
        >
        </ColorChooserBar>
      </Row>
      
      

      <Row class="rightAlignControl">
        <Label> háttérszín:</Label>

        <ColorChooserBar
          colorSet="backgroundColors"
          v-model:selectedColor="canvasStore.state.configBackgroundRect._rawFillColor"
        >
        </ColorChooserBar>
      </Row>



            <Row class="rightAlignControl">
        <Label> keretszín:</Label>

        <ColorChooserBar
          colorSet="borderColors"
          v-model:selectedColor="canvasStore.state.configBackgroundRect._rawStrokeColor"
        >
        </ColorChooserBar>
      </Row>

      <Row class="noBottomSeparator">
        <Label> keretvastagság:</Label>

        <EditableRangeSlider
          id="borderSizeSlider"
          :sliderValue="borderSize"
          @update:sliderValue="updateModelValue('borderSize', $event)"
          min="0"
          max="100"
        >
        </EditableRangeSlider>
      </Row>

    </Column>
  </EditorBox>
</template>

<script>
import { ref } from "vue";
import EditorBox from "@/components/EditorBox/EditorBox.vue";
import CanvasStore from "../../store/canvas";

import ToggleSwitch from "@/components/ToggleSwitch.vue";

import EditableRangeSlider from "@/components/EditableRangeSlider.vue";
import ColorChooserBar from "@/components/ColorChooserBar.vue";

import Label from "@/components/EditorBox/Label.vue";
import Row from "@/components/EditorBox/Row.vue";
import Column from "@/components/EditorBox/Column.vue";
import SwitchRow from "@/components/EditorBox/SwitchRow.vue";

export default {
  components: {
    EditorBox,
    ToggleSwitch,
    EditableRangeSlider,
    ColorChooserBar,
    Column,
    Row,
    SwitchRow,
    Label,
  },
  props: {
    location: {
      type: String,
      required: true,
      note: "Can be upperText or lowerText",
    },
    textInputValue: String,
    fontFamily: String,
    outlineVersion: Boolean,

    borderSize: Number,
  },
  setup(props, { emit }) {
    const canvasStore = CanvasStore;

    const inputText = ref("");
    const font = ref("");

    const checkbox = ref("true");

    const colorChooserWindowVisible = ref("true");

    const autoCompleteOptions = ref([
      "alma",
      "körte",
      "banán",
      "citrom",
      "eper",
    ]);

    function clicked() {
      canvasStore.methods.setText("upperText", "hello");
    }

    function updateModelValue(model, value) {
      emit("update:" + model, value);
    }
    return {
      clicked,
      inputText,
      font,
      autoCompleteOptions,
      checkbox,
      canvasStore,
      updateModelValue,
      colorChooserWindowVisible,
    };
  },
};
</script>

<style scoped>
/* .toggleSwitch {
  font-size: 2.2vh;
} */

#borderSizeSlider {
  /*font-size: 4vh;*/
  /*width:90%;*/
}
</style>