<template>
  <div class="column">
      <slot> </slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.column
{
    display: grid;
    grid-template-columns: 1fr;
      grid-template-rows: auto;
    
    gap: 2vh;

 
padding: 1.5vh;

    justify-content: start;
    /* background-color: blueviolet; */
}
</style>