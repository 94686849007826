<template>
  <!-- <div>Search Filters</div> -->
  <!-- <button @click="apiGetWatermarks">API Call</button> -->

  <select v-model="selected" id="fontFamilySelect">
    <option value=""></option>
    <option
      v-for="(watermark, index) in watermarks"
      :key="'wm_' + index"
      :value="watermark.waterMarkId"
    >
      {{ watermark.waterMarkCreator }}
    </option>
  </select>
</template>

<script>
import { ref } from "vue";
import { onMounted } from "vue";
import axios from "axios";
import PersistentStore from "../store/persistent";

export default {
  setup() {
    const persistentStore = PersistentStore;

    const watermarks = ref();

    const selected = ref(null);

    function apiGetWatermarks() {
      console.log("API GET WATERMARKS")
      console.log(persistentStore.state.token)

      axios
        .get(
          "verbalio_server/index.php?watermarks",
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          watermarks.value = resp.data;
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function setSelectedWatermark(waterMarkId) {
      const index = watermarks.value.findIndex(
        (watermark) => watermark.waterMarkId == waterMarkId
      );

      // console.log("found index: " + index)

      if (waterMarkId==undefined || index == -1) {
        selected.value= undefined
      } else {
        selected.value= waterMarkId;
      }
    }

    function getPath(waterMarkId) {
      const index = watermarks.value.findIndex(
        (watermark) => watermark.waterMarkId == waterMarkId
      );

      // console.log("found index: " + index)

      if (index == -1) {
        return "";
      } else {
        return watermarks.value[index].waterMarkPath;
      }
    }

    onMounted(() => {
      persistentStore.methods.init();
      apiGetWatermarks();
    });

    return {
      apiGetWatermarks,
      watermarks,
      getPath,
      setSelectedWatermark,
      selected,
    };
  },
};
</script>

<style scoped>
</style>