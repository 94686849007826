<template>
  <div class="floatingWindowShader"></div>

  <div class="floatingWindowBase">
    <div class="floatingWindow">
      <div class="floatingWindowTitle">
        <slot name="title"> </slot>
      </div>

      <div class="floatingWindowContentContainer">
        <slot name="content"> </slot>
      </div>

      <div class="floatingWindowButtonContainer">
        <slot name="buttons"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.floatingWindowBase {
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;

  display: grid;
  justify-content: center;
  align-content: center;
  backdrop-filter: blur(0.4vh);
}

.floatingWindowShader {
  height: 100vh;
  width: 100vw;
  background-color: #333333;
  opacity: 0.8;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999;

  display: grid;
  justify-content: center;
  align-content: center;
}

.floatingWindow {
  background-color: #ffffff;
  width: 25vw;
  border-radius: 1vh;
  box-shadow: 0px 0px 10px 0px rgba(3, 85, 216, 0.15);
  user-select: none;
  padding: 2.5vh;
}

#responsive.mobile .floatingWindow {
  width: 80vw;
}

#responsive.tablet .floatingWindow {
  width: 65vw;
}

.floatingWindowTitle {
  display: grid;
  justify-content: left;
  font-size: var(--font_size_normal);
  font-weight: bold;
}

.floatingWindowContentContainer {
  /* min-height: 5vh; */
  display: grid;
  justify-content: left;
  grid-template-columns: 1fr;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: var(--font_size_normal);

  width: auto;

  /* background-color: rosybrown; */
}

.floatingWindowButtonContainer {
  display: grid;
  justify-content: right;
  gap: 4vh;
  /* grid-auto-columns: min-content;  */
  grid-template-columns: max-content max-content;
  /* width: 700px; */
  /* background-color: rosybrown; */
}
</style>
