<template>
  <div>
    <div
      class="tagGroup"
      v-for="(tagGroup, index) in tags"
      :key="'tg_' + index"
    > 
   
      <input type="text" v-model="tagGroup.label" />
        <button class="delete">x</button>
       <button class="add">+</button>
      <div
        class="tagItem"
        v-for="(tagItem, index) in tagGroup.items"
        :key="'ti_' + index"
      >
        <input type="text" v-model="tagItem.label" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";

// import VueAxios from 'vue-axios'
// Vue.use(VueAxios.axios)
import PersistentStore from "../store/persistent";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const persistentStore = PersistentStore;

    const tags = ref([
      {
        label: "Korosztály",
        id: "asdasd23rasfdasf",
        items: [
          { label: "1. osztály", id: "fsdf32twet" },
          { label: "2. osztály", id: "asfasfasf" },
        ],
      },

      {
        label: "Szófaj",
        id: "sdfsdfsdf",
        items: [
          { label: "ige", id: "sdfsdfsdfsdf" },
          { label: "főnév", id: "asfasdfsfasf" },
          { label: "melléknév", id: "yxcyxc" },
        ],
      },
    ]);

    function apiCall() {
      axios
        .get("verbalio_server/", {
          headers: { Authorization: `Bearer ${"token"}` },
        })
        .then((resp) => {
          console.log(resp.data);
        });

      console.log("hello world");
    }

    function apiPost() {
      axios
        .post(
          "verbalio_server/index.php?users=login",
          {
            email: "zsomboribalazs@gmail.com",
            password: "almafa",
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          persistentStore.state.userId = resp.data.userId;
          persistentStore.state.userFullName = resp.data.fullName;
          persistentStore.state.userRole = resp.data.role;
          persistentStore.state.token = resp.data.token;
          persistentStore.state.loggedIn = true;

          router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }
    return {
      apiCall,
      apiPost,
      tags,
    };
  },
};
</script>

<style scoped>
</style>