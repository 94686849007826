<template>   
       <input class="floatingWindowInput" type="text">
</template>

<script>
export default {};
</script>

<style scoped>
 

.floatingWindowInput {
  
  font-size: var(--font_size_normal);
  
 

  
  /* padding: 1vh; */
}

 
</style>
