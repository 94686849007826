<template>
  <div id="imageGrid" :class="{ showDetails: showDetails }">
    <div
      class="itemContainer"
      :class="{ showDetails: showDetails }"
      v-for="(image, index) in imagesCopy"
      :key="'im_' + index"
      :id="'lazyLoadItem_' + index"
    >
      <div
        class="folderContainer"
        v-if="image.type == 'folder'"
        @click="emitFolderClicked(image)"
      >
        <div class="folderTop"></div>
        <div class="folderBack">
          {{ image.label }}
          <img
          class="folderImage"
          :src="
            image.load
              ? (image.type == 'savedWork'? 'verbalio_server/savedWorks/' /*+ actualSetUserId + '/'*/ : 'verbalio_server/uploadedImages/') +
                image.photoId +
                '_thumbnail.png'
              : ''
          "
          draggable="false"
          @click="speak(image)"
        />
        </div>

        <slot name="folder" :folder="image" :index="index"> </slot>
      </div>

      <div class="imageContainer" v-else>
        <div class="imageLabel">
          {{ image.label!=undefined&&image.label.length>0?image.label:image.synonyms[0] }}
        </div>

        <img
          class="image"
          :src="
            image.load
              ? (image.type == 'savedWork'? 'verbalio_server/savedWorks/' /*+ actualSetUserId + '/'*/ : 'verbalio_server/uploadedImages/') +
                image.photoId +
                '_thumbnail.png'
              : ''
          "
          draggable="false"
          @click="speak(image)"
        />
      </div>

      <div class="controlContainer">
        <slot name="controlButtons" :image="image" :index="index"> </slot>
      </div>

      <div class="detailsContainer"
      v-if="showDetails"
      >
        <div>
          <div class="detailTitle">Szinonímák:</div>
          <ul class="detailSynonymsList">
            
            <li
              v-for="(synonym, synonym_index) in image.synonyms"
              :key="'si_' + synonym_index"
            >
              {{ synonym }}
            </li>
          </ul>
        </div>
        <div>
          <!-- Kategóriák: -->
          <!-- <li v-for="(tag, tag_index) in image.tagIds" :key="'ti_' + tag_index">
          {{ tag }}
        </li> -->

          <div
            class="tagGroup"
            v-for="(tagGroup, index) in allTags"
            :key="'atg_' + index"
          >
            <strong>
                        
              <div class="tagLabel detailTitle">{{ tagGroup.label }}</div>
            </strong>

            <div
              class="tagItem"
              v-for="(tagItem, index) in tagGroup.items"
              :key="'ati_' + index"
            >
              <div
                v-if="image.tagIds.includes(tagItem.tagId)"
                v-on:click.stop.prevent=""
              >
                <input type="checkbox" checked="true" />
                <label> {{ tagItem.label }}</label
                ><br />
              </div>
            </div>
          </div>
        </div>
        <div>
                 <div class="detailTitle">Rajzoló:</div>
          
          {{ image.waterMarkCreator }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from "vue";
// import { onMounted } from "vue";
// import axios from "axios";

import { useRouter } from "vue-router";

import { ref, watch } from "vue";

// import QuickImageButton from "@/components/QuickImageButton.vue";
import CanvasStore from "../store/canvas";
import Store from "../store/store";

export default {
  components: {
    // QuickImageButton,
  },
  emits: ["folderClicked"],
  props: {
    images: Array,
    allTags: Array,
    showDetails: {
      type: Boolean,
      default: false,
    },
    actualUserId: {
      type: String,
      default: "",
      required: false,
    },
  },

  setup(props, { emit }) {
    const canvasStore = CanvasStore;
    const store = Store;

    const router = useRouter();

    // const loadedItemsIndex = ref([]);

    const imagesCopy = ref();

    const lazyLoadCount = 24;

    

    var observer = undefined;

    function lazyLoadAroundIndex(index) {
      console.log("lazyLoadAroundIndex: " + index)

      

      for (
        let i = index;
        i <= index + lazyLoadCount && i < props.images.length;
        i++
      ) {
        imagesCopy.value[i].load = true;
      }

      for (let i = index; i >= index - lazyLoadCount && i >= 0; i--) {
        // console.log(i)
        imagesCopy.value[i].load = true;
      }
    }

    function addInterSectionObserver() {
      const options = {
        // root: document.querySelector("#visibleArea"),
        rootMargin: "0px",
        threshold: 0.1,
      };

      const callback = (entries) => {

        
        for(let i = 0; i < entries.length; i++)

        if (entries[i].isIntersecting) {
          // console.log("intersecting");
          // emit("lazyLoadRequested", 0);

          let index = Number.parseInt(
            entries[i].target.id.replace("lazyLoadItem_", "")
          );
          
          
          lazyLoadAroundIndex(index);

        }
      };

      if (observer != undefined) {
        observer.disconnect();
      }

      observer = new IntersectionObserver(callback, options);

      for (var i = 0; i < props.images.length; i += lazyLoadCount / 2) {
        let target = document.querySelector("#lazyLoadItem_" + i);
        if (target != undefined) {
          observer.observe(target);
        }
      }
    }

    // function addTagLabels() {
    //   console.log("addingTagLabels");

    //   imagesCopy.value.forEach((image) => {
    //     console.log(image)

    //   allTags.forEach(allTag => {

    //   });
    //     image.tagIds.forEach(tagId => {
    //               console.log(tagId)

    //     });
    //   });
    // }

    // onMounted(() => {
    //   addInterSectionObserver();
    // });

    // Before DOM update
    watch(
      () => props.images,
      () => {
        console.log("images changed pre");

        //addInterSectionObserver()

        imagesCopy.value = [...props.images];

        // addTagLabels();

        //loadedItemsIndex.value = new Array(props.images.length).fill(false);

        for (var i = 0; i < imagesCopy.value.length; i++) {
          //loadedItemsIndex.value[i] = true;
          imagesCopy.value.load = i < lazyLoadCount;
        }
      }
    );

    // After DOM update
    watch(
      () => props.images,
      () => {
        console.log("images changed");
        console.log(props.images);

        addInterSectionObserver();

 

      },
      { flush: "post" } // only triggers callback after DOM update
    );


    function speak(image)
    {


      openImageInEditor(image)
//      console.log(image)
      let synth = window.speechSynthesis;
      let utterance = new SpeechSynthesisUtterance(image.label!=undefined&&image.label.length>0?image.label:image.synonyms[0]);
      synth.speak(utterance);
    }

    function openImageInEditor(image) {
      console.log(image);

 

      console.log("extra image adder state");
      console.log(store.state.extraImageAdderState);

      if (store.state.extraImageAdderState) {
        store.state.extraImageAdderState = false;
        canvasStore.methods.addExtraImage(image);
      } else {

          if(image.type == "savedWork")
          {
          //  console.log("load saved work") 
          canvasStore.methods.loadSavedWork(image);
          }
          else
          {
            canvasStore.methods.loadNewImage(image);
          }
      }

      //  router.push("imageeditor");
      console.log(router)
    }

    function emitFolderClicked(folder) {
      emit("folderClicked", folder);
    }

    return {
      // loadedItemsIndex,
      actualSetUserId: props.actualUserId,
      imagesCopy,
      openImageInEditor,
      speak,
      emitFolderClicked,
    };
  },
};
</script>

<style scoped>
#imageGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3vh;
  padding-left: 3vh;
  padding-right: 0.5vh;

  /* height: 100vh; */
  /* overflow-y: auto; */
}

#imageGrid.showDetails {
  grid-template-columns: 1fr;
  

}



#responsive.tablet #imageGrid {
  grid-template-columns: 1fr 1fr;
  padding-left: 4vh;
 }

#responsive.mobile #imageGrid {
  grid-template-columns: 1fr;
  padding-left: 4vh;
}

/* .skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;


    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }


.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );

} */

.itemContainer {
  /* height: 40vh; */
  /* width: 40vh; */

  display: grid;

  grid-template-columns: 9fr 3fr;

 

  gap: 1vh;

  /* background-color: orange; */
}

.itemContainer.showDetails {
  /* height: 40vh; */
  /* width: 40vh; */

  display: grid;

  grid-template-columns: 9fr 3fr 21fr;

  gap: 1vh;

  /* background-color: orange; */
}

#responsive.mobile .itemContainer {
 

  grid-template-columns: 13fr 3fr;
  gap: 1vh;
 
}
 

.detailsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  padding-top: 3vh;
  justify-content: start;
  align-content: top;

  text-align: left;
  padding-left: 5vh;
  /* background-color: orange; */
}

.detailTitle
{
  font-weight: bold;
  margin-bottom: 0.5vh;
  margin-top:1vh;
}

.detailSynonymsList
{
  margin-top: 0;
  /* padding-top: 0; */

}




.imageContainer {
  display: grid;
  grid-template-rows: 4vh 1fr;

  justify-content: center;
  align-content: center;
  /* background-color: olivedrab; */
}

 
.image {
  object-fit: cover;

  /* //    height: 30vh;   */
  width: 100%;
  user-select: none;
  /* background-color: white; */

  border-radius: 1vh;

  cursor: pointer;

  box-shadow: 0 0 0.8vh 0 rgba(3, 85, 216, 0.15);
  /* display: none; */
}
.folderImage {
  object-fit: cover;

  /* //    height: 30vh;   */
  width: 100%;
  user-select: none;
  /* background-color: white; */

  border-radius: 1vh;

  cursor: pointer;

  box-shadow: 0 0 0.8vh 0 rgba(3, 85, 216, 0.15);
  /* display: none; */
}

.imageLabel {
  font-size: var(--font_size_normal);
  text-overflow: ellipsis;
  overflow: hidden; 


}

.folderContainer {
 

  display: grid;
  grid-template-rows: 1fr 10fr;

  
    margin-top: 4.5vh;
  
    align-content: stretch;
    justify-content: center;

 
 

 


  cursor: pointer;
  /* background-color: burlywood; */
}

#responsive.tablet .folderContainer {

/*margin: 13%;*/
/*width: 33vw;*/
}

#responsive.mobile .folderContainer {

  /*margin: 13%;*/
 /* width: 50vw;*/
}

.folderBack {


 

  /*margin-bottom: 7%;*/
  margin-top: -7%;
  border-radius: 1vh;

  background-color: var(--primary_color);
  color: var(--light_text_color);

  display: grid;
  align-content: center;
  justify-content: center;

  font-size: var(--font_size_normal);

  user-select: none;
  box-shadow: 0 0 0.8vh 0 rgba(3, 85, 216, 0.15);

  /*aspect-ratio: 1 / 1;*/
  width: 15.5vw;
  min-height: 14vw;
}

#responsive.tablet .folderBack {

 width: 30vw;
 min-height: 30vw;
}

#responsive.mobile .folderBack {


  width: 50vw;
  min-height: 50vw;

}

.folderTop {
  width: 45%;
  /*height: 14%;*/

  margin-top: -7%;

  border-radius: 1vh;

  background-color:  var(--primary_color); 
  /* box-shadow: 0 0 0.8vh 0 rgba(3, 85, 216, 0.15); */
  /* background-color: blueviolet; */
}

.controlContainer {
  margin-top: 4vh;
  display: grid;

  grid-template-columns: 1fr;

  align-content: flex-start;

  gap: 0.1vh;

  /* background-color: orange; */
}
</style>
