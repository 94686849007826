<template>
  <!-- <div>Search Filters</div> -->
  <!-- <button @click="apiGetTags">API Call</button> -->
  <div id="searchFilterBox">
    <!--<div id="resetAllButtonContainer">
      <ImageButton
        v-if="restoreButtonsVisible"
        id="resetAllButton"
        label="Alaphelyzetbe"
        iconClass="fas fa-fw fa-fast-backward"
        :disabled="selectedTags.length == 0"
        @click="resetAll"
      />
    </div>-->

    <div  v-bind:class="{ allFilterContainerHugeTopMargin: restoreButtonsVisible}">
      <div
        class="tagGroup"
        v-for="(tagGroup, index) in tags"
        :key="'tg_' + index"
      >
        <div class="tagGroupHeader">
          <div class="tagLabel">{{ tagGroup.label }}</div>

          <!--<RestoreButton
            v-if="restoreButtonsVisible"
            @click="resetTagGroup(tagGroup)"
          />-->
        </div>

        <div
          class="tagItem"
          v-for="(tagItem, index) in tagGroup.items"
          :key="'ti_' + index"
          
        >
          <input
            type="radio"
            :id="tagItem.tagId"
            value="tagItem.tagId"
            @click="toggleTag(tagItem.tagId)"
            :checked="selectedTags.includes(tagItem.tagId)"
            name="searchFilter"
          />
          <label :for="tagItem.tagId"> {{ tagItem.label }}</label
          ><br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onMounted } from "vue";
import axios from "axios";
import PersistentStore from "../store/persistent";
//import ImageButton from "@/components/ImageButton.vue";
// import RestoreButton from "@/components/RestoreButton.vue";

export default {
  props: {
    selectedTags: Array,
    restoreButtonsVisible: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    //ImageButton,
    //RestoreButton,
  },
  emits: ["tagsLoaded"],
  setup(props, { emit }) {
    const tags = ref();
    const persistentStore = PersistentStore;

    function toggleTag(tagId) {
      let newTags = []//[...props.selectedTags];
      /*if (newTags.includes(tagId)) {
        console.log("filter");
        newTags = newTags.filter((id) => id != tagId);
      } else {
        newTags.push(tagId);
      }*/
      newTags.push(tagId)

      updateSelectedTags(newTags);
    }

    function resetTagGroup(group) {
      // console.log(group)
      let newTags = [...props.selectedTags];
      group.items.forEach((tag) => {
        newTags = newTags.filter((id) => id != tag.tagId);
      });
      updateSelectedTags(newTags);
    }

    function resetAll() {
      updateSelectedTags([]);
    }

    function updateSelectedTags(newTags) {
      emit("update:selectedTags", newTags);
    }

    function apiGetTags() {
      axios
        .get(
          "verbalio_server/index.php?tags",
          {
            headers: { Authorization: `Bearer ${persistentStore.state.token}` },
          }
          // { withCredentials: true }
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.data);

          tags.value = resp.data;

          emit("tagsLoaded", resp.data);

          //localStorage.setItem('userData', JSON.stringify(resp.data))

          //   persistentStore.state.userId = resp.data.userId;
          //   persistentStore.state.userFullName = resp.data.fullName;
          //   persistentStore.state.userRole = resp.data.role;
          //   persistentStore.state.token = resp.data.token;
          //   persistentStore.state.loggedIn = true;

          //   router.push("favorites");

          // console.log(JSON.parse(localStorage.getItem('userData')))

          // console.log(store.state);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          console.log("error end");
        });
    }

    function getSelectedTagsInLogicalOrder() {
      var and = [];
      tags.value.forEach((tagGroup) => {
        var or = [];
        tagGroup.items.forEach((tag) => {
          if (props.selectedTags.includes(tag.tagId)) {
            // console.log("includes " + tag.label  )
            or.push(tag.tagId);
          }
        });

        if (or.length > 0) {
          and.push(or);
        }
      });

      return and;
    }

    onMounted(() => {
      persistentStore.methods.init();
      apiGetTags();
    });

    return {
      apiGetTags,
      tags,
      toggleTag,
      resetTagGroup,
      resetAll,
      getSelectedTagsInLogicalOrder,
    };
  },
};
</script>

<style scoped>
#searchFilterBox {
  text-align: left;
  font-size: var(--font_size_normal);
  user-select: none;
  overflow-y: auto;

  /* margin-top:50vh; */

  /* padding-bottom: 5vh; */
}

.allFilterContainerHugeTopMargin {
  /* padding-top: 11vh; */
}

.tagGroup {
  margin-bottom: 2vh;
}

.tagGroupHeader {
  display: grid;
  grid-template-columns: min-content 1fr;
  justify-items: right;

  padding-right: 2.5vh;
  /* background-color: blue; */
}

.tagLabel {
  font-weight: bold;
  display: none;
}

.tagItem {
  padding-top: 1vh;
  
  /* color: gray;   */
  /* color: var(--primary_color); */
  
}
.tagItem label {
  margin: 0 0 0 -3vh;
  padding: 2vh;
  background-color: #f8e5ad;
  display: inline-block;
  width: 80%;
  max-width: 80%;
  border-style: solid;
  border-width: 0.1vh;
}

input[type="radio"]{
    visibility:hidden;
}

.tagItem input[type="radio"]:checked+label {
  
  background-color: rgb(251, 98, 10);
  font-weight: bold;
  
}

/* .tagItem input:checked + label {
  color: var(--primary_color);
 
}  */

#resetAllButtonContainer {
  position: absolute;
 
 



 /*border: 5px solid red;*/
 overflow: hidden;
  /* margin-bottom: 2.5vh; */
  /* margin-top: 2.5vh; */
  /* margin-right: 2.5vh; */

 

  display: grid;

  background-color: #ebebf3;
}

/*#responsive.tablet #resetAllButtonContainer {

width:23vw;

}

#responsive.mobile #resetAllButtonContainer {

width:32vw;

}*/


#resetAllButton {
  margin-top: 2.5vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
</style>
