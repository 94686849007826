<template>
  <FloatingWindowBase>
    <template v-slot:title>
      {{ windowTitle }}
    </template>

    <template v-slot:content>
      <FloatingWindowInput
        class="floatingWindowInputMargin"
        ref="floatingWindowInput"
        :value="inputModel"
        @input="updateModelValue($event.target.value)"
      />
    </template>

    <template v-slot:buttons>
      <FloatingWindowButton @click="emit('cancelButtonClicked')">
        Mégse
      </FloatingWindowButton>
      <FloatingWindowButton
        @click="
          if (hasInput) {
            emit('acceptButtonClicked');
          }
        "
        :class="{ disabled: !hasInput }"
      >
        {{ acceptButtonLabel }}
      </FloatingWindowButton>
    </template>
  </FloatingWindowBase>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";

import FloatingWindowBase from "@/components/FloatingWindowBase.vue";
import FloatingWindowButton from "@/components/FloatingWindowButton.vue";
import FloatingWindowInput from "@/components/FloatingWindowInput.vue";

export default {
  components: {
    FloatingWindowBase,
    FloatingWindowButton,
    FloatingWindowInput,
  },
  props: {
    windowTitle: String,
    acceptButtonLabel: String,
    inputModel: String,
  },
  emits: ["cancelButtonClicked", "acceptButtonClicked"],

  setup(props, { emit }) {
    /*const renameWindowVisible = ref(true);*/
    const floatingWindowInput = ref(null);
    const floatingWindowInputModel = ref("");

    const hasInput = computed(() => props.inputModel.trim().length > 0);

    watch(floatingWindowInput, (newValue) => {
      if (newValue != null) {
        newValue.$el.focus();
      }
    });

    onMounted(() => {
      console.log("onmounted floating input");
    });

    function updateModelValue(value) {
      emit("update:inputModel", value);
    }

    return {
      floatingWindowInput,
      floatingWindowInputModel,
      hasInput,
      updateModelValue,
      emit,
    };
  },
};
</script>

<style scoped>
.floatingWindowInputMargin {
  margin-bottom: 4vh;
  padding: 0.5vh;
}

.disabled {
  color: gray;
}
.disabled:hover {
  color: gray;
}
</style>
